import { useApolloClient } from '@apollo/client';
import { Box, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DashboardPanel from '../../components/DashboardPanel';
import { useResources } from '../../hooks/resources';
import getFormattedDate from '../../support/date/getFormattedDate';
import { ResourceIcon } from '../Common/ResourceIcon';

interface ItemData {
  content_type: string; // adjust the type according to actual data structure
  name: string;
  description?: string; // optional property
  create_date: number | string; // assuming this is a date or date string
}

export const Resources: React.FC = () => {
  const { getResources } = useResources();

  const client = useApolloClient();
  const [resources, setResources] = useState<any[]>([]);

  useEffect(() => {
    const fetchResources = async () => {
      const response = await getResources(client, { limit: 4, offset: 0 });
      setResources(response);
    };

    fetchResources();
  }, [client, getResources]);

  function ResourceRow({ itemdata }: { itemdata: ItemData }) {
    return (
      <Grid container spacing={4} alignItems="center" style={{ flexGrow: 1 }}>
        <Grid item xs={1}>
          <ResourceIcon type={itemdata.content_type} />
        </Grid>
        <Grid item xs={8}>
          <Box marginLeft={2}>
            <Typography variant="h4" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {itemdata.name}
            </Typography>
            {itemdata.description && (
              <Typography
                variant="body2"
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {itemdata.description}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Typography>{getFormattedDate(itemdata.create_date)}</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <DashboardPanel title="Reports">
      {resources.length === 0 ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography style={{ textAlign: 'center', color: '#647386' }}>No Reports Available</Typography>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" style={{ height: '100%' }} flexGrow={1}>
          {resources.map((resource) => (
            <Box
              key={resource.id}
              display="flex"
              flex={1}
              style={{ minHeight: 0, borderBottom: '1px solid #ECEDEF' }}
            >
              <ResourceRow itemdata={resource} />
            </Box>
          ))}
        </Box>
      )}
    </DashboardPanel>
  );
};
