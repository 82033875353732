import { ApolloClient, useApolloClient } from '@apollo/client';
import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { usePrioritisationOverlay } from '../../contexts/PrioritisationOverlayContext';
import { useCriteria } from '../../contexts/Search/Criteria';
import { useSearchQuery } from '../../contexts/Search/Query';
import { QuestionDemographics } from '../../declarations/Demographics';
import { getQuestionDemographics } from '../../services/api/get-question-demographics';
import { Segments, getIncomeSurveySegmentFromSector } from '../../types/Segments';
import { Loading } from '../Common/Loading';
import { WeightBadge } from '../Common/WeightBadge';
import { Modal } from '../Modal/Modal';
import { StyledBox } from './PrioritisationOverlayModal.styled';
import { AudienceCriterion } from '../../declarations/Audience';

const loadQuestionDemographics = (
  client: ApolloClient<object>,
  question: number,
  segment: Segments,
  audience: Record<string, number[]>
) => {
  return getQuestionDemographics(client, {
    as_at_date: new Date().toISOString().split('T')[0],
    question,
    segment,
    audience
  });
};

export const PrioritisationOverlayModal = () => {
  const searchQuery = useSearchQuery();
  const searchCriteria = useCriteria();
  const apolloClient = useApolloClient();

  const { state, dispatch } = usePrioritisationOverlay();

  const [loading, setLoading] = useState<boolean>(false);
  const [demographics, setDemographics] = useState<QuestionDemographics>();

  useEffect(() => {
    if (state.question && state.segment) {
      setLoading(true);
      loadQuestionDemographics(apolloClient, state.question, state.segment, searchQuery.state.audience)
        .then((response) => {
          setDemographics(response);
        })
        .then(() => setLoading(false));
    }
  }, [apolloClient, searchQuery.state.audience, state.question, state.segment]);

  const onClose = () => {
    dispatch({ type: 'SET_VOC_OVERLAY_MODAL_STATE', payload: { open: false } });
  };

  return (
    <Modal
      open={state.vocOverlayIsOpen}
      onClose={onClose}
      closeable={true}
      title={'VoC Prioritisation'}
      paddingX={8}
      paddingY={4}
      fullHeight={false}
      rounded={true}
      size={'xl'}
    >
      <Container
        maxWidth="lg"
        style={{
          outline: 'none',
          maxHeight: '90vh'
        }}
      >
        {loading && <Loading.Panel width={'800px'} height={'600px'} />}

        {!loading && demographics && (
          <Box maxWidth={'800px'} width={'100%'} display={'flex'} flexDirection={'column'} minWidth={0}>
            <Box marginY={2}>
              <Paper>
                <Box paddingY={1} paddingX={4} display={'flex'} flexDirection={'column'}>
                  <Box
                    borderBottom={'1px solid #ECEDEF'}
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    flexWrap={'nowrap'}
                    paddingBottom={3}
                    paddingTop={2}
                  >
                    <Typography variant={'h4'}>{state.text}</Typography>

                    <Box display={'flex'} alignItems={'center'}>
                      <WeightBadge
                        style={{
                          marginRight: '5px'
                        }}
                        withIcon
                        weight={state.weight as number}
                        segment={state.segment!}
                      />
                      {/* <SecureChannelBadge secure={state.secure as boolean} withText /> */}
                    </Box>
                  </Box>

                  <Box marginY={2}>
                    <Grid container spacing={4}>
                      {Object.entries(demographics).map((d, dIdx) => {
                        const [name, value] = d;

                        const display = Array.from(['age', 'market', 'gender', 'income']).includes(name);

                        if (!display) return null;

                        const criterion = searchCriteria.state.audience[name];

                        if (name === 'income') {
                          const surveySegment = getIncomeSurveySegmentFromSector(searchQuery.state.selectedCycle.linkedSector)
                          const ranges = criterion.filter(c => c.segment === surveySegment)

                          return (
                            <Grid key={dIdx} item xs={6}>
                              <Typography style={{ textTransform: 'capitalize' }} variant={'h4'}>
                                Income
                              </Typography>
                              <Box display={'flex'} flexDirection={'column'}>
                                {ranges.map((c: AudienceCriterion, cIdx) => {
                                  const stat = Object.entries(value).find((v) => {
                                    return v[1].id === c.id;
                                  });

                                  return (
                                    <Box
                                      key={cIdx}
                                      marginY={1}
                                      display={'flex'}
                                      justifyContent={'space-between'}
                                      marginBottom={'2px'}
                                    >
                                      <Typography variant={'body1'}>{c.name}</Typography>
                                      <Box display={'flex'}>
                                        {stat !== undefined && (
                                          <>
                                            <Typography variant={'body1'}>{stat[1].percent.toFixed(2)}%</Typography>
                                            <Box
                                              style={{
                                                display: 'inline-block',
                                                boxSizing: 'border-box',
                                                verticalAlign: 'middle',
                                                position: 'relative',
                                                width: 94,
                                                height: 15,
                                                backgroundColor: '#ECEDEF',
                                                borderRadius: 3,
                                                overflow: 'hidden',
                                                marginLeft: 8
                                              }}
                                            >
                                              <Box
                                                style={{
                                                  display: 'block',
                                                  boxSizing: 'border-box',
                                                  position: 'absolute',
                                                  top: 0,
                                                  left: 0,
                                                  height: '100%',
                                                  backgroundColor: '#001433',
                                                  width: stat[1].percent + '%'
                                                }}
                                              />
                                            </Box>
                                            <Typography
                                              variant={'body1'}
                                              style={{ textAlign: 'right', minWidth: 60, display: 'inline-block' }}
                                            >
                                              of {stat[1].total_count}
                                            </Typography>
                                          </>
                                        )}

                                        {stat === undefined && <Typography variant={'body1'}>No Data</Typography>}
                                      </Box>
                                    </Box>
                                  );
                                })}
                              </Box>
                            </Grid>
                          );
                        }

                        return (
                          <Grid key={dIdx} item xs={6}>
                            <Typography style={{ textTransform: 'capitalize' }} variant={'h4'}>
                              {name}
                            </Typography>
                            <Box display={'flex'} flexDirection={'column'}>
                              {criterion.map((c: AudienceCriterion, cIdx) => {
                                const stat = Object.entries(value).find((v) => {
                                  return v[1].id === c.id;
                                });

                                return (
                                  <Box
                                    key={cIdx}
                                    marginY={1}
                                    display={'flex'}
                                    justifyContent={'space-between'}
                                    marginBottom={'2px'}
                                  >
                                    <Typography variant={'body1'}>{c.name}</Typography>
                                    <Box display={'flex'}>
                                      {stat !== undefined && (
                                        <>
                                          <Typography variant={'body1'}>{stat[1].percent.toFixed(2)}%</Typography>
                                          <Box
                                            style={{
                                              display: 'inline-block',
                                              boxSizing: 'border-box',
                                              verticalAlign: 'middle',
                                              position: 'relative',
                                              width: 94,
                                              height: 15,
                                              backgroundColor: '#ECEDEF',
                                              borderRadius: 3,
                                              overflow: 'hidden',
                                              marginLeft: 8
                                            }}
                                          >
                                            <Box
                                              style={{
                                                display: 'block',
                                                boxSizing: 'border-box',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                height: '100%',
                                                backgroundColor: '#001433',
                                                width: stat[1].percent + '%'
                                              }}
                                            />
                                          </Box>
                                          <Typography
                                            variant={'body1'}
                                            style={{ textAlign: 'right', minWidth: 60, display: 'inline-block' }}
                                          >
                                            of {stat[1].total_count}
                                          </Typography>
                                        </>
                                      )}

                                      {stat === undefined && <Typography variant={'body1'}>No Data</Typography>}
                                    </Box>
                                  </Box>
                                );
                              })}
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </Box>
              </Paper>
            </Box>

            <StyledBox>
              <Typography variant={'h3'}>N = {demographics.total_count}</Typography>
              <p>
                Results generated by a cross-sectional, closed ended survey incorporating the Radar benchmarks. Values
                reflect the proportion of survey participants that identified this benchmark as a priority in their
                digital banking experience
              </p>
            </StyledBox>
          </Box>
        )}
      </Container>
    </Modal>
  );
};
