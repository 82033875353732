import { uniqBy } from 'lodash'
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes'
import secDomainToString from '../../helpers/secDomainToString'
import { FeatureJourney } from '../../types/Functions'
import getFormattedDate from '../date/getFormattedDate'
import { getSurveySegmentFromSegment } from '../../types/Segments';
import { TransformedChannelScore } from '../../declarations/ChannelScore'

export const exportFunctions = async (
  filename: string,
  transformedChannelScores: TransformedChannelScore[],
  journeys: FeatureJourney[],
  frictionScoreData: FrictionData[],
  selected: string[],
  usesVoc: boolean
) => {
  const headers = await exportFunctionsHeaders(transformedChannelScores, usesVoc);
  const data = await exportFunctionsData(transformedChannelScores, journeys, frictionScoreData, selected, usesVoc);
  return { headers, data, filename };
};


const exportFunctionsHeaders = async (transformedChannelScores: TransformedChannelScore[], usesVoc: boolean) => {
  let headers = [
    { label: 'Function', key: 'function' },
    { label: 'Feature', key: 'feature' },
    { label: 'Sec Domain', key: 'secure' }
  ];
  let segments: LabelKeyObject[] = [];
  let channelNames: LabelKeyObject[] = [];

  transformedChannelScores.forEach((transformedChannelScore) => {
    const segmentKey = 'segment-' + getSurveySegmentFromSegment(transformedChannelScore.segment)
    if (usesVoc) {
      segments.push({
        label: 'Weight',
        key: segmentKey
      });
    }

    channelNames.push({
      label: transformedChannelScore.providerName,
      key: transformedChannelScore.key
    });
  });

  return headers.concat(uniqBy(segments, 'key')).concat(channelNames);
};

const exportFunctionsData = async (
  transformedChannelScores: TransformedChannelScore[],
  journeys: FeatureJourney[],
  frictionScoreData: FrictionData[],
  selected: string[],
  usesVoc: boolean
) => {
  let data: Record<string, string>[] = [];

  let channelRow = { function: 'Channel' };
  let segmentRow = { function: 'Segment' };
  let channelVersionRow = { function: 'Channel Version' };
  let auditDateRow = { function: 'Audit Date' };

  let vocScoreRow = { function: 'VoC Score (Total)' };
  let utilityScoreRow = { function: 'Utility Score (Total)' };


  transformedChannelScores.forEach((transformedChannelScore) => {
    channelRow = {
      ...channelRow,
      [transformedChannelScore.key]: transformedChannelScore.channelName
    };

    segmentRow = {
      ...segmentRow,
      [transformedChannelScore.key]: transformedChannelScore.segmentName
    };

    channelVersionRow = {
      ...channelVersionRow,
      [transformedChannelScore.key]: transformedChannelScore.channel_version
    };

    auditDateRow = {
      ...auditDateRow,
      [transformedChannelScore.key]: getFormattedDate(transformedChannelScore.audit_date)
    };

    vocScoreRow = {
      ...vocScoreRow,
      [transformedChannelScore.key]: transformedChannelScore.cx_score
    };


    utilityScoreRow = {
      ...utilityScoreRow,
      [transformedChannelScore.key]: transformedChannelScore.score
    };


  });

  data.push(channelRow);
  data.push(segmentRow);
  data.push(channelVersionRow);
  data.push(auditDateRow);

  if (usesVoc) {
    data.push(vocScoreRow);
  }


  data.push(utilityScoreRow);


  journeys.forEach((journey) => {
    let functionRow = { function: journey.name };

    transformedChannelScores.forEach((transformedChannelScore) => {
      const segment = getSurveySegmentFromSegment(transformedChannelScore.segment);
      const weight = journey.weights.find((weight) => {
        return segment === weight.segment;
      });
      functionRow = {
        ...functionRow,
        [`segment-${segment}`]: weight?.weight
      };
    });

    data.push(functionRow);

    if (selected.includes(`journey#${journey.id}#${journey.secure ? '1' : '0'}`)) {
      journey.questions.forEach((question) => {
        let featureRow = {
          function: '',
          feature: question.text,
          secure: secDomainToString(question.secure_channelId)
        };

        transformedChannelScores.forEach((transformedChannelScore) => {
          let frictionScore = undefined;
          let value: Answer | undefined = undefined;
          let weight = undefined;

          const frictionData = frictionScoreData.find((fd) => fd.auditId === transformedChannelScore.auditId);

          if (frictionData) {
            frictionScore = frictionData.friction_scores.find((fs) => fs.question === question.id);
          }

          const channelJourney = transformedChannelScore[journey.secure ? 'secure' : 'nonsecure'].journeys.find((j) => {
            return j.journey === journey.id;
          });

          if (channelJourney) {
            value = channelJourney.answers.find((a) => a.question.id === question.id);
            weight = value ? value.weight.toFixed(1) : '';
          }

          const segment = getSurveySegmentFromSegment(transformedChannelScore.segment);

          featureRow = {
            ...featureRow,
            [`segment-${segment}`]: weight,
            [transformedChannelScore.key]: frictionScore
              ? frictionScore.friction_score
              : value?.value === 1
                ? 'Y'
                : value?.value === 2
                  ? 'N'
                  : 'N/A'
          };
        });

        data.push(featureRow);
      });

      let functionVoCRow = { feature: 'VoC Score' };
      let functionUtilityRow = { feature: 'Utility Score' };

      transformedChannelScores.forEach((transformedChannelScore) => {
        const channelJourney = transformedChannelScore[journey.secure ? 'secure' : 'nonsecure'].journeys.find((j) => {
          return j.journey === journey.id;
        });

        functionVoCRow = {
          ...functionVoCRow,
          [transformedChannelScore.key]: channelJourney ? channelJourney.cx_score : 0
        };

        functionUtilityRow = {
          ...functionUtilityRow,
          [transformedChannelScore.key]: channelJourney ? channelJourney.score : 0
        };
      });

      if (usesVoc) {
        data.push(functionVoCRow);
      }
      data.push(functionUtilityRow);
    }
  });

  return data;
};
