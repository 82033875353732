import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BlogImage } from '../../components/Blog/BlogImage';
import { Blog } from '../../support/contentful/content-types';
import getFormattedDate from '../../support/date/getFormattedDate';

const ItemBox = styled(Box)`
  && {
    &:hover {
      cursor: pointer;
    }
  }
`;

type FeaturedBlogItemProps = {
  item: Blog;
};

export const FeaturedBlogItem: FunctionComponent<FeaturedBlogItemProps> = ({ item }) => {
  const navigate = useNavigate();
  return (
    <Grid item xs={12} key={item.sys.id}>
      <Paper style={{ height: '100%' }}>
        <ItemBox onClick={() => navigate(`/insights/${item.fields.path}`)}>
          <BlogImage
            height="400px"
            s3={item.fields.imageOnS3}
            asset={item.fields.image}
          />
          <Box padding={2}>
            <Typography variant="h3">{item.fields.title}</Typography>
          </Box>
          <Box padding={2} display="flex">
            <Typography variant="body1">
              <strong>{item.fields.source}</strong>
            </Typography>
            <Divider orientation="vertical" flexItem style={{ marginLeft: '8px', marginRight: '8px' }} />
            <Typography variant="body1">{getFormattedDate(item.fields.originalPostDate)}</Typography>
          </Box>
        </ItemBox>
      </Paper>
    </Grid>
  );
};
