/**
 * Format a date to human readable string.
 *
 * @param {string|number} dateString
 *
 * @returns {string}
 */
const getFormattedDate = (dateString: string | number) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', options);
};

export default getFormattedDate
