import { FunctionView } from "../../../components/FunctionView/FunctionView";
import { FunctionComponent } from "react";
import { ChannelScore } from "../../../declarations/ChannelScore";

type FunctionsProps = {
    channelScores: ChannelScore[];
    sortedOnText?: string;
    usesVoc: boolean;
}

export const Functions: FunctionComponent<FunctionsProps> = (props) => {
    return (
        <FunctionView
            comparable={true}
            channelScores={props.channelScores}
            sortedOnText={props.sortedOnText}
            usesVoc={props.usesVoc}
        />
    )
}
