import styled from "styled-components";
import {Box, makeStyles, TableCell, TableHead, TableRow} from "@material-ui/core";
import {AddCircleOutline, RemoveCircleOutline} from "@material-ui/icons";

export const TableHeaderCell = styled(TableCell)`
  && {
    padding: 14px;
    font-weight: 700 !important;
    font-size: 14px !important;
    color: #001433 !important;
  }
`;

export const TableHeaderStyles = styled(TableHead)`
  && {
    tr th:not(:first-child) {
      border-left: 1px solid #ECEDEF;
    }
  }
`

export const TableCellStyles = styled(TableCell)`
  && {
    padding: 10px 16px !important;
    border-bottom: 1px solid #ECEDEF !important;
  }
`

export const DataRow = styled(TableRow)`
  && {
    &:hover {
      cursor: pointer;
    }
    border-bottom: 1px solid #ECEDEF !important;
  }
`;

export const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "1px solid #ECEDEF",
        },
    },
    popover: {
        pointerEvents: "none",
    },
    paper: {
        padding: "8px",
    },
});

export const ScoreContainer = styled(Box)`
  && {
    width: 30px;
    height: auto;
  }
`;

export const Loader = styled(Box)`
  && {
    position: absolute;
    background-color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
  }
`;

export const ExpandIcon = styled(AddCircleOutline)`
 && {
    font-size: 16px !important;
    color: #8b96a4 !important;
 }
`;

export const CollapseIcon = styled(RemoveCircleOutline)`
  && {
    font-size: 16px !important;
    color: #8b96a4 !important;
  }
`;
