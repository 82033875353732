import { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../components/Router/ProtectedRoute';
import { Resources } from '../pages/Resources';
import { Download } from '../pages/Resources/Download';
import { Unauthorised } from '../pages/Resources/Unauthorised';

export const ResourceContainer: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path={'/resources'}
        element={
          <ProtectedRoute>
            <Resources />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/resources/unauthorised'}
        element={
          <ProtectedRoute>
            <Unauthorised />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/resources/:resourceId'}
        element={
          <ProtectedRoute>
            <Download />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/reports/:resourceId'}
        element={
          <ProtectedRoute>
            <Download />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
