import { ApolloClient, gql } from '@apollo/client';
import { Criterion } from '../../declarations/Criterion';

/**
 * The response type for the api request.
 */
type GetSectorsResponse = {
  getSectors: { sectors: Criterion[] };
};

/**
 * The query to use when running the request.
 */
const query = gql`
  query allSectors {
    getSectors {
      sectors {
        id
        name
      }
    }
  }
`;

/**
 * Gets the sectors from the api.
 *
 * @param {ApolloClient<object>} client
 *
 * @throws Error
 */
export const getSectors = async (client: ApolloClient<object>) => {
  try {
    const response = await client.query<GetSectorsResponse>({
      query
    });

    return response.data.getSectors;
  } catch (caught) {
    throw Error();
  }
};
