import { TagCollection } from 'contentful';
import { clientFactory } from '../client';

type ContentfulTagCollectionSuccess = {
  type: 'success';
  response: TagCollection;
};

type ContentfulTagCollectionNoRecords = {
  type: 'no-records';
};

type ContentfulTagCollectionFailure = {
  type: 'failure';
  errors: [];
};

export type ContentfulTagCollectionResult =
  | ContentfulTagCollectionSuccess
  | ContentfulTagCollectionNoRecords
  | ContentfulTagCollectionFailure;

/**
 * Handles fetching the tag collection from contentful's sdk for the given query.
 *
 * @param {Record<string, string | number | boolean>} query
 *
 * @returns {Promise<ContentfulTagCollectionResult>}
 */
export const getTagCollection = async (
  query?: Record<string, string | number | boolean>
): Promise<ContentfulTagCollectionResult> => {
  try {
    const client = await clientFactory();
    const response = await client.getTags({
      ...query
    });

    if (response.items.length === 0) {
      return { type: 'no-records' };
    }

    return { type: 'success', response };
  } catch (caught) {
    return { type: 'failure', errors: [] };
  }
};
