import { Navigate, RouteProps } from 'react-router-dom';
import { useIdentityContext } from '../../contexts/IdentityContext';

export type PrivateRouteProps = RouteProps;

type ProtectedRouteProps = {
  children: JSX.Element;
};

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { isLoggedIn } = useIdentityContext();

  return isLoggedIn ? (
    <>{children}</>
  ) : (
    <Navigate to={'/login'} state={{ intended: window.location.pathname + window.location.search }} />
  );
}
