import { MuiThemeProvider, ThemeProvider } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import { FunctionComponent, useMemo } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { EnvironmentProvider } from './contexts/EnvironmentContext';
import { IdentityProvider } from './contexts/IdentityContext';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { environment } from './support/environment';
import { factory } from './theme';

/**
 * Configure Amplify with passed ENV variables,
 * This is done here because we need them present for auth checks early on.
 */
Auth.configure({
  Auth: {
    userPoolId: environment.cognitoUserPoolId,
    userPoolWebClientId: environment.cognitoUserPoolWebClientId,
    region: environment.cognitoRegion,
    mandatorySignIn: false
  }
});

//DISABLE ANALYTICS UNTIL WE GET LEGAL GREENLIGHT
// // Setup Google tag manager
// const tagManagerArgs = {
//   gtmId: 'GTM-PZT7S4Z'
// }
// TagManager.initialize(tagManagerArgs)

// Disabled Analytics until we can upgrade all the components

/**
 * Bootstrap Component for memorizing the theme.
 * Without memorizing it, it can cause some unintended side effects in production.
 */
const Bootstrap: FunctionComponent = ({ children }) => {
  const theme = useMemo(() => factory(environment.branding.name), []);

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>,
    </MuiThemeProvider>
  );
};

/**
//  * The main app entry point for React.
 */
ReactDOM.render(
  <Bootstrap>
    <EnvironmentProvider environment={environment}>
      <IdentityProvider>
        <App />
      </IdentityProvider>
    </EnvironmentProvider>
  </Bootstrap>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
