import { Button, Card, Typography } from '@material-ui/core';

import { Pagination, Skeleton } from '@material-ui/lab';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useJourneyView } from '../../hooks/journeys';

// Main styling for the modal
import '../Modal/FrictionscoreModal.css';

import FrictionScoreBreakdown from '../FrictionScore/FrictionScoreBreakdown';
import { Modal } from '../Modal/Modal';
import { CheckSingleIcon, ChevronDown, CrossCircle } from './icons';
import { ChannelScore, TransformedChannelScore } from '../../declarations/ChannelScore';
import { Asset } from '../../declarations/Asset';
import DataCard from './FrictionScoreLedgen';

const DisplayNA = 'span_na';
const DisplayCheck = 'span_check';
const DisplayCross = 'span_cross';
const DisplayValue = 'span_value';

const type = (score?: FrictionScore, value?: number) => {
  if (score !== undefined) {
    return DisplayValue;
  }

  if (value === 1) {
    return DisplayCheck;
  }

  if (value === 2) {
    return DisplayCross;
  }

  if (value === 3) {
    return DisplayNA;
  }

  return DisplayNA;
};

type BadgeProps = {
  score?: FrictionScore;
  value?: number;
};

const Badge = (props: BadgeProps) => {
  const style = useMemo(() => type(props.score, props.value), [props.score, props.value]);

  return (
    <>
      {/*span_value [children:text], span_cross[<CrossCircle/>], span_check [children: <CheckSingleIcon/>], span_na [children: text]*/}

      {style === DisplayNA && (
        <FrictionScoreIcon>
          <span className={'span_na'}>n/a</span>
        </FrictionScoreIcon>
      )}

      {style === DisplayCross && (
        <FrictionScoreIcon>
          <span className={'span_cross'}>
            <CrossCircle />
          </span>
        </FrictionScoreIcon>
      )}

      {style === DisplayCheck && (
        <FrictionScoreIcon>
          <span className={'span_check'}>
            <CheckSingleIcon />
          </span>
        </FrictionScoreIcon>
      )}

      {style === DisplayValue && (
        <FrictionScoreIcon>
          <span className={'span_value'}>{props.score?.friction_score}</span>
        </FrictionScoreIcon>
      )}
    </>
  );
};

type DropdownProps = {
  guid: string;
  channelScore: ChannelScore;
  journey: { id: number; secure: boolean };
  question: number;
  score?: FrictionScore;
  value?: number;
  onViewJourneysClick: (channelScore: ChannelScore, expanded: string[]) => void;
  provider: TransformedChannelScore;
};

const Dropdown = (props: DropdownProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { getJourneyAssets } = useJourneyView();
  const [assets, setAssets] = useState<Asset[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>(undefined);
  const [assetsLoaded, setAssetsLoaded] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  // @ts-ignore
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const auditDate = useMemo(() => new Date(props.channelScore.audit_date).toLocaleDateString(), [props.channelScore]);

  const loadAssets = useCallback(
    async (auditId: number, selectedCategory: number, selectedFunction: number) => {
      console.log('Loading assets:', auditId, selectedCategory, selectedFunction);
      try {
        const journeyAssets = await getJourneyAssets(auditId, selectedCategory);
        return journeyAssets
          .filter((asset) => asset.questions.includes(selectedFunction))
          .sort((assetA, assetB) => assetB.type - assetA.type);
      } catch (e) {
        console.error(e);
      }
    }
    , [getJourneyAssets])

  useEffect(() => {
    if (!open) {
      return;
    }

    loadAssets(props.channelScore.auditId, props.journey.id, props.question).then((a) => {
      if (a) {
        console.log('Useeffect loading assets. Got', a[0]);
        setAssets(a);
        setSelectedAsset(a[0]);
        setAssetsLoaded(true);
      }
    });
    return () => {
      setAssets([]);
    };
  }, [loadAssets, open, props.channelScore.auditId, props.journey.id, props.question]);

  const hasAsset = useMemo(() => {
    return assetsLoaded && assets.length > 0;
  }, [assets, assetsLoaded]);

  function changeAsset(page: number): void {
    setSelectedAsset(assets[page - 1]);
    setCurrentPage(page);
  }

  return (
    <>
      <PrettyButton aria-describedby={props.guid} variant="contained" color="primary" onClick={handleClick}>
        <Badge score={props.score} value={props.value} />
        <PopOverChevron>
          <ChevronDown viewBox={'0 0 12 6.8'} width={12} height={7} />
        </PopOverChevron>
      </PrettyButton>

      <Modal size="xl" open={open} onClose={handleClose} title={props.channelScore.providerName} closeable>
        <div className="grid-main" style={{ flexDirection: 'column-reverse', height: '100dvh' }}>
          <div className="grid-main-item">
            <Card variant="elevation" color="#333" style={{ height: '100%', padding: '10px 20px' }}>
              <div className="card-grid-container" style={{ gridTemplateColumns: props.score ? '1fr 1fr' : '1fr' }}>
                {' '}
                {/** We split the container in two columns if we have a friction score breakdown to show */}
                {props.score && (
                  <div className="card-column">
                    <FrictionScoreBreakdown score={props.score} />
                  </div>
                )}
                <div className="card-column">
                  <div className="grid-main-item">
                    <Typography variant="h4">Category:</Typography>
                    <Typography variant="body1">
                      {props.provider.journeys.find((j) => j.id === props.journey.id)?.name}
                    </Typography>
                  </div>
                  <div className="grid-main-item">
                    <Typography variant="h4">Function:</Typography>
                    <Typography>
                      {
                        props.provider.journeys
                          .find((j) => j.id === props.journey.id)
                          ?.questions.find((q) => q.id === props.question)?.text
                      }
                    </Typography>
                  </div>
                  <div className="grid-main-item">
                    <Typography variant={'h4'}>Audited</Typography>
                    <Typography variant={'body1'}>{auditDate}</Typography>
                  </div>
                  <div className="grid-main-item">
                    <Typography variant={'h4'}>Notes</Typography>
                    {hasAsset ? (
                      selectedAsset?.narratives.map((narrative, index) => (
                        <div key={index} style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                          <Typography variant={'body2'}>{narrative}</Typography>
                        </div>
                      ))
                    ) : (
                      <Typography variant={'body2'}>No notes available</Typography>
                    )}
                  </div>
                </div>
              </div>
            </Card>
            {props.score && <DataCard />}
          </div>

          {/**
           * Renders a grid item with an image viewer and pagination.
           *
           * @param {boolean} hasAsset - Indicates if there is an asset to display.
           * @param {Asset[]} assets - The list of assets to display.
           * @param {number} currentPage - The current page of the pagination.
           * @param {function} changeAsset - Callback function to change the selected asset.
           * @param {Asset} selectedAsset - The currently selected asset.
           *
           * @returns {JSX.Element} The rendered grid item with the image viewer and pagination.
           */}
          <div className="grid-main-item grid-col-span-2">
            {hasAsset ? (
              <div className="grid-img-viewer">
                <div className="grid-header">
                  <Pagination
                    count={assets.length}
                    color="secondary"
                    variant="outlined"
                    shape="rounded"
                    page={currentPage}
                    onChange={(ev, page) => changeAsset(page)}
                  />
                </div>
                <div className="grid-main-item grid-image-container">
                  <Card id="asset-viewer" style={{ minHeight: '500px' }}>
                    {selectedAsset !== undefined ? (
                      <>
                        {selectedAsset.type === 2 ? (
                          <video src={selectedAsset.location} height={'600px'} controls></video>
                        ) : (
                          <img
                            src={selectedAsset.location}
                            style={{ minHeight: '100px', maxHeight: '73dvh' }}
                            alt="corona"
                          />
                        )}
                      </>
                    ) : (
                      <Skeleton variant="rect" animation="wave" width={'100%'} height={300} />
                    )}
                  </Card>
                </div>
              </div>
            ) : (
              <Skeleton variant="rect" animation="wave" width={'100%'} height={300} />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

const FrictionScoreIcon = styled.span`
  && {
    padding: 4px;
    min-height: 0px;
    min-width: 0px;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    &:not(:only-child) {
      &:before {
        content: '';
        order: 2;
        box-sizing: border-box;
        display: block;
        height: 20px;
        width: 1px;
        background-color: #d9dbe0;
        margin-left: 4px;
      }
    }
    & > span {
      min-height: 0px;
      min-width: 0px;
      width: 24px;
      height: 24px;
      border-radius: 24px;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      text-align: center;

      &:nth-of-type(1) {
        order: 1;
        font-size: 12px;
        line-height: 13px;
        letter-spacing: -0.53px;
        font-weight: bold;
      }
      &:nth-of-type(2) {
        order: 3;
        svg {
          width: 12px;
          height: 12px;
        }
      }

      &.span_value {
        color: white;
        background-color: #24ab3b;
      }
      &.span_check {
        color: #24ab3b;
        color: white;
        background-color: #24ab3b;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      &.span_cross {
        color: #8b96a4;
      }

      &.span_na {
        color: #647386;
        font-size: 14px;
        letter-spacing: -0.2px;
        line-height: 16px;
        font-weight: normal;
      }
    }
  }
`;

const PopOverChevron = styled.span`
  && {
    min-height: 0px;
    min-width: 0px;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

const PrettyButton = styled(Button)`
  && {
    background-color: white;
    color: black;
    padding: 0;
    margin: 0;
    line-height: 1;
    box-shadow: 0 1px 3px 0 rgba(65, 69, 88, 0.2);
    border-radius: 20px;
    cursor: pointer;
    &:hover {
      background-color: white;
      color: black;
      box-shadow: 0 1px 3px 0 rgba(65, 69, 88, 0.4);
    }
  }
`;

export const FrictionScore = {
  Dropdown: Dropdown,
  Badge: Badge
};
