import React, {FunctionComponent} from "react";
import {Button} from "@material-ui/core";

type ResendVerificationButtonProps = {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const ResendVerificationButton: FunctionComponent<ResendVerificationButtonProps> = (props) => {
    return (
        <Button
            size="small"
            variant="text"
            color="primary"
            style={{
                boxShadow: "none",
                textDecoration: "underline",
            }}
            onClick={props.onClick}
        >
            Resend Verification Code
        </Button>
    )
}
