import { Container, Grid, useTheme } from "@material-ui/core"
import { LogoEyeIcon } from "../Common/icons"

export const Footer = () => {
    const theme = useTheme();
    return (
        <Container maxWidth={false} disableGutters={true} style={{
            backgroundColor: theme.palette.primary.main,
            color: "#98A5B3",
            fontSize: "14px",
            paddingTop: "14px",
            paddingBottom: "16px",
        }}>
            <Container maxWidth={"lg"}>
                <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center">
                    <Grid container item xs={12}>
                       <LogoEyeIcon styles={{ display: "inline-block", verticalAlign: "middle", marginRight: "8px"}}/> © 2018-{(new Date().getFullYear())} iSky Research | {APP_VERSION}
                    </Grid>
                </Grid>
            </Container>
        </Container>
    )
}
