import { Box, Button, Container, Divider, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { ChangeEvent, useEffect, useState } from 'react';
import { ErrorBox } from '../../components/Auth/ErrorBox';
import { Page } from '../../components/Layout/Page';
import { AmplifyUser, useAmplify } from '../../hooks/amplify';
import { changePassword } from '../../support/amplify/change-password';
import { updateUserAttributes } from '../../support/amplify/update-user-attributes';
import { PASSWORD_POLICY, fn as passwordValidator } from '../../support/validation/password';
import { UNEXPECTED_ERROR_MESSAGE } from '../../helpers/messages';

const Input = withStyles({
  root: {
    '& .MuiInputBase-root': {
      color: '#647386'
    }
  }
})(TextField);

export const MyAccount = () => {
  const { currentUser } = useAmplify();

  const initialDetailsState = {
    name: '',
    familyName: '',
    position: ''
  };

  const initialPasswordsState = {
    current: '',
    password: '',
    confirmPassword: ''
  };

  const [userAttributes, setUserAttributes] = useState<{ [key: string]: string }>();
  const [details, setDetails] = useState(initialDetailsState);
  const [detailsError, setDetailsError] = useState<string | undefined>(undefined);
  const [wasDetailsRecentlyChanged, setWasDetailsRecentlyChanged] = useState(false);

  const [passwords, setPasswords] = useState(initialPasswordsState);
  const [passwordsError, setPasswordsError] = useState<string | undefined>(undefined);
  const [wasPasswordRecentlyChanged, setWasPasswordRecentlyChanged] = useState(false);

  const websiteURL = import.meta.env.REACT_APP_WEBSITE_URL;

  const handleDetailsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const form = {
      ...details,
      [event.target.name]: event.target.value
    };

    setDetails(form);
  };

  const handlePasswordsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const form = {
      ...passwords,
      [event.target.name]: event.target.value
    };

    setPasswords(form);
  };

  const handleDetailsSubmit = async () => {
    const user = await currentUser();

    if (user === null || user === undefined) {
      setDetailsError(UNEXPECTED_ERROR_MESSAGE);
      return;
    }

    setDetailsError(undefined);

    const updateUserAttributesResult = await updateUserAttributes(user, {
      name: details.name,
      family_name: details.familyName,
      'custom:Position': details.position
    });

    switch (updateUserAttributesResult.type) {
      case 'success':
        setWasDetailsRecentlyChanged(true);

        setTimeout(() => {
          setWasDetailsRecentlyChanged(false);
        }, 5000);
        break;
      case 'unknown-error':
        setDetailsError(UNEXPECTED_ERROR_MESSAGE);
        break;
      default:
        setDetailsError(UNEXPECTED_ERROR_MESSAGE);
    }
  };

  const validatePassword = async (password: string, confirmed: string) => {
    if (password !== confirmed) {
      setPasswordsError(`Passwords do not match!`);

      return false;
    }

    if (!passwordValidator(password) || !passwordValidator(confirmed)) {
      setPasswordsError(`${PASSWORD_POLICY}`);

      return false;
    }

    return true;
  };

  const handlePasswordsSubmit = async () => {
    const user = await currentUser();

    if (user === null || user === undefined) {
      setDetailsError(UNEXPECTED_ERROR_MESSAGE);
      return;
    }

    setPasswordsError(undefined);

    const isPasswordValid = await validatePassword(passwords.password, passwords.confirmPassword);

    if (!isPasswordValid) return;

    const changePasswordResult = await changePassword(user, passwords.current, passwords.password);

    switch (changePasswordResult.type) {
      case 'success':
        setWasPasswordRecentlyChanged(true);

        setTimeout(() => {
          setWasPasswordRecentlyChanged(false);
        }, 5000);
        break;
      case 'invalid-parameter':
        setPasswordsError(changePasswordResult.error);
        break;
      case 'unknown-error':
        setPasswordsError(UNEXPECTED_ERROR_MESSAGE);
        break;
      default:
        setPasswordsError(UNEXPECTED_ERROR_MESSAGE);
    }

    setPasswords(initialPasswordsState);
  };

  useEffect(() => {
    const getUserAttributes = async () => {
      const user = (await currentUser()) as AmplifyUser;

      setUserAttributes(user.attributes);
    };

    getUserAttributes();
  }, [currentUser]);

  useEffect(() => {
    if (userAttributes !== undefined) {
      setDetails(() => {
        return {
          name: userAttributes.name,
          familyName: userAttributes.family_name,
          position: userAttributes['custom:Position']
        };
      });
    }
  }, [userAttributes]);

  return (
    <Page>
      <Container>
        <Box marginTop={4} marginBottom={2}>
          <Grid container spacing={4} justify="space-between" alignItems="center">
            <Grid item xs={12}>
              <Typography variant={'h1'}>My Account</Typography>
            </Grid>
          </Grid>
        </Box>

        <Divider orientation="horizontal" style={{ marginLeft: '4px', marginRight: '4px' }} />

        {detailsError && (
          <Box marginY={2}>
            <Grid container alignItems={'center'}>
              <Grid container item xs={4} justify={'flex-end'}>
                &nbsp;
              </Grid>
              <Grid container item xs={8}>
                <Box width={'360px'}>
                  <Paper
                    style={{
                      borderColor: '#DC2626',
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      backgroundColor: '#FCA5A5'
                    }}
                  >
                    <Box display={'flex'} padding={1}>
                      <InfoOutlined
                        fontSize={'small'}
                        style={{
                          color: '#DC2626',
                          marginRight: '4px'
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: '12px',
                          color: '#DC2626'
                        }}
                        variant={'subtitle2'}
                      >
                        {detailsError}
                      </Typography>
                    </Box>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        <Box marginY={4}>
          {wasDetailsRecentlyChanged === true && (
            <Box marginY={2}>
              <Grid container alignItems={'center'}>
                <Grid container item xs={4} justify={'flex-end'}>
                  &nbsp;
                </Grid>
                <Grid container item xs={8}>
                  <Box width={'360px'}>
                    <Alert severity={'success'} color={'success'}>
                      Details Successfully Changed!
                    </Alert>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}

          <Grid container alignItems={'center'} spacing={2}>
            <Grid container item xs={4} justify={'flex-end'}>
              <Typography
                style={{
                  fontSize: '14px',
                  color: '#001433',
                  fontWeight: 600
                }}
                variant={'subtitle2'}
              >
                First Name
              </Typography>
            </Grid>
            <Grid container item xs={8}>
              <Box width={'360px'}>
                <Input
                  style={{
                    color: '#647386',
                    backgroundColor: 'white',
                    marginTop: '4px'
                  }}
                  onChange={handleDetailsChange}
                  name={'name'}
                  placeholder={'First Name'}
                  fullWidth={true}
                  size={'small'}
                  variant={'outlined'}
                  required={true}
                  type={'text'}
                  value={details.name}
                />
              </Box>
            </Grid>
            <Grid container item xs={4} justify={'flex-end'}>
              <Typography
                style={{
                  fontSize: '14px',
                  color: '#001433',
                  fontWeight: 600
                }}
                variant={'subtitle2'}
              >
                Last Name
              </Typography>
            </Grid>
            <Grid container item xs={8}>
              <Box width={'360px'}>
                <Input
                  style={{
                    color: '#647386',
                    backgroundColor: 'white',
                    marginTop: '4px'
                  }}
                  onChange={handleDetailsChange}
                  name={'familyName'}
                  placeholder={'Last Name'}
                  fullWidth={true}
                  size={'small'}
                  variant={'outlined'}
                  required={true}
                  type={'text'}
                  value={details.familyName}
                />
              </Box>
            </Grid>

            <Grid container item xs={4} justify={'flex-end'}>
              <Typography
                style={{
                  fontSize: '14px',
                  color: '#001433',
                  fontWeight: 600
                }}
                variant={'subtitle2'}
              >
                Position
              </Typography>
            </Grid>
            <Grid container item xs={8}>
              <Box width={'360px'}>
                <Input
                  style={{
                    color: '#647386',
                    backgroundColor: 'white',
                    marginTop: '4px'
                  }}
                  onChange={handleDetailsChange}
                  name={'position'}
                  placeholder={'Position'}
                  fullWidth={true}
                  size={'small'}
                  variant={'outlined'}
                  required={true}
                  type={'text'}
                  value={details.position}
                />
              </Box>
            </Grid>

            <Grid container item xs={4} justify={'flex-end'}>
              &nbsp;
            </Grid>
            <Grid container item xs={8}>
              <Box width={'360px'}>
                <Button color={'secondary'} variant={'contained'} onClick={handleDetailsSubmit}>
                  Save Details
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box marginY={4}>
          <Grid container alignItems={'center'} spacing={2}>
            <Grid container item xs={4} justify={'flex-end'}>
              &nbsp;
            </Grid>
            <Grid container item xs={8}>
              <Box width={'360px'}>
                <Paper
                  style={{
                    borderColor: '#FEAF38',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    backgroundColor: '#FEFAF1',
                    boxShadow: 'none'
                  }}
                >
                  <Box display={'flex'} padding={1}>
                    <InfoOutlined
                      fontSize={'small'}
                      style={{
                        color: '#FEAF38',
                        marginRight: '4px'
                      }}
                    />
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: '#647386'
                      }}
                      variant={'subtitle2'}
                    >
                      To change any of the details below, please{' '}
                      {websiteURL !== undefined ? (
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={`${websiteURL}/#contactArea`}
                          style={{ color: '#001433' }}
                        >
                          get in touch
                        </a>
                      ) : (
                        'get in touch'
                      )}
                    </Typography>
                  </Box>
                </Paper>
              </Box>
            </Grid>

            <Grid container item xs={4} justify={'flex-end'}>
              <Typography
                style={{
                  fontSize: '14px',
                  color: '#001433',
                  fontWeight: 600
                }}
                variant={'subtitle2'}
              >
                Company
              </Typography>
            </Grid>
            <Grid container item xs={8}>
              <Box width={'360px'}>
                <Typography
                  style={{
                    fontSize: '14px',
                    color: '#647386'
                  }}
                  variant={'subtitle1'}
                >
                  {userAttributes && userAttributes['custom:Company']}
                </Typography>
              </Box>
            </Grid>

            <Grid container item xs={4} justify={'flex-end'}>
              <Typography
                style={{
                  fontSize: '14px',
                  color: '#001433',
                  fontWeight: 600
                }}
                variant={'subtitle2'}
              >
                Country
              </Typography>
            </Grid>
            <Grid container item xs={8}>
              <Box width={'360px'}>
                <Typography
                  style={{
                    fontSize: '14px',
                    color: '#647386'
                  }}
                  variant={'subtitle1'}
                >
                  {userAttributes && userAttributes['custom:Country']}
                </Typography>
              </Box>
            </Grid>

            <Grid container item xs={4} justify={'flex-end'}>
              <Typography
                style={{
                  fontSize: '14px',
                  color: '#001433',
                  fontWeight: 600
                }}
                variant={'subtitle2'}
              >
                Email Address
              </Typography>
            </Grid>
            <Grid container item xs={8}>
              <Box width={'360px'}>
                <Typography
                  style={{
                    fontSize: '14px',
                    color: '#647386'
                  }}
                  variant={'subtitle1'}
                >
                  {userAttributes && userAttributes.email}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box marginTop={4}>
          <Grid container alignItems={'center'} spacing={2}>
            <Grid container item xs={4} justify={'flex-end'}>
              &nbsp;
            </Grid>
            <Grid container item xs={8}>
              <Box width={'360px'}>
                <Typography
                  style={{
                    fontSize: '14px',
                    color: '#001433',
                    fontWeight: 600
                  }}
                  variant={'subtitle2'}
                >
                  Change Password
                </Typography>

                <Typography
                  style={{
                    fontSize: '14px',
                    color: '#647386'
                  }}
                  variant={'subtitle2'}
                >
                  {PASSWORD_POLICY}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {passwordsError && (
          <Box marginY={2}>
            <Grid container alignItems={'center'}>
              <Grid container item xs={4} justify={'flex-end'}>
                &nbsp;
              </Grid>
              <Grid container item xs={8}>
                <Box width={'360px'}>
                  <ErrorBox message={passwordsError} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {wasPasswordRecentlyChanged === true && (
          <Box marginY={2}>
            <Grid container alignItems={'center'}>
              <Grid container item xs={4} justify={'flex-end'}>
                &nbsp;
              </Grid>
              <Grid container item xs={8}>
                <Box width={'360px'}>
                  <Alert severity={'success'} color={'success'}>
                    Password Successfully Changed!
                  </Alert>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        <Box>
          <Grid container alignItems={'center'} spacing={2}>
            <Grid container item xs={4} justify={'flex-end'}>
              <Typography
                style={{
                  fontSize: '14px',
                  color: '#001433',
                  fontWeight: 600
                }}
                variant={'subtitle2'}
              >
                Current Password
              </Typography>
            </Grid>
            <Grid container item xs={8}>
              <Box width={'360px'}>
                <Input
                  style={{
                    color: '#647386',
                    backgroundColor: 'white',
                    marginTop: '4px'
                  }}
                  onChange={handlePasswordsChange}
                  name={'current'}
                  placeholder={'Current Password'}
                  fullWidth={true}
                  size={'small'}
                  variant={'outlined'}
                  required={true}
                  type={'password'}
                  value={passwords.current}
                />
              </Box>
            </Grid>

            <Grid container item xs={4} justify={'flex-end'}>
              <Typography
                style={{
                  fontSize: '14px',
                  color: '#001433',
                  fontWeight: 600
                }}
                variant={'subtitle2'}
              >
                New Password
              </Typography>
            </Grid>
            <Grid container item xs={8}>
              <Box width={'360px'}>
                <Input
                  style={{
                    color: '#647386',
                    backgroundColor: 'white',
                    marginTop: '4px'
                  }}
                  onChange={handlePasswordsChange}
                  name={'password'}
                  placeholder={'Password'}
                  fullWidth={true}
                  size={'small'}
                  variant={'outlined'}
                  required={true}
                  type={'password'}
                  value={passwords.password}
                />
              </Box>
            </Grid>

            <Grid container item xs={4} justify={'flex-end'}>
              <Typography
                style={{
                  fontSize: '14px',
                  color: '#001433',
                  fontWeight: 600
                }}
                variant={'subtitle2'}
              >
                Confirm New Password
              </Typography>
            </Grid>
            <Grid container item xs={8}>
              <Box width={'360px'}>
                <Input
                  style={{
                    color: '#647386',
                    backgroundColor: 'white',
                    marginTop: '4px'
                  }}
                  onChange={handlePasswordsChange}
                  name={'confirmPassword'}
                  placeholder={'Confirm Password'}
                  fullWidth={true}
                  size={'small'}
                  variant={'outlined'}
                  required={true}
                  type={'password'}
                  value={passwords.confirmPassword}
                />
              </Box>
            </Grid>

            <Grid container item xs={4} justify={'flex-end'}>
              &nbsp;
            </Grid>
            <Grid container item xs={8}>
              <Box width={'360px'}>
                <Button color={'secondary'} variant={'contained'} onClick={handlePasswordsSubmit}>
                  Change Password
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};
