import { ChannelScore } from '../../declarations/ChannelScore';
import { JourneyCriterion } from '../../declarations/Criterion';
import { getChannelJourneys } from '../../hooks/journeys';

/**
 * Handles transforming of data into a single chart data series for Highcharts.
 *
 * @param {ChannelScore[]} channelScore
 * @param {JourneyCriterion[]} secureJourneys
 * @param {JourneyCriterion[]} nonSecureJourneys
 * @param {'score' | 'cx_score'} type
 */
export const withSingleChartData = (
  channelScore: ChannelScore,
  secureJourneys: JourneyCriterion[],
  nonSecureJourneys: JourneyCriterion[],
  type: 'score' | 'cx_score'
) => {
  const journeys = getChannelJourneys(channelScore, secureJourneys, nonSecureJourneys);

  const categories = journeys.map((data) => {
    return `${data.id}#${data.secure}#${data.name}`;
  });

  const scoreData = journeys.map((data) => {
    return { y: data[type] };
  });

  return {
    categories,
    series: [
      {
        type: 'area',
        lineWidth: 2,
        name: channelScore.providerName,
        data: scoreData,
        pointPlacement: 'on',
        color: '#E5126E',
        fillColor: 'rgba(229,18,110,0.1)',
        // fillOpacity: .2,
        marker: {
          symbol: 'circle'
        }
      }
    ]
  };
};
