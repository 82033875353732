import { ApolloProvider } from '@apollo/client';
import Routes from '../../Routes';
import { AdvancedJourneyProvider } from '../../contexts/AdvancedJourneyContext';
import { ChannelProvider } from '../../contexts/ChannelContext';
import { CompareChannelProvider } from '../../contexts/CompareChannelContext';
import { ComparisonProvider } from '../../contexts/ComparisonContext';
import { useIdentityContext } from '../../contexts/IdentityContext';
import { PrioritisationOverlayProvider } from '../../contexts/PrioritisationOverlayContext';
import { CriteriaProvider } from '../../contexts/Search/Criteria';
import { QueryProvider } from '../../contexts/Search/Query';
import { createApolloClient } from '../../support/graphql/client';
import Layout from './Layout';

/**
 * The authenticated component.
 *
 * Handles all the global contexts that are required,
 * includes things like Apollo for GraphQL, Search Results/Query state.
 */
export const Authenticated = () => {
  const { session } = useIdentityContext();

  const client = createApolloClient();

  if (!session) return null;
  if (!client) return null;

  return (
    <ApolloProvider client={client}>
      <CriteriaProvider>
        <QueryProvider>
          <ComparisonProvider>
            <PrioritisationOverlayProvider>
              <AdvancedJourneyProvider>
                <ChannelProvider>
                  <CompareChannelProvider>
                    <Layout>
                      <Routes />
                    </Layout>
                  </CompareChannelProvider>
                </ChannelProvider>
              </AdvancedJourneyProvider>
            </PrioritisationOverlayProvider>
          </ComparisonProvider>
        </QueryProvider>
      </CriteriaProvider>
    </ApolloProvider>
  );
};
