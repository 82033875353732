import { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../components/Router/ProtectedRoute';
import { Index as BlogIndex } from '../pages/Blog/Index';
import { Search as BlogSearch } from '../pages/Blog/Search';
import { Show as BlogShow } from '../pages/Blog/Show';

export const BlogContainer: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path={'/insights'}
        element={
          <ProtectedRoute>
            <BlogIndex />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/insights/search'}
        element={
          <ProtectedRoute>
            <BlogSearch />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/insights/:id'}
        element={
          <ProtectedRoute>
            <BlogShow />
          </ProtectedRoute>
        }
      />

      {/* REDIRECT TO INSIGHTS AS IT IS THE NEW NAMING */}
    </Routes>
  );
};
