import { Segments } from '../types/Segments';
import { useCriteria } from '../contexts/Search/Criteria';
import { useCallback } from 'react';

/**
 * A hook for using segments in components.
 */
export const useSegments = () => {
  const searchCriteria = useCriteria();


  /**
   * Resolve the name of the segment by id.
   *
   * @param {string | null} segment
   */
  const resolveName = useCallback(
    (segment: Segments) => {
      const criterion = searchCriteria.state.segments.find((s) => {
        return s.id === segment;
      });

      if (!criterion) {
        return null;
      }

      return criterion.name;
    }
    , [searchCriteria.state.segments])

  return {
    resolveName
  };
};
