import { ApolloClient, useApolloClient } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useSegments } from '../../hooks/segments';
import { getTotalDemographics } from '../../services/api/get-total-demographics';
import { Segments } from '../../types/Segments';

type DemographicsBadgeProps = {
  segment: Segments;
  audience: MapKind<number[]>;
  withName?: boolean;
};

const withTotalDemographics = async (client: ApolloClient<object>, audience: MapKind<number[]>, segment: Segments) => {
  const response = await getTotalDemographics(client, {
    audience: audience,
    as_at_date: new Date().toISOString().split('T')[0],
    segment: segment
  })
  return response.find(d => d.segment === segment);
}

export const DemographicsBadge = (props: DemographicsBadgeProps) => {
  const { withName = true } = props;

  const client = useApolloClient();
  const { resolveName } = useSegments();

  const [loading, setLoading] = useState<boolean>(true);
  const [value, setValue] = useState<number>();

  const name = useMemo(
    () => resolveName(props.segment),

    [props.segment, resolveName]
  )

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let record;
      try {
        record = await withTotalDemographics(client, props.audience, props.segment)
      }
      catch (ex) {
        console.error(ex)
      }
      setValue(record?.total_count || undefined);
      setLoading(false)
    }
    fetchData();
  }, [props.audience, props.segment, client]
  )

  const transformedName = useMemo(() => (name === 'Business' ? 'Business/Corporate' : name), [name]);

  return (
    <Typography variant={'h3'}>
      {withName ? `${transformedName} (N)` : 'N'} = {loading ? '...' : value === undefined ? 0 : value}
    </Typography>
  );
};
