import { useCallback } from 'react';
import { useCriteria } from '../contexts/Search/Criteria'
import { useSearchQuery } from '../contexts/Search/Query'
import { Weight } from '../declarations/Question';
import { Segments, getSurveySegmentFromSector } from '../types/Segments';

/**
 * A hook for using questions in components.
 */
export const useQuestions = () => {
  const searchCriteria = useCriteria();
  const searchQuery = useSearchQuery();
  const selectedSector = searchQuery.state.selectedCycle.linkedSector

  /**
   * Gets the question based on the input.
   *
   * @param {number} id
   * @param {number} journey
   * @param {number} segment
   * @param {number} secure
   */
  const resolveQuestion = useCallback(
    (id: number, journey: number, segment: Segments, secure: number) => {
      return searchCriteria.state.questions.questions.find((q) => {
        return (
          q.segmentId === segment &&
          q.journeyId === journey &&
          q.sectorId === selectedSector &&
          q.id === id
        );
      });
    }, [searchCriteria.state.questions.questions, selectedSector])

  /**
   * Get the journey based on the input.
   *
   * @param {number} id
   */
  const resolveJourney = useCallback(
    (id: number) => {
      return searchCriteria.state.questions.journeys.find((q) => {
        return q.journeyId === id;
      });
    }, [searchCriteria.state.questions.journeys])

  /**
   * Get the weights based on the sector being searched. returns 0 or 1 items
   *
   * @param {Weight[]} weights
   */
  const resolveWeights = useCallback(
    (weights: Weight[]): Weight[] => {
      const segment = getSurveySegmentFromSector(selectedSector);

      return weights
        .filter(weight => weight.segment === segment)
    }, [selectedSector])

  /**
   * Get the weight based on the input.
   *
   * @param {number} id
   * @param {number} journey
   * @param {number} segment
   * @param {number} secure
   */
  const getWeight = useCallback(
    (id: number, journey: number, segment: Segments, secure: number): Weight[] => {
      const question = resolveQuestion(id, journey, segment, secure);

      if (!question) {
        return [];
      }

      return resolveWeights(question.weights);
    }, [resolveQuestion, resolveWeights])

  /**
   * Get the specific journey weight based on input.
   *
   * @param {number} id
   * @param {number} secure
   */
  const getJourneyWeights = useCallback(
    (id: number) => {
      const journey = resolveJourney(id);

      if (!journey) {
        return [];
      }
      return resolveWeights(journey.weights);
    }, [resolveJourney, resolveWeights])

  return {
    getWeight,
    getJourneyWeights,
    resolveWeights
  };
};
