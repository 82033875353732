import { ApolloClient, gql } from '@apollo/client'
import { ChannelScore } from '../../declarations/ChannelScore';

/**
 * The response type for the api request.
 */
type GetChannelScoresResponse = {
  getChannelScores: { channels: ChannelScore[]; count: number };
};

/**
 * The variables used for the request.
 */
export type GetChannelScoresVariables = {
  markets: number[];
  providers: number[];
  segments: number[];
  channels: number[];
  sectors: number[];
  questions?: { type: string; questionIds: number[] };
  audience: Record<string, number[]>;
  orderBy: { field: string; direction: string; friction_question?: number };
  offset: number;
  limit: number;
  as_at_date?: string;
};

/**
 * The query to use when running the request.
 */
const query = gql`
  query channelScores(
    $offset: Int
    $limit: Int
    $sectors: [Int]
    $markets: [Int]
    $channels: [Int]
    $as_at_date: AWSDate
    $providers: [Int]
    $segments: [Int]
    $audience: AudienceSearch
    $orderBy: OrderBy
    $questions: QuestionCriteria
  ) {
    getChannelScores(
      offset: $offset
      limit: $limit
      where: {
        sector: $sectors
        market: $markets
        channel: $channels
        provider: $providers
        segment: $segments
        questions: $questions
      }
      audience: $audience
      orderBy: $orderBy
      as_at_date: $as_at_date
    ) {
      count
      limit
      offset
      channels {
        auditId
        audit_date
        channel
        channel_version
        cx_score
        market
        provider
        score
        sector
        segment
        journeys {
          answers {
            question {
              id
              journey
              predecessor
              sector
              secure_channel
              segment
              text
              valid_from
              valid_to
            }
            value
            weight
          }
          cx_score
          journey
          score
          weight
          journey_seq
        }
        score
      }
    }
  }
`;

/**
 * Gets the channel scores from the api.
 *
 * @param {ApolloClient<object>} client
 * @param {GetChannelScoresVariables} variables
 *
 * @throws Error
 */
export const getChannelScores = async (client: ApolloClient<object>, variables: GetChannelScoresVariables) => {
  try {
    const channelScoresResult = await client.query<GetChannelScoresResponse, GetChannelScoresVariables>({
      query,
      variables: preProcessGetChannelScoresParams(variables)
    });

    // fudge the returned output to make it fit the old API shape
    const orig = channelScoresResult.data as any;
    for (const channel of orig.getChannelScores.channels) {
      channel.secure = { journeys: channel.journeys }
      channel.nonsecure = { journeys: [] }
    }
    return channelScoresResult.data

  } catch (caught) {
    console.error(caught)
  }
};

type preProcessParams = {
  questions?: { type: string; questionIds: number[] }
  as_at_date?: string;
}

/*
 * In the Query object, as_at_date is mandatory and a blank value is represented as empty string because
 * that's how HTML forms work, but in the API we need to omit the value if it's blank.
 * 
 * Also, in the app an empty question list means don't restrict by questions, but if we pass an empty question
 * list to the server, it assumes no questions, so instead we have to omit the questions parameter entirely.
 */
export const preProcessGetChannelScoresParams = <T extends preProcessParams>(variables: T): T | undefined => ({
  ...variables,
  as_at_date: variables.as_at_date || undefined,
  questions: variables.questions?.questionIds.length ? variables.questions : undefined
})
