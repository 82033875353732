import {
    BusinessBanking2021Sector, BusinessBanking2023Sector,
    LifeInsurance2021Sector, LifeInsurance2023Sector,
    PersonalBanking2021Sector, PersonalBanking2023Sector,
    Sectors
} from "./Sectors"

export const Corporate2021Segment = 1
export const Business2021Segment = 2
export const Personal2021Segment = 3
export const LifeInsurance2021Segment = 4
export const TelecomsSegment = 5
export const LifeInsurance2023Segment = 6
export const Personal2023Segment = 7
export const Corporate2023Segment = 8
export const Business2023Segment = 9
export const MerchantServices2023Segment = 10

export type Segments =
    typeof Corporate2021Segment |
    typeof Business2021Segment |
    typeof Personal2021Segment |
    typeof LifeInsurance2021Segment |
    typeof TelecomsSegment |
    typeof LifeInsurance2023Segment |
    typeof Personal2023Segment |
    typeof Corporate2023Segment |
    typeof Business2023Segment |
    typeof MerchantServices2023Segment

/*
 * survey income bands are different for personal and commercial. they are
 * indicated by using segment = 2 for commercial and 3 for personal
 */
export const getIncomeSurveySegmentFromSector = (sector: Sectors) =>
    [BusinessBanking2021Sector, BusinessBanking2023Sector].includes(sector)
        ? Business2021Segment
        : Personal2021Segment

/*
 * weights for corporate are stored under the corresponding business segment
 */
const surveySegmentMap: { [key: number]: Segments } =
{
    [Corporate2021Segment]: Business2021Segment,
    [Corporate2023Segment]: Business2023Segment
}

export const getSurveySegmentFromSegment = (segment: Segments) =>
    surveySegmentMap[segment] || segment

const sectorToSurveySegmentMap: { [key: number]: Segments } = {
    [BusinessBanking2021Sector]: Business2021Segment,
    [PersonalBanking2021Sector]: Personal2021Segment,
    [BusinessBanking2023Sector]: Business2023Segment,
    [PersonalBanking2023Sector]: Personal2023Segment,
    [LifeInsurance2021Sector]: LifeInsurance2021Segment,
    [LifeInsurance2023Sector]: LifeInsurance2023Segment,
}

export const getSurveySegmentFromSector = (sector: Sectors): Segments | undefined =>
    sectorToSurveySegmentMap[sector]

