import { useApolloClient } from '@apollo/client';
import { find, sortBy, uniqBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useCriteria } from '../contexts/Search/Criteria';
import { getAssets } from '../support/graphql/queries';
import { AssetJourney } from '../types/Journeys';
import { ChannelJourney, ChannelScore, WeightedJourneyScore } from '../declarations/ChannelScore';
import { JourneyCriterion } from '../declarations/Criterion';
import { Segments } from '../types/Segments';

const transformToChannelJourney = (
  weightedJourneyScore: WeightedJourneyScore,
  journeys: JourneyCriterion[],
  secure: boolean,
  segment: Segments,
  auditId: number
): ChannelJourney => {
  return {
    id: weightedJourneyScore.journey,
    name: find(journeys, { id: weightedJourneyScore.journey })?.name,
    score: weightedJourneyScore.score,
    cx_score: weightedJourneyScore.cx_score,
    secure: secure,
    key: `${weightedJourneyScore.journey}#${secure ? '1' : '0'}`,
    weight: weightedJourneyScore.weight,
    segment: segment,
    answers: weightedJourneyScore.answers,
    auditId: auditId,
    sequence: weightedJourneyScore.journey_seq
  };
};

export const getChannelJourneys = (
  channelScore: ChannelScore,
  secureJourneys: JourneyCriterion[],
  nonSecureJourneys: JourneyCriterion[]
): ChannelJourney[] => {
  let nonSecureJourneysId = channelScore.nonsecure.journeys.map((weightedJourneyScore) => {
    return transformToChannelJourney(
      weightedJourneyScore,
      nonSecureJourneys,
      false,
      channelScore.segment,
      channelScore.auditId
    );
  });

  let secureJourneysId = channelScore.secure.journeys.map((weightedJourneyScore) => {
    return transformToChannelJourney(
      weightedJourneyScore,
      secureJourneys,
      true,
      channelScore.segment,
      channelScore.auditId
    );
  });

  return sortBy<ChannelJourney>(nonSecureJourneysId.concat(secureJourneysId), ['sequence']);
};

const getAllJourneys = (channelJourneys: ChannelJourney[][]): ChannelJourney[] => {
  return uniqBy(
    channelJourneys
      .reduce((a, b) => a.concat(b))
      .map((j) => {
        return {
          ...j
        };
      }),
    'key'
  );
};

export const useJourneys = () => {
  return {
    getChannelJourneys,
    getAllJourneys
  };
};

export const useJourneyView = () => {
  const searchCriteria = useCriteria();
  const client = useApolloClient();

  const getJourney = (journey: JourneyCriterion, secure: boolean): AssetJourney => {
    return {
      key: `journey#${journey.id}#${secure ? '1' : '0'}`,
      id: journey.id,
      name: journey.name,
      secure: secure,
      assets: []
    };
  };

  const journeys: AssetJourney[] = useMemo(() => {
    const secureJourneys = searchCriteria.state.secureJourneys.map((j) => getJourney(j, true));
    const nonSecureJourneys = searchCriteria.state.nonSecureJourneys.map((j) => getJourney(j, false));

    return secureJourneys.concat(nonSecureJourneys);
  }, [searchCriteria.state.secureJourneys, searchCriteria.state.nonSecureJourneys]);

  const names = useMemo(() => journeys.map((j) => j.key), [journeys]);

  const getJourneyAssets = useCallback(
    async (auditId: number, journey: number) => {
      console.log(auditId, journey);
      try {
        const assets = await getAssets(client, { auditId, journey });
        if (!assets) {
          return [];
        }
        return assets;
      } catch (e) {
        console.log('Failed to load assets!');
      }
      return [];
    }, [client])

  return {
    getJourney,
    getJourneyAssets,
    journeys,
    names
  };
};
