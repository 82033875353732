import { Grid } from '@material-ui/core'
import { FunctionComponent } from 'react'
import { Blog } from '../../support/contentful/content-types'
import { BlogItem } from './BlogItem'

type BlogListProps = {
  items: Blog[];
};

export const BlogList: FunctionComponent<BlogListProps> = ({ items }) => {
  return (
    <Grid container spacing={4}>
      {items.map((item) => (
        <BlogItem key={item.sys.id} item={item} />
      ))}
    </Grid>
  );
};
