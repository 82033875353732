import { Box, Button, Container, Link, Paper, TextField, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import * as Sentry from '@sentry/react';
import { ChangeEvent, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { ErrorBox } from '../../components/Auth/ErrorBox';
import { ResetPasswordButton } from '../../components/Auth/PasswordResetButton';
import { ResendVerificationButton } from '../../components/Auth/ResendVerificationButton';
import { ArrowRight } from '../../components/Common/icons';
import { Page } from '../../components/Layout/Page';
import { useIdentityContext } from '../../contexts/IdentityContext';
import { resendVerificationCode } from '../../support/amplify/resend-verification-code';
import { signIn } from '../../support/amplify/sign-in';
import { AmplifyResponses } from '../../types/Amplify';
import { UNEXPECTED_ERROR_MESSAGE } from '../../helpers/messages';

export const Login = () => {
  const { setIsLoggedIn, setUser } = useIdentityContext();
  const navigate = useNavigate();
  let location = useLocation();

  const [processing, setProcessing] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<AmplifyResponses | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [input, setInput] = useState({
    username: '',
    password: ''
  });

  const websiteURL = import.meta.env.REACT_APP_WEBSITE_URL;

  const [wasPasswordRecentlyChanged] = useState<boolean>(() => {
    if (
      location.state?.wasPasswordRecentlyChanged !== undefined &&
      location.state?.wasPasswordRecentlyChanged === true
    ) {
      let locationState = location.state;
      delete locationState.wasPasswordRecentlyChanged;

      navigate('/login', locationState);

      return true;
    }

    return false;
  });

  const [wasAccountConfirmedRecently] = useState<boolean>(() => {
    if (
      location.state?.wasAccountConfirmedRecently !== undefined &&
      location.state?.wasAccountConfirmedRecently === true
    ) {
      let locationState = location.state;
      delete locationState.wasAccountConfirmedRecently;

      navigate('/login', { replace: true, state: locationState });

      return true;
    }

    return false;
  });

  const [intended] = useState<string>(() => {
    if (location.state?.intended !== undefined) {
      return ['/login', '/register', '/confirm', '/forgot-password', '/forgot-password/confirm', '/logout'].includes(
        location.state.intended
      )
        ? '/dashboard'
        : location.state.intended;
    }

    return '/dashboard';
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const form = {
      ...input,
      [event.target.name]: event.target.value
    };
    setInput(form);
  };

  const handleResendVerificationCode = async () => {
    const resendVerificationCodeResult = await resendVerificationCode(input.username);

    switch (resendVerificationCodeResult.type) {
      case 'success':
        await navigate('/confirm');
        break;
      case 'user-not-found':
        setErrorType(resendVerificationCodeResult.type);
        setError(resendVerificationCodeResult.error);
        break;
      case 'unknown-error':
        setErrorType(resendVerificationCodeResult.type);
        setError(UNEXPECTED_ERROR_MESSAGE);
        break;
    }

    setProcessing(false);
  };

  const handleResetPassword = async () => {
    await navigate('/forgot-password');
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setProcessing(true);

    if (input.username === '' || input.password === '') {
      setError('Please correct the errors below before proceeding.');
      setProcessing(false);
      return false;
    }

    const signInResult = await signIn(input.username, input.password);

    switch (signInResult.type) {
      case 'not-authorized':
      case 'user-not-confirmed':
      case 'password-reset-required':
      case 'user-not-found':
      case 'network-error':
        setErrorType(signInResult.type);
        setError(signInResult.error);
        break;
      case 'success':
        Sentry.addBreadcrumb({
          category: 'auth',
          message: 'User authenticated',
          data: {
            signInResult: signInResult
          }
        });
        setIsLoggedIn(true);
        await setUser(signInResult.user);
        await navigate(intended);
        break;
      default: {
        setError(UNEXPECTED_ERROR_MESSAGE);
      }
    }

    if (signInResult.type !== 'success') {
      setInput({
        ...input,
        password: ''
      });
    }

    setProcessing(false);
  };

  return (
    <Page>
      <Container>
        {websiteURL !== undefined && (
          <Box marginTop={4}>
            <Button
              variant="contained"
              color="default"
              style={{ backgroundColor: 'white', fontSize: '14px', borderRadius: '20px', color: '#647386' }}
              startIcon={<ArrowBack style={{ width: '20px', height: '20px', aspectRatio: '1/1' }} />}
              onClick={() => {
                if (websiteURL) {
                  window.location.replace(websiteURL);
                }
              }}
            >
              Back
            </Button>
          </Box>
        )}

        <Container maxWidth={'sm'}>
          <Box display={'flex'} flexDirection={'column'} maxWidth={'md'}>
            {wasPasswordRecentlyChanged === true && (
              <Box display={'flex'} justifyContent={'center'} marginBottom={4}>
                <Box style={{ maxWidth: 464 }}>
                  <Alert severity={'success'} color={'success'}>
                    Password Successfully Changed!
                  </Alert>
                </Box>
              </Box>
            )}

            {wasAccountConfirmedRecently === true && (
              <Box display={'flex'} justifyContent={'center'} marginBottom={4}>
                <Box style={{ maxWidth: 464 }}>
                  <Alert severity={'success'} color={'success'}>
                    Account Confirmed Successfully!
                  </Alert>
                </Box>
              </Box>
            )}

            <Box marginBottom={4}>
              <Paper style={{ maxWidth: 464, margin: '0 auto' }}>
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Box padding={4}>
                    <Typography variant={'h1'}>Log In</Typography>
                    {error && !['user-not-confirmed', 'password-reset-required'].includes(errorType ?? '') && (
                      <ErrorBox message={error} />
                    )}

                    {error && errorType === 'user-not-confirmed' && (
                      <ErrorBox>
                        {error}

                        <div>
                          <ResendVerificationButton onClick={handleResendVerificationCode} />
                        </div>
                      </ErrorBox>
                    )}

                    {error && errorType === 'password-reset-required' && (
                      <ErrorBox>
                        {error}

                        <div>
                          <ResetPasswordButton onClick={handleResetPassword} />
                        </div>
                      </ErrorBox>
                    )}

                    <Box marginY={2}>
                      <Typography
                        style={{
                          fontSize: '14px',
                          color: '#647386',
                          fontWeight: 600
                        }}
                        variant={'subtitle2'}
                      >
                        Email
                      </Typography>
                      <TextField
                        error={!!(error && input.username === '')}
                        style={{
                          marginTop: '4px'
                        }}
                        onChange={handleChange}
                        fullWidth={true}
                        size={'small'}
                        variant={'outlined'}
                        required={true}
                        type={'email'}
                        name={'username'}
                        data-cy={'username'}
                        aria-describedby="my-helper-text"
                        helperText={error && input.username === '' ? 'Please enter your username.' : false}
                        value={input.username}
                      />
                    </Box>
                    <Box marginY={2}>
                      <Typography
                        style={{
                          fontSize: '14px',
                          color: '#647386',
                          fontWeight: 600
                        }}
                        variant={'subtitle2'}
                      >
                        Password
                      </Typography>
                      <TextField
                        error={!!(error && input.password === '')}
                        style={{
                          marginTop: '4px'
                        }}
                        onChange={handleChange}
                        fullWidth={true}
                        size={'small'}
                        variant={'outlined'}
                        required={true}
                        type={'password'}
                        name={'password'}
                        data-cy={'password'}
                        aria-describedby="my-helper-text"
                        helperText={error && input.password === '' ? 'Please enter your password.' : false}
                        value={input.password}
                      />
                    </Box>
                    <Box marginY={2}>
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Button
                          type="submit"
                          color={'secondary'}
                          variant={'contained'}
                          onClick={handleSubmit}
                          disabled={processing}
                          data-cy={'submit'}
                        >
                          Log In
                        </Button>
                        <Typography
                          style={{
                            textDecoration: 'none',
                            fontSize: '14px',
                            color: '#001433'
                          }}
                          variant={'subtitle2'}
                        >
                          <Link
                            data-cy={'reset-password'}
                            component={RouterLink}
                            to="/forgot-password"
                            style={{ color: '#001433' }}
                          >
                            Forgotten password?
                          </Link>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Paper>
            </Box>
            <Box>
              <Paper style={{ maxWidth: 464, margin: '0 auto' }}>
                <Box padding={4}>
                  <Box marginY={2}>
                    <Typography variant={'h3'} style={{ marginBottom: 16 }}>
                      New User?
                    </Typography>

                    <Alert style={{ marginBottom: 16 }} severity="error" color="warning">
                      All first-time users will need to create a new user profile, irrespective of whether you have used
                      the classic Radar experience.
                    </Alert>

                    <Typography
                      style={{
                        fontSize: '14px',
                        marginTop: '4px',
                        marginBottom: '7px',
                        color: '#647386'
                      }}
                      variant={'body1'}
                    >
                      New user registration is available to employees of subscribing businesses, please be sure to use
                      your work email address when signing up.
                    </Typography>
                    <Typography
                      style={{
                        textDecoration: 'none',
                        fontSize: '16px',
                        color: '#001433'
                      }}
                      variant={'body1'}
                    >
                      <Link
                        data-cy={'register'}
                        component={RouterLink}
                        to="/register"
                        style={{
                          color: '#001433',
                          display: 'inline-flex',
                          justifyItems: 'center'
                        }}
                      >
                        <span>Create an account </span>
                        <span>
                          <ArrowRight style={{ margin: '5px 6px 2px', color: '#8B96A4' }} />
                        </span>
                      </Link>
                    </Typography>
                  </Box>
                  <Box marginY={2}>
                    <Typography variant={'h3'} style={{ marginBottom: 16 }}>
                      Not a subscriber?
                    </Typography>
                    <Typography
                      style={{
                        fontSize: '14px',
                        marginTop: '4px',
                        marginBottom: '7px',
                        color: '#647386'
                      }}
                      variant={'body1'}
                    >
                      Contact us to today to unlock a whole new world of digital intelligence.
                    </Typography>
                    {websiteURL !== undefined && (
                      <Typography
                        style={{
                          textDecoration: 'none',
                          fontSize: '16px',
                          color: '#001433'
                        }}
                        variant={'body1'}
                      >
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${websiteURL}/#contactArea`}
                          style={{
                            color: '#001433',
                            display: 'inline-flex',
                            justifyItems: 'center'
                          }}
                        >
                          <span>Contact Us </span>
                          <span>
                            <ArrowRight style={{ margin: '5px 6px 2px', color: '#8B96A4' }} />
                          </span>
                        </a>
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Container>
      </Container>
    </Page>
  );
};

export default Login;
