import { Box, Container, Grid } from '@material-ui/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlogList } from '../../components/Blog/BlogList';
import { FeaturedBlogItem } from '../../components/Blog/FeatureBlogItem';
import { Header } from '../../components/Blog/Header';
import { Pagination } from '../../components/Pagination/Pagination';
import { useQueryParams } from '../../hooks/query';
import { getBlogPosts } from '../../services/contentful/get-blog-posts';
import { Blog } from '../../support/contentful/content-types';

const loadBlogPosts = async (limit: number, currentPage: number) => {
  return getBlogPosts({
    limit,
    skip: (currentPage - 1) * limit,
    order: '-fields.originalPostDate',
    [`fields.isFeatured[ne]`]: true
  });
};

const loadFeaturedPost = async () => {
  return getBlogPosts({
    limit: 1,
    skip: 0,
    order: '-fields.originalPostDate',
    [`fields.isFeatured`]: true
  });
};

export const Index: FunctionComponent = (props) => {
  const navigate = useNavigate();
  const query = useQueryParams();
  const perPage = 24;

  const [loading, setLoading] = useState<boolean>(true);
  const [posts, setPosts] = useState<Blog[]>([]);
  const [pagination, setPagination] = useState<{ limit: number; totalHits: number; skip: number }>();
  const [featuredPost, setFeaturedPost] = useState<Blog>();

  const [currentPage, setCurrentPage] = useState<number>(() => {
    const queryPage = query.get('page');

    if (queryPage) return parseInt(queryPage);

    return 1;
  });

  useEffect(() => {
    setLoading(true);

    Promise.all([loadBlogPosts(perPage, currentPage), loadFeaturedPost()])
      .then(([posts, featured]) => {
        console.log({
          posts,
          featured
        });
        if (posts.type === 'success') {
          setPosts(posts.response.items);
          setPagination({
            limit: posts.pagination.limit,
            totalHits: posts.pagination.total,
            skip: posts.pagination.skip
          });
          console.log('Pagination meta:', posts.pagination);
        }

        if (featured.type === 'success') {
          setFeaturedPost(featured.response.items[0]);
        }
      })
      .then(() => setLoading(false));
  }, [currentPage]);

  const handlePageNavigate = (page: number) => {
    query.set('page', page.toString());

    navigate({
      pathname: '/insights',
      search: query.toString()
    });

    setCurrentPage(page);
  };

  if (loading) return null;

  return (
    <Container>
      <Header title={featuredPost ? 'Featured article' : ''} />

      <Box marginBottom={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={9}>
            {featuredPost && <FeaturedBlogItem item={featuredPost} />}
          </Grid>
          <Grid item xs={12} md={3} />
        </Grid>
      </Box>

      <Grid item xs={12} style={{ marginBottom: '3em' }}>
        <BlogList items={posts} />
      </Grid>
      <Box paddingBottom={4}>
        {pagination && <Pagination onPageNavigate={handlePageNavigate} page={currentPage} meta={pagination} />}
      </Box>
    </Container>
  );
};

export default Index;
