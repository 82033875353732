import { createContext, FunctionComponent, useContext, useReducer } from 'react';
import { ChannelTabs } from '../types/Channel';

type InitialiseExportModalState = {
  expanded: string[];
  tab: ChannelTabs;
};

type ToggleExportModalOpenState = {
  value: boolean;
};

type InitialiseExportModal = { type: 'INITIALISE_EXPORT_MODAL_STATE'; payload: InitialiseExportModalState };
type ToggleExportModalOpen = { type: 'TOGGLE_EXPORT_MODAL_OPEN_STATE'; payload: ToggleExportModalOpenState };

type Action = InitialiseExportModal | ToggleExportModalOpen;

type Dispatch = (action: Action) => void;

type State = {
  open: boolean;
  expanded: string[];
  tab: ChannelTabs;
};

type ExportContextType = { state: State; dispatch: Dispatch } | undefined;
const ExportContext = createContext<ExportContextType>(undefined);

const initModal = (state: State, action: InitialiseExportModal): State => {
  return {
    ...state,
    expanded: Array.from(action.payload.expanded),
    tab: action.payload.tab
  };
};

const toggleModal = (state: State, action: ToggleExportModalOpen): State => {
  return {
    ...state,
    open: action.payload.value
  };
};

const reducer = (state: State, action: Action) => {
  if (action.type === 'INITIALISE_EXPORT_MODAL_STATE') {
    return initModal(state, action);
  }

  if (action.type === 'TOGGLE_EXPORT_MODAL_OPEN_STATE') {
    return toggleModal(state, action);
  }

  throw new Error(`Unhandled action type in 'ExportContext'`);
};

export const ExportProvider: FunctionComponent = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    open: false,
    expanded: [],
    tab: 'channel'
  });

  return <ExportContext.Provider value={{ state, dispatch }}>{props.children}</ExportContext.Provider>;
};

export const useExport = () => {
  const context = useContext(ExportContext);

  if (context === undefined) {
    throw new Error('useExport must be used within a ExportProvider');
  }

  return context;
};
