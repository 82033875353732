import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import {
  AmplifyInvalidParameter,
  AmplifyLambdaValidationException,
  AmplifySuccess,
  AmplifyUnknownError
} from '../../types/Amplify'
import { CognitoError } from '../../types/Cognito'
import { environment } from '../environment'
import { UNEXPECTED_ERROR_MESSAGE } from '../../helpers/messages'

export type SignUpResponses = 'domain-not-authorized' | 'username-exists' | 'invalid-password' | 'invalid-brand';

export type SignUpDomainNotAuthorized = {
  type: 'domain-not-authorized';
  error: string;
};
export type SignUpUsernameExists = { type: 'username-exists'; error: string };
export type SignUpInvalidPassword = { type: 'invalid-password'; error: string };
export type SignUpInvalidBrand = { type: 'invalid-brand'; error: string };

export type SignUpResult =
  | AmplifySuccess<{ user: CognitoUser }>
  | SignUpDomainNotAuthorized
  | SignUpUsernameExists
  | SignUpInvalidPassword
  | SignUpInvalidBrand
  | AmplifyInvalidParameter
  | AmplifyLambdaValidationException
  | AmplifyUnknownError;

type ValidateCustomerSuccess = {
  type: 'success';
  response: { brand: string; domain: string; approval: boolean };
};
type ValidateCustomerValidationError = {
  type: 'validation-error';
  response: { brand: string; errors: Record<string, string> };
};
type ValidateCustomerDisabled = {
  type: 'disabled';
  brand: string;
  response: { brand: string; errors: Record<string, string> };
};
type ValidateCustomerNotFound = {
  type: 'not-found';
  brand: string;
  response: { brand: string; errors: Record<string, string> };
};

export type ValidateCustomerResult =
  | ValidateCustomerSuccess
  | ValidateCustomerValidationError
  | ValidateCustomerDisabled
  | ValidateCustomerNotFound;

type ValidateCustomerResponse = ValidateCustomerResult;

/**
 * Handles the validation of a customer, ensuring they are allowed to signup and pre-setting the group.
 *
 * @param {string} email
 *
 * @returns {Promise<{response: {attributes: {"custom:ApprovalStatus": string}}, type: "success"}>}
 */
const validateCustomer = async (email: string) => {
  const response = await fetch(`${environment.services.auth}/auth/validate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, brand: environment.branding.name })
  });

  const data: ValidateCustomerResponse = await response.json();

  if (!data) {
    throw Error();
  }

  if (data.response.brand !== environment.branding.name) {
    throw Error("invalid-brand");
  }

  switch (data.type) {
    case 'success':
      return {
        type: data.type,
        response: {
          attributes: {
            'custom:ApprovalStatus': data.response.approval ? 'pending' : 'approved'
          }
        }
      };
  }
};

/**
 * Handles the signup of a user, validates the user against the api to get the user group.
 *
 * @param {string} username
 * @param {string} password
 * @param {Record<string, string>} attributes
 *
 * @returns {Promise<SignUpResult>}
 */
export const signUp = async (
  username: string,
  password: string,
  attributes: Record<string, string>
): Promise<SignUpResult> => {
  let userAttributes = attributes;

  try {
    const customer = await validateCustomer(username);

    if (customer && customer.type === 'success') {
      userAttributes = {
        ...userAttributes,
        ...customer.response.attributes
      };
    }

    const response = await Auth.signUp({
      username,
      password,
      attributes: {
        email: username,
        ...userAttributes
      }
    });

    return {
      type: 'success',
      user: response.user
    };
  } catch (caught) {


    if ((caught as CognitoError).code === undefined || (caught as CognitoError).message === undefined) {
      return { type: 'unknown-error', exception: caught };
    }

    switch ((caught as CognitoError).code) {
      case 'UserLambdaValidationException':
        return {
          type: 'lambda-validation',
          error: UNEXPECTED_ERROR_MESSAGE
        };
      case 'UsernameExistsException':
        return {
          type: 'username-exists',
          error: "This email has already been registered"
        }

    }

    return { type: 'unknown-error', exception: caught };
  }
};
