import { ContentfulClientApi, createClient } from 'contentful';
import { getSecurityKeys } from '../../services/api/get-security-keys';
import { environment } from '../environment';
import { createApolloClient } from '../graphql/client';

let contentfulClient: ContentfulClientApi | undefined = undefined;
const gqClient = createApolloClient();
/**
 * Factory for contentful client.
 *
 * @returns {ContentfulClientApi}
 */
export const clientFactory = async () => {
  if (contentfulClient === undefined) {
    const key = await getSecurityKeys(gqClient); // TODO: Need cache this so we stop asking each time

    contentfulClient = createClient({
      space: environment.contentful.spaceId,
      accessToken: key.contentful,
      environment: environment.contentful.environment
    });
  }

  return contentfulClient;
};
