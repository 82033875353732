import { useCriteria } from '../contexts/Search/Criteria';
import { ChannelScore } from '../declarations/ChannelScore';
import { withMultipleChartData } from '../support/charts/get-multiple-chart-data';
import { withSingleChartData } from '../support/charts/get-single-chart-data';

export const useCharts = () => {
  const searchCriteria = useCriteria();

  const single = (channelScore: ChannelScore, scoreType: 'score' | 'cx_score') => {
    return withSingleChartData(
      channelScore,
      searchCriteria.state.secureJourneys,
      searchCriteria.state.nonSecureJourneys,
      scoreType
    );
  };

  const multiple = (
    channelScores: ChannelScore[],
    scoreType: 'score' | 'cx_score',
    channelColours: Record<string, string>
  ) => {
    return withMultipleChartData(
      channelScores,
      searchCriteria.state.secureJourneys,
      searchCriteria.state.nonSecureJourneys,
      scoreType,
      channelColours
    );
  };

  return {
    single,
    multiple
  };
};
