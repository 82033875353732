import { Divider, Grid, Paper } from '@material-ui/core';
import { Fragment, FunctionComponent } from 'react';
import { DemographicsButton } from '../../../../components/AdjustDemographics';
import { ChannelIcon, FunctionIcon, ListIcon } from '../../../../components/MenuIcons';
import { SortingDropdown } from '../../../../components/SearchSorting';
import { SortOption } from '../../../../components/SearchSorting/SortingDropdown';
import { MenuItem, TabChannelView, TabFunctionView, TabListView } from '../../../../types/Channel';
import { BoxIconContainer, MenuTitle } from './FilterToolbar.styled';

type FilterToolbarProps = {
  view: string;
  onOpenDemographics: () => void;
  onViewChange: (view: string) => void;
  usesVoc: boolean;
  onSortChange: (option: SortOption) => void;
  sortedOnText?: string;
  sortType?: string;
  sortSeparator?: string;
};

type FilterToolbarSortProps = {
  view: string;
  onSortChange: (option: SortOption) => void;
  sortedOnText?: string;
  sortType?: string;
  sortSeparator?: string;
};

export const FilterToolbar: FunctionComponent<FilterToolbarProps> = (props) => {
  return (
    <div>
      <Grid
        container
        direction={'row'}
        alignItems="center"
        spacing={2}
        justifyContent={'space-between'}
        wrap={'nowrap'}
        id="Filtertoolbar"
      >
        <Grid item>
          <SortingDropdown
            sortType={props.sortType}
            sortedOnText={props.sortedOnText}
            onSortChange={props.onSortChange}
          />
        </Grid>
        {props.usesVoc && (
          <Grid item>
            <DemographicsButton onOpen={props.onOpenDemographics} />
          </Grid>
        )}
        <Grid item>
          <Navigation onViewChange={props.onViewChange} view={props.view} />
        </Grid>
      </Grid>
    </div>
  );
};

export const FilterToolbarSort: FunctionComponent<FilterToolbarSortProps> = (props) => {
  return (
    <Grid container direction={'row'} alignItems={'center'}>
      <Grid item zeroMinWidth>
        <SortingDropdown
          sortType={props.sortType}
          sortedOnText={props.sortedOnText}
          onSortChange={props.onSortChange}
        />
      </Grid>
    </Grid>
  );
};

const menu: MenuItem[] = [
  { key: TabChannelView, label: 'Channel View', icon: ChannelIcon },
  { key: TabFunctionView, label: 'Function View', icon: FunctionIcon },
  // { key: TabJourneyView, label: 'Journey View', icon: GridIcon },
  { key: TabListView, label: 'List View', icon: ListIcon }
];

type NavigationProps = {
  view: string;
  onViewChange: (view: string) => void;
};

const Navigation: FunctionComponent<NavigationProps> = (props) => {
  return (
    <Paper style={{ padding: 3, display: 'flex', flexFlow: 'row nowrap' }}>
      {menu.map((item, idx) => (
        <Fragment key={item.key}>
          <BoxIcon
            title={item.label}
            selected={props.view === item.key}
            handleClick={() => props.onViewChange(item.key)}
          >
            <item.icon selected={props.view === item.key} />
          </BoxIcon>

          {idx !== menu.length - 1 && (
            <Divider orientation="vertical" flexItem style={{ marginLeft: '4px', marginRight: '4px' }} />
          )}
        </Fragment>
      ))}
    </Paper>
  );
};

interface BoxIconProps {
  children: any;
  title: string;
  selected?: boolean;
  handleClick: any;
}

const BoxIcon: FunctionComponent<BoxIconProps> = ({ children, title, selected, handleClick }) => {
  return (
    <BoxIconContainer
      paddingX={2}
      paddingY={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      bgcolor={selected ? '#647386' : ''}
      borderRadius="6px"
      onClick={handleClick}
    >
      {children}
      <MenuTitle className={selected ? 'selected' : ''}>{title}</MenuTitle>
    </BoxIconContainer>
  );
};
