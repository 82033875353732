import { createContext, PropsWithChildren, useContext, useReducer } from 'react'
import { ChannelTabs, TabChannelView } from "../types/Channel";
import { ChannelScore } from '../declarations/ChannelScore';

type InitialiseChannelModalPayload = {
    channelScore: ChannelScore,
    view: ChannelTabs,
    expanded?: string[]
}

type ToggleChannelModalPayload = {
    value: boolean
}

type InitialiseChannelModal = { type: 'INITIALISE_CHANNEL_MODAL_STATE', payload: InitialiseChannelModalPayload }
type ToggleChannelModal = { type: 'TOGGLE_CHANNEL_MODAL_STATE', payload: ToggleChannelModalPayload }

type Action = InitialiseChannelModal | ToggleChannelModal

type Dispatch = (action: Action) => void

type State = {
    channelScore: ChannelScore | null,
    open: boolean,
    default: {
        view: ChannelTabs
        expanded?: string[]
    }
}

type ChannelContextType = { state: State; dispatch: Dispatch } | undefined
const ChannelContext = createContext<ChannelContextType>(undefined)

const initialiseChannelModal = (state: State, action: InitialiseChannelModal): State => {
    return {
        ...state,
        channelScore: action.payload.channelScore,
        default: {
            expanded: action.payload.expanded,
            view: action.payload.view
        }
    }
}

const toggleChannelModal = (state: State, action: ToggleChannelModal): State => {
    return {
        ...state,
        open: action.payload.value
    }
}

const reducer = (state: State, action: Action) => {
    if (action.type === 'INITIALISE_CHANNEL_MODAL_STATE') {
        return initialiseChannelModal(state, action)
    } else if (action.type === 'TOGGLE_CHANNEL_MODAL_STATE') {
        return toggleChannelModal(state, action)
    }

    throw new Error(`Unhandled action type in 'ChannelContext'`)
}

const initialState = {
    channelScore: null,
    open: false,
    default: {
        view: TabChannelView as ChannelTabs
    }
}

type ChannelProviderProps = {}

export const ChannelProvider = (props: PropsWithChildren<ChannelProviderProps>) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <ChannelContext.Provider value={{ state, dispatch }}>
            {props.children}
        </ChannelContext.Provider>
    )
}

export const useChannel = () => {
    const context = useContext(ChannelContext)

    if (context === undefined) {
        throw new Error('useChannel must be used within a ChannelProvider')
    }

    return context
}
