import { FunctionComponent, useEffect, useState } from 'react'
import { BlogList } from '../../components/Blog/BlogList'
import { getBlogPosts } from '../../services/contentful/get-blog-posts'
import { Blog } from '../../support/contentful/content-types'

const loadBlogPosts = async () => {
  return getBlogPosts({
    order: '-fields.originalPostDate',
    limit: 3
  });
};

const DashboardBlogList: FunctionComponent = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [posts, setPosts] = useState<Blog[]>([]);

  useEffect(() => {
    loadBlogPosts()
      .then((request) => {
        if (request.type === 'success') {
          setPosts(request.response.items);
        }
      })
      .then(() => setLoading(false));
  }, []);

  if (loading) return null;

  return <BlogList items={posts} />;
};

export default DashboardBlogList;
