import { JourneyList } from "../../JourneyView/JourneyList";
import { ChannelScore } from '../../../declarations/ChannelScore';

type JourneysProps = {
    channelScore: ChannelScore
}

export const Journeys = (props: JourneysProps) => {
    return (
        <>
            <JourneyList channelScore={props.channelScore} />
        </>
    )
}