import { ApolloClient } from '@apollo/client';
import { Industry, Industries } from '../../types/Industries';
import { Criteria } from '../../contexts/Search/Criteria';
import { getAudienceCriteria } from '../../services/api/get-audience-criteria';
import { getQuestions } from '../../services/api/get-questions';
import { getSearchCriteria } from '../../services/api/get-search-criteria';
import { getSectors } from '../../services/api/get-sectors';
import { Sectors, TelecomsSector } from '../../types/Sectors';
import { Segments } from '../../types/Segments';
import { Criterion, JourneyCriterion } from '../../declarations/Criterion';
import { Query } from '../../contexts/Search/Query';

/**
 * Handles sorting the journeys by their order sequence.
 *
 * @param {JourneyCriterion[]} journeys
 *
 * @returns {JourneyCriterion[]}
 */
export const withSortedJourneys = (journeys: JourneyCriterion[]): JourneyCriterion[] => {
  return journeys.sort((a, b) => a.journey_seq - b.journey_seq).map((v) => v);
};

/**
 * Handles grouping the journeys by their secure flag.
 *
 * @param {JourneyCriterion[]} journeys
 *
 * @returns {{ id: number; secure: boolean; sequence: number }[]}
 */
export const withGroupedJourneys = (
  journeys: JourneyCriterion[]
): { id: number; secure: boolean; sequence: number }[] => {
  const grouped: Array<{ id: number; secure: boolean; sequence: number }> = [];

  journeys.forEach((criterion) => {
    const journey = criterion;
    grouped.push({
      id: journey.id,
      secure: true,
      sequence: journey.journey_seq
    });
  });

  return grouped;
};

/**
 * Handles transforming the audience criteria into a searchable query input.
 *
 * @param {Record<string, { id: number; name: string; segment: Segments; __typename: string }[]>} criteria
 *
 * @returns {Record<string, number[]>}
 */
const withAudience = (
  criteria: Record<string, { id: number; name: string; segment: Segments; __typename: string }[]>
) => {
  const audience: Record<string, number[]> = {};

  Object.entries(criteria)
    .filter((v) => {
      const [name] = v;
      return name !== '__typename';
    })
    .forEach((v, z) => {
      const [name, criterions] = v;
      audience[name] = criterions.map((v, x) => v.id);
    });

  return audience;
};

/**
 * Handles setting the sector for the search, uses the query string value or defaults to the first enabled by default.
 *
 * @param {string | null} querySector
 * @param {Criterion[]} sectors
 *
 * @returns {number}
 */
export const withCurrentSector = (querySector: string | null, sectors: Criterion[]) => {
  return querySector === null
    ? sectors[0].id
    : Object.values(sectors).find((s) => s.id === parseInt(querySector))
      ? parseInt(querySector)
      : sectors[0].id;
};

/**
 * Handles the loading the search criteria and transforming the response data into usuable data.
 *
 * @param {ApolloClient<object>} client
 * @param {number} sector
 */
export const loadSearchCriteria = async (client: ApolloClient<object>, sector: Sectors): Promise<Criteria> => {
  const as_at_date = new Date().toISOString().split('T')[0];
  return Promise.all([getSearchCriteria(client, { sector, as_at_date }), getAudienceCriteria(client, { sector })])
    .then((res) => {
      return { currentSector: sector, criteria: res[0], audience: res[1] };
    })
    .then(({ currentSector, criteria, audience }) => {
      const state: Record<string, number[]> = withAudience(audience!);

      const formatted = Object.entries(state).reduce(
        (result, [k, v]) => (v.length ? { ...result, [k]: v } : result),
        {}
      );

      return getQuestions(client, { sector: currentSector, audience: formatted }).then((data) => {
        return { currentSector, criteria, audience, questions: data };
      });
    })
    .then(({ currentSector, criteria, audience, questions }) => {
      const { markets, providers, segments, journeys, channels } = criteria!;

      const groupedJourneys = withGroupedJourneys(journeys);
      const secureJourneys = withSortedJourneys(journeys);

      return {
        markets,
        providers,
        segments,
        journeys: groupedJourneys,
        secureJourneys,
        nonSecureJourneys: [],
        channels,
        audience,
        questions,
        cycles: []
      };
    });
};

/**
 * Loads sectors that the user has access to and returns the industries with allowed sectors.
 *
 * @param {ApolloClient<object>} client - The Apollo client used to fetch data.
 *
 * @returns {Promise<Industry[]>} - A promise that resolves to an array of industries with allowed sectors.
 */
export const loadAuthorisedIndustries = async (client: ApolloClient<object>): Promise<Industry[]> => {
  //TODO: Cleanup this mess!
  let industriesWithAllowedSectors: Industry[] = [];
  const { sectors } = await getSectors(client);
  const allowedSectors = sectors.filter((sector: Criterion) => ![TelecomsSector].includes(sector.id));
  // this shouldn't really be a map because it's just executing a side-effect
  // and not returning anything
  Industries.map(industry => {
    const filteredCycles = industry.cycles.filter((cycle) => {
      return allowedSectors.some((sector: Criterion) => sector.id === cycle.linkedSector);
    });
    industriesWithAllowedSectors.push({
      ...industry,
      cycles: filteredCycles
    });
    return undefined
  });
  const sortedWithoutEmptyIndustry = industriesWithAllowedSectors
    .filter(x => x.cycles.length > 0)
    .sort((a, b) => (a.industryId > b.industryId ? 1 : -1));
  return sortedWithoutEmptyIndustry;
};
/**
 * Handles the creation of the initial search query, either by using the values on the url or the defaults from the loaded criteria.
 *
 * @param {number} sector
 * @param {Criteria} criteria
 * @param {URLSearchParams} queryParams
 */
export const loadSearchQuery = (sector: Sectors, criteria: Criteria, usesVoc: boolean): Query => {
  const sort = usesVoc
    ? { label: 'VoC Score (high-low)', field: 'CX_SCORE', direction: 'DESC' }
    : { label: 'Utility Score (high-low)', field: 'SCORE', direction: 'DESC' }

  const audience: Record<string, number[]> = withAudience(criteria.audience);


  let query: Query = {
    markets: [],
    providers: [],
    segments: [],
    channels: [],
    audience,
    questions: {
      type: 'OR',
      questionIds: []
    },
    sort,
    pagination: {
      page: 1,
      offset: 0,
      limit: 12
    }
  };

  Array.from(['markets', 'providers', 'segments', 'channels']).forEach((key) => {
    const facet = key as keyof typeof criteria;
    // @ts-ignore
    query[facet] = criteria[facet].map((criterion) => criterion.id);
  });
  return query;
};
