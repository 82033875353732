import { Box, Divider, Typography, styled } from '@material-ui/core';

export type SubtitleProps = {
  text: string;
};

const SpacedDivider = styled(Divider)({
  marginTop: '16px !important',
  marginBottom: '12px !important',
  backgroundColor: '#D9DBE0 !important'
});

const Subtitle = (props: SubtitleProps) => {
  return (
    <Box>
      <Typography style={{ fontWeight: 'bold' }} variant="subtitle2">
        {props.text}
      </Typography>
      
      <SpacedDivider />
    </Box>
  );
};

export default Subtitle;
