import { getTagCollection } from '../../support/contentful/content/get-tag-collection';

/**
 * Gets the tags from contentful.
 *
 * @param {Record<string, string | number | boolean>} query
 *
 * @returns {Promise<ContentfulTagCollectionResult>}
 */
export const getTags = async (query?: Record<string, string | number | boolean>) => {
  return await getTagCollection({
    ...query
  });
};
