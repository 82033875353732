import { Typography } from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/Launch'
import { FunctionComponent } from 'react'
import { useExport } from '../../contexts/ExportContext'
import colors from '../../support/theme/colors'
import { ChannelTabs } from '../../types/Channel'

type ExportTriggerProps = {
  tab: ChannelTabs;
  selected: string[];
};

export const ExportTrigger: FunctionComponent<ExportTriggerProps> = (props) => {
  const exportComparison = useExport();

  const onClickHandler = (expanded: string[], tab: ChannelTabs) => {
    exportComparison.dispatch({ type: 'INITIALISE_EXPORT_MODAL_STATE', payload: { expanded, tab } });
    exportComparison.dispatch({ type: 'TOGGLE_EXPORT_MODAL_OPEN_STATE', payload: { value: true } });
  };

  return (
    <Typography
      onClick={() => onClickHandler(props.selected, props.tab)}
      style={{
        cursor: 'pointer',
        marginRight: '15px',
        color: colors.main,
        display: 'inline-flex',
        justifyItems: 'center'
      }}
      variant={'h4'}
    >
      <span>
        <LaunchIcon style={{ color: '#8B96A4', marginRight: '4px', width: '18px', height: '18px' }} />
      </span>
      <span>Export</span>
    </Typography>
  );
};