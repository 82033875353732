import { Box, Button, Container } from "@material-ui/core"
import { Done } from "@material-ui/icons"
import { times, uniqBy } from "lodash"
import { FunctionComponent } from "react"
import { getBrands } from '../../../../brands'
import { BodyTextWhiteBold } from "../../../../components/Typography"
import { useChannel } from "../../../../contexts/ChannelContext"
import { useCompareChannel } from "../../../../contexts/CompareChannelContext"
import { useComparison } from "../../../../contexts/ComparisonContext"
import { environment } from '../../../../support/environment'
import colors from "../../../../support/theme/colors"
import { ChannelTabs, TabJourneyView } from "../../../../types/Channel"
import { CheckBoxIndicator } from "./ComparisonToolbar.styled"

type ComparisonToolbarProps = {
    view: ChannelTabs,
}

export const ComparisonToolbar: FunctionComponent<ComparisonToolbarProps> = (props) => {
    const comparison = useComparison()
    const compareChannel = useCompareChannel()
    const channel = useChannel()
    const config = getBrands(environment.branding.name || '');

    const compare = () => {

        const items = comparison.state[props.view]

        if (props.view === TabJourneyView) {

            const expanded = uniqBy(items.map((i) => {
                return {key: `journey#${i.stack?.journey}#${i.stack?.secure ? '1' : '0'}`}
            }), 'key')

            const isSingle = uniqBy(items.map((i) => {
                return { key: i.value.key }
            }), 'key').length === 1

            if (isSingle) {
                channel.dispatch({
                    type: "INITIALISE_CHANNEL_MODAL_STATE",
                    payload: {
                        channelScore: items[0].value,
                        view: TabJourneyView,
                        expanded: expanded.map((e) => e.key)
                    }
                })

                channel.dispatch({
                    type: "TOGGLE_CHANNEL_MODAL_STATE",
                    payload: {value: true}
                })
            } else {

                const scores = uniqBy(items.map((c) => c.value), 'key')
                let selected: Record<string, string> = {}

                for (const i of items) {
                    if (i.stack) {
                        if( selected[i.value.key] === undefined ) {
                            selected[i.value.key] = `journey#${i.stack?.journey}#${i.stack?.secure ? '1' : '0'}`
                        }
                    }
                }

                compareChannel.dispatch({
                    type: "INITIALISE_COMPARE_CHANNEL_MODAL_STATE",
                    payload: {
                        channelScores: scores,
                        expanded: expanded.map((e) => e.key),
                        selected,
                        view: props.view,
                    }
                })

                compareChannel.dispatch({
                    type: "TOGGLE_COMPARE_CHANNEL_MODAL_STATE",
                    payload: { value: true }
                })
            }
        } else {
            compareChannel.dispatch({
                type: "INITIALISE_COMPARE_CHANNEL_MODAL_STATE",
                payload: {
                    channelScores: comparison.state[props.view].map((c) => c.value),
                    view: props.view,
                }
            })

            compareChannel.dispatch({
                type: "TOGGLE_COMPARE_CHANNEL_MODAL_STATE",
                payload: { value: true }
            })
        }
    };

    const clear = () => {
        comparison.dispatch({
            type: "CLEAR_COMPARISON_ITEMS_IN_STATE",
            payload: {view: props.view}
        })
    }

    const count = comparison.state[props.view].length

    return (
        <Box position="sticky" width="100%" left={0} bottom={0} zIndex={5}>
            <Container>
                <Box
                    borderRadius={"10px 10px 0px 0px"}
                    width="1"
                    display="flex"
                    justifyContent="space-between"
                    bgcolor={colors.main}
                >
                    <Box padding={2} width="33%" display="flex" alignItems="center">
                        {times(10, (i) => {
                            return count <= i ? (
                                <CheckBoxIndicator key={i} borderColor="white" data-cy={`cbi-${i}`}/>
                            ) : (
                                <CheckBoxIndicator
                                    key={i}
                                    bgcolor="#24AB3B"
                                    borderColor="#24AB3B"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    data-cy={`cbi-${i}`}
                                >
                                    <Done style={{color: "white", fontSize: "14px"}} data-cy={`done-${i}`} />
                                </CheckBoxIndicator>
                            );
                        })}
                    </Box>
                    <Box
                        padding={2}
                        width="33%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <BodyTextWhiteBold
                            align="center">
                            Select up to ten results
                        </BodyTextWhiteBold>
                    </Box>
                    <Box
                        padding={2}
                        width="33%"
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <Box marginX={2}>
                            <Button
                                disabled={count < 1}
                                color="inherit"
                                variant="text"
                                disableElevation
                                style={{
                                    color: "white",
                                    borderRadius: "6px",
                                    textTransform: "capitalize",
                                    fontWeight: "bold"
                                }}
                                onClick={() => clear()}
                            >Clear</Button>
                        </Box>
                        <Box marginX={2}>
                            <Button
                                disabled={count <= 1}
                                color="secondary"
                                variant="contained"
                                disableElevation
                                style={{
                                    backgroundColor: config.colors.secondary,
                                    borderRadius: "6px",
                                    textTransform: "capitalize",
                                    fontWeight: "bold"
                                }}
                                onClick={() => compare()}
                            >Compare</Button>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default ComparisonToolbar