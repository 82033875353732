
import { FC, ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useIdentityContext } from "../../contexts/IdentityContext"

type Props = {
    children?: ReactNode;
};

export const PrivateRoute: FC<Props> = ({ children }) => {
    const location = useLocation()
    const { isLoggedIn } = useIdentityContext()

    if (!isLoggedIn) {
        <Navigate to="/login" state={{ intended: location.pathname + location.search }} />
    }
    return (
        <>
            {children}
        </>

    )
}
