import { useApolloClient } from "@apollo/client"
import { Box, Button, Container, Paper, Typography } from "@material-ui/core"
import { FunctionComponent, MouseEvent, useEffect, useRef } from "react"
import { useParams } from "react-router-dom"
import { Page } from "../../components/Layout/Page"
import { useResources } from "../../hooks/resources"

export const Download: FunctionComponent = () => {
  const apolloClient = useApolloClient();
  // @ts-ignore
  const { resourceId } = useParams();
  const { downloadResource } = useResources();
  const downloadButton = useRef<HTMLButtonElement | undefined>();

  useEffect(() => {
    setTimeout(() => {
      if (downloadButton && downloadButton.current) {
        downloadButton.current.click();
      }
    }, 2000);
  }, [apolloClient, downloadResource, resourceId]);

  const onDownloadHandler = async (event: MouseEvent) => {
    event.preventDefault();
    downloadResource(apolloClient, Number(resourceId));
  };

  return (
    <Page>
      <Container>
        <Box display={"flex"} justifyContent={"center"} paddingY={4}>
          <Paper style={{ width: "700px", height: "100%" }}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              paddingY={8}
              paddingX={4}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography style={{ marginBottom: "12px" }} variant={"h1"}>
                Downloading File
              </Typography>

              <Box marginX={4}>
                <Typography
                  variant={"body1"}
                  style={{ marginTop: "10px", fontSize: "16px" }}
                >
                  Your file should start downloading automatically, if it does
                  not start shortly,{" "}
                  <Button
                    buttonRef={downloadButton}
                    variant={"text"}
                    disableElevation
                    disableRipple
                    style={{
                      textDecoration: "none",
                      fontSize: "16px",
                      color: "#001433",
                      cursor: "pointer",
                    }}
                    onClick={onDownloadHandler}
                  >
                    click this link
                  </Button>{" "}
                  to start the download
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Container>
    </Page>
  );
};
