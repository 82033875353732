import { Box } from "@material-ui/core"
import React, { CSSProperties } from 'react'
import { Business2021Segment, Corporate2021Segment, LifeInsurance2021Segment, LifeInsurance2023Segment, Personal2021Segment, Personal2023Segment, Segments, TelecomsSegment, MerchantServices2023Segment, Business2023Segment, Corporate2023Segment } from "../../types/Segments"
import { PrioritisationOverlayTrigger } from "../PrioritisationOverlay/PrioritisationOverlayTrigger"
import { BusinessIcon, CorporateIcon, LifeInsuranceIcon, MerchantServicesIcon, PersonalIcon } from "./icons"

const icons = (segment: Segments): JSX.Element | null => {
    switch (segment) {
        case Corporate2021Segment:
        case Business2021Segment:
        case Corporate2023Segment:
        case Business2023Segment:
            return (
                <>
                    <BusinessIcon styles={{ marginRight: '2px' }} />
                    <CorporateIcon styles={{ marginRight: '5px' }} />
                </>
            )
        case Personal2021Segment:
        case Personal2023Segment:
            return <PersonalIcon styles={{ marginRight: '5px' }} />
        case LifeInsurance2021Segment:
        case LifeInsurance2023Segment:
            return <LifeInsuranceIcon styles={{ marginRight: '5px' }} />
        case MerchantServices2023Segment:
            return <MerchantServicesIcon styles={{ marginRight: '5px' }} />
    }

    return null
}

const styles = (segment: Segments): React.CSSProperties => {
    switch (segment) {
        case Business2021Segment:
        case Corporate2021Segment:
        case Business2023Segment:
        case Corporate2023Segment:
            return {
                backgroundColor: "#F7E7EA",
                color: "#B8102D"
            }
        case Personal2021Segment:
        case Personal2023Segment:
            return {
                backgroundColor: "#FEEFE5",
                color: "#BB4C00"
            }
        case LifeInsurance2021Segment:
        case LifeInsurance2023Segment:
            return {
                backgroundColor: "#E5FBF7",
                color: "#018067"
            }
        case TelecomsSegment:
            return {
                backgroundColor: "#F9FAD8",
                color: "#747600"
            }
        case MerchantServices2023Segment:
            return {
                backgroundColor: "#F9FAD8",
                color: "#747600"
            }
    }

    return {
        backgroundColor: "#F7E7EA",
        color: "#B8102D"
    }
}

type WeightBadgeProps = {
    weight: number,
    segment: Segments
    question?: number,
    text?: string,
    withIcon?: boolean,
    secure?: boolean,
    style?: CSSProperties,
}

export const WeightBadge = (props: WeightBadgeProps) => {
    const { withIcon = false, secure = false } = props

    const style = styles(props.segment)
    const icon = icons(props.segment)

    return (
        <Box
            marginX={0.2}
            maxWidth={"60px"}
            paddingX={1}
            fontSize="10px"
            lineHeight={"18px"}
            display="inline-flex"
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius="20px"
            height={"18px"}
            style={{ ...props.style, ...style, whiteSpace: "nowrap" }}
        >
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"100%"}>
                {withIcon && (
                    icon
                )}

                {(props.question && props.text) && (
                    <PrioritisationOverlayTrigger
                        text={props.text}
                        question={props.question}
                        segment={props.segment}
                        weight={props.weight}
                        secure={secure}
                    />
                )}

                {props.weight.toFixed(1)}&nbsp;%
            </Box>
        </Box>
    )
}
