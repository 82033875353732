import { useApolloClient } from '@apollo/client';
import { Box, Button, Container, Paper } from '@material-ui/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { getBrands } from '../../../src/brands';
import { useComparison } from '../../contexts/ComparisonContext';
import { Industry, Cycle } from '../../types/Industries';
import { useCriteria } from '../../contexts/Search/Criteria';
import { useSearchQuery } from '../../contexts/Search/Query';
import { useSearchResults } from '../../contexts/Search/Results';
import { environment } from '../../support/environment';
import { loadAuthorisedIndustries, loadSearchCriteria, loadSearchQuery } from '../../support/search/search';
import { CycleFilter } from './CycleFilter';
import { ChannelFilter } from './ChannelFilter';
import { DropdownContainer } from './Filter/Dropdown';
import FullpageLoading from './FullpageLoading';
import { MarketFilter } from './MarketFilter';
import { ProviderFilter } from './ProviderFilter';
import { IndustryFilter } from './IndustryFilter';
import { SegmentFilter } from './SegmentFilter';
import { useLocation } from 'react-router-dom';
import { Segments } from '../../types/Segments';
import { AdvancedJourneyTrigger } from '../AdvancedJourney/AdvancedJourneyTrigger';

type SearchWidgetProps = {
  onTriggerSearch: () => void;
  hideAdvanced?: boolean;
};

const loadingTips: Array<string> = [
  "You can change the VOC customer profile 'VoC Setup'",
  "Set your browser to 'full screen' to see more",
  'Click the expand icon on any Channel View result to deep dive',
  'Need help? Contact SwiftAssist@iSkyResearch.com',
  'Click Insights to read about the recent changes in each provider'
];

const findIndustry = (industries: Industry[], queryParams: URLSearchParams) => {
  const querySector = queryParams.get('sector')
  if (querySector) {
    return industries.find(industry => industry.cycles.find(cycle => cycle.linkedSector.toString() === querySector))!
  } else {
    return industries[0]
  }
}

const Widget: FunctionComponent<SearchWidgetProps> = (props) => {
  const searchQuery = useSearchQuery();
  const searchResults = useSearchResults();
  const searchCriteria = useCriteria();
  const apolloClient = useApolloClient();
  const comparison = useComparison();
  const config = getBrands(environment.branding.name || '');

  const usesVoc = searchQuery.state.selectedIndustry.usesVoc
  const { hideAdvanced = false } = props;

  const [progress, setProgress] = useState(0);
  const onTriggerSearchCallback = props.onTriggerSearch

  // the linter isn't keen on having searchQuery.dispatch as a useEffect dependency
  // but it is happy if we've assigned that to a variable (probably something to do with 'this')
  const searchQueryDispatch = searchQuery.dispatch
  const searchCriteriaDispatch = searchCriteria.dispatch
  const location = useLocation()

  useEffect(() => {
    // initial load
    const fetchData = async () => {
      const queryParams = new URLSearchParams(location.search)
      const industries = await loadAuthorisedIndustries(apolloClient)
      const selectedIndustry = findIndustry(industries, queryParams)
      const selectedCycle = selectedIndustry.cycles[0]
      const criteria = await loadSearchCriteria(apolloClient, selectedCycle.linkedSector)
      searchCriteriaDispatch({ type: 'SET_INITIAL_CRITERIA_STATE', payload: { value: criteria } });
      searchCriteriaDispatch({ type: 'SET_INDUSTRY_CRITERIA_STATE', payload: { value: industries } });
      const query = loadSearchQuery(selectedCycle.linkedSector, criteria, selectedIndustry.usesVoc);
      searchQueryDispatch({ type: 'SET_INITIAL_QUERY_STATE', payload: { value: query } });
      const selectedProvider = queryParams.get("provider")
      if (selectedProvider) {
        searchQueryDispatch({ type: 'SET_PROVIDER_QUERY_STATE', payload: { value: [Number(selectedProvider)] } });
      }
      searchQueryDispatch({ type: 'SET_SELECTED_INDUSTRY', payload: { value: selectedIndustry } });
      searchQueryDispatch({ type: 'SET_SELECTED_CYCLE', payload: { value: selectedCycle } });
      const selectedSegment = queryParams.get("segment")
      if (selectedSegment) {
        searchQueryDispatch({ type: 'SET_SEGMENT_QUERY_STATE', payload: { value: [Number(selectedSegment) as Segments] } });
      }
      searchCriteriaDispatch({ type: 'SET_LOADING_CRITERIA_STATE', payload: { value: false } });
      onTriggerSearchCallback()
    }
    fetchData()

  }, [apolloClient, location.search, onTriggerSearchCallback, searchCriteriaDispatch, searchQueryDispatch]);

  useEffect(() => {
    if (searchCriteria.state.loading) {
      setProgress(x => x + 22);
    }
    if (searchResults.state.loading) {
      setProgress(x => x + 22);
    }
    return () => {
      setProgress(0);
    };
  }, [searchCriteria.state.loading, searchResults.state.loading]);

  /**
   * Triggers when the user clicks the search button
   * @returns void
   * @async
   * @function
   * @name handleSearch
   * @param {void}
   */
  const handleSearch = async () => {
    setProgress(5);
    onTriggerSearchCallback()
  };

  const handleReset = async () => {
    const query = loadSearchQuery(searchQuery.state.selectedCycle.linkedSector, searchCriteria.state, usesVoc);
    searchQuery.dispatch({ type: 'SET_INITIAL_QUERY_STATE', payload: { value: query } });
    onTriggerSearchCallback()
  };

  const onCycleChangeHandler = async (selectedCycle: Cycle) => {
    // console.log('selectedCycle', selectedCycle);
    setProgress(5);
    if (selectedCycle.linkedSector !== searchQuery.state.selectedCycle.linkedSector) {
      const criteria = await loadSearchCriteria(apolloClient, selectedCycle.linkedSector)
      searchCriteria.dispatch({ type: 'SET_INITIAL_CRITERIA_STATE', payload: { value: criteria } });
      const query = loadSearchQuery(selectedCycle.linkedSector, criteria, usesVoc);
      searchQuery.dispatch({ type: 'SET_INITIAL_QUERY_STATE', payload: { value: query } });
    }
    comparison.dispatch({ type: 'CLEAR_COMPARISON_IN_ALL_VIEWS' });
    onTriggerSearchCallback()
  };

  /**
   * Will trigger when the user selects a industry.
   * Industry will be changed and date will be set to default cycle end date
   *
   * @param selectedIndustry User selected industry
   */
  const onIndustryChangeHandler = async (selectedIndustry: Industry) => {
    setProgress(5);
    const selectedCycle = selectedIndustry.cycles[0]
    let criteria = await loadSearchCriteria(apolloClient, selectedCycle.linkedSector)
    searchCriteria.dispatch({ type: 'SET_INITIAL_CRITERIA_STATE', payload: { value: criteria } });
    const query = loadSearchQuery(selectedCycle.linkedSector, criteria, selectedIndustry.usesVoc);
    searchQuery.dispatch({ type: 'SET_INITIAL_QUERY_STATE', payload: { value: query } });
    comparison.dispatch({ type: 'CLEAR_COMPARISON_IN_ALL_VIEWS' });
    onTriggerSearchCallback()
  };

  return (
    <>
      <FullpageLoading
        open={progress !== 0}
        progress={progress}
        text={loadingTips[Math.floor(Math.random() * loadingTips.length)]}
        onCanceled={() => setProgress(0)}
      />

      <Paper square={true}>
        <Container maxWidth={false}>
          <Box display="flex" justifyContent="space-between" paddingY={1} zIndex={1}>
            <DropdownContainer>
              <IndustryFilter onIndustryChange={onIndustryChangeHandler} />
            </DropdownContainer>
            <DropdownContainer>
              <CycleFilter onCycleChange={onCycleChangeHandler} />
            </DropdownContainer>
            <DropdownContainer>
              <MarketFilter />
            </DropdownContainer>
            {!hideAdvanced && (
              <DropdownContainer>
                <ProviderFilter />
              </DropdownContainer>
            )}
            <DropdownContainer>
              <SegmentFilter />
            </DropdownContainer>
            <AdvancedJourneyTrigger />
            {!hideAdvanced && (
              <DropdownContainer>
                <ChannelFilter />
              </DropdownContainer>
            )}
            <Box display={'flex'} justifyItems={'center'} padding={1}>
              <Button
                disableElevation
                disabled={false}
                color={'default'}
                variant="outlined"
                style={{
                  backgroundColor: 'white',
                  color: config.colors.secondary,
                  borderRadius: '6px',
                  // textTransform: "capitalize",
                  fontWeight: 'bold'
                }}
                onClick={() => handleSearch()}
              >
                Update
              </Button>

              <Button
                disableElevation
                disabled={false}
                color={'default'}
                variant="outlined"
                style={{
                  marginLeft: '2px',
                  backgroundColor: 'white',
                  color: '#647386',
                  border: 'none',
                  // textTransform: "capitalize",
                  fontWeight: 'bold'
                }}
                onClick={() => handleReset()}
              >
                Reset
              </Button>
            </Box>
          </Box>
        </Container>
      </Paper>
    </>
  );
};

export default Widget;
