import { useEffect, useMemo, useState } from 'react';
import { Modal } from '../../../../components/Modal/Modal';
import { ChannelTabs, TabChannelView, TabFunctionView, TabJourneyView } from '../../../../types/Channel';
import { Tab } from '../../../../components/Comparison/Tabs';
import { useCompareChannel } from '../../../../contexts/CompareChannelContext';
import { ExportProvider } from '../../../../contexts/ExportContext';
import { ExportModal } from '../../../../components/Export/ExportModal';
import { useSearchQuery } from '../../../../contexts/Search/Query';

export const MultipleModal = () => {
  const compareChannel = useCompareChannel();
  const searchQuery = useSearchQuery();
  const channelScores = useMemo(() => compareChannel.state.channelScores, [compareChannel.state.channelScores]);

  const [selectedView, setSelectedView] = useState<ChannelTabs>(compareChannel.state.default.view);

  useEffect(() => {
    if (selectedView !== compareChannel.state.default.view) {
      setSelectedView(compareChannel.state.default.view);
    }
    // if you add the dependency "selectedView", the dialog always flips back
    // to default view whenever you change the view. Deleting the useEffect
    // seems to work OK so it's not really clear what the intention was
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareChannel.state.default, compareChannel.state.default.view]);

  const toolbarActions: ToolbarAction[] = [
    {
      key: TabChannelView,
      selected: selectedView === TabChannelView,
      title: 'Channels',
      onClick: () => setSelectedView(TabChannelView)
    },
    {
      key: TabFunctionView,
      selected: selectedView === TabFunctionView,
      title: 'Functions',
      onClick: () => setSelectedView(TabFunctionView)
    },
    {
      key: TabJourneyView,
      selected: selectedView === TabJourneyView,
      title: 'Journeys',
      onClick: () => setSelectedView(TabJourneyView)
    }
  ];

  const onClose = () => {
    compareChannel.dispatch({
      type: 'TOGGLE_COMPARE_CHANNEL_MODAL_STATE',
      payload: { value: false }
    });
  };

  if (!channelScores) return null;
  const usesVoc = searchQuery.state.selectedIndustry.usesVoc

  return (
    <ExportProvider>
      <Modal
        open={compareChannel.state.open}
        onClose={onClose}
        closeable={true}
        title={'Comparison'}
        actions={toolbarActions}
        paddingY={1}
        paddingX={4}
      >
        <ExportModal usesVoc={usesVoc} />

        {selectedView === TabChannelView && <Tab.Channels channelScores={channelScores} usesVoc={usesVoc} />}

        {selectedView === TabFunctionView && <Tab.Functions channelScores={channelScores} sortedOnText={searchQuery.state.sort.friction_question_text} usesVoc={usesVoc} />}

        {selectedView === TabJourneyView && <Tab.Journeys channelScores={channelScores} usesVoc={usesVoc} />}
      </Modal>
    </ExportProvider>
  );
};
