import { Box } from '@material-ui/core'
import { FunctionComponent, ReactNode } from 'react'
import colors from '../../support/theme/colors'
import { ButtonContainer, Close, ModalTitle, ViewButton } from './Header.styled'

export interface ModalToolbarProps {
  title?: string;
  onClose: () => void;
  toolbarActions?: ToolbarAction[];
  exportable?: ReactNode;
}

const Header: FunctionComponent<ModalToolbarProps> = ({ title, onClose, exportable = false, toolbarActions = [] }) => {
  return (
    <Box
      minHeight={'64px'}
      boxSizing="border-box"
      bgcolor={colors.main}
      display="flex"
      width={'100%'}
      justifyContent={'space-between'}
      alignItems="center"
      paddingY={1}
      paddingX={4}
    >
      <Box display="flex">
        <ModalTitle>{title}</ModalTitle>
      </Box>
      {toolbarActions.length > 0 && (
        <ButtonContainer>
          {toolbarActions.map((action) => (
            <ViewButton
              key={action.key}
              type="button"
              variant="contained"
              onClick={() => action.onClick()}
              className={action.selected ? 'selected' : ''}
            >
              {action.title}
            </ViewButton>
          ))}
        </ButtonContainer>
      )}
      <Box display="flex">
        {exportable && exportable}
        <Close onClick={() => onClose()} />
      </Box>
    </Box>
  );
};

export default Header;
