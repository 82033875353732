import { Box, Button, Grid, Paper, useTheme } from '@material-ui/core'
import NoResultsFound from '../../../components/NoResultsFound'
import StackItem from '../../../components/StackItem'
import { FunctionComponent, useEffect, useState } from 'react'
import { Loading } from '../../../components/Common/Loading'
import { useChannel } from '../../../contexts/ChannelContext'
import { useSearchResults } from '../../../contexts/Search/Results'
import { TabJourneyView } from '../../../types/Channel'
import { StackRaw } from '../../../declarations/StackRaw'
import { ChannelScore } from '../../../declarations/ChannelScore'

type JourneysProps = {
  channelScores: ChannelScore[];
  stacks: StackRaw[];
  usesVoc: boolean
};

export const Journeys: FunctionComponent<JourneysProps> = ({ stacks, channelScores, usesVoc }) => {
  const channel = useChannel();
  const searchResults = useSearchResults();

  const [chunkSize] = useState<number>(12);
  const [chunkOffset, setChunkOffset] = useState<number>(12);
  const [chunk, setChunk] = useState<StackRaw[]>([]);

  const [canLoadMore, setCanLoadMore] = useState<boolean>(true);

  const handleChannelOpen = (channelScore: ChannelScore, key: string) => {
    channel.dispatch({
      type: 'INITIALISE_CHANNEL_MODAL_STATE',
      payload: { channelScore, view: TabJourneyView, expanded: [key] }
    });

    channel.dispatch({
      type: 'TOGGLE_CHANNEL_MODAL_STATE',
      payload: { value: true }
    });
  };

  const loadMore = () => {
    setChunkOffset(chunkOffset + chunkSize);
    setChunk(stacks.slice(0, chunkOffset + chunkSize));
  };

  const handleSelectStack = (stack: StackRaw) => {
    const score = channelScores.find((score) => {
      return score.segment === stack.segment && score.channel === stack.channel && score.provider === stack.provider;
    });

    const key = `journey#${stack.journey}#${stack.secure ? '1' : '0'}`;

    if (score) {
      handleChannelOpen(score, key);
    }
  };

  useEffect(() => {
    setChunk(stacks.slice(0, chunkOffset));
  }, [chunkOffset, stacks]);

  useEffect(() => {
    setCanLoadMore(chunkOffset <= stacks.length);
  }, [chunkOffset, stacks]);

  const isLoading = searchResults.state.loading;

  if (isLoading) return <JourneysLoading />;

  return (
    <Grid container spacing={4}>
      {stacks && !isLoading && !stacks.length && (
        <Grid item xs={12}>
          <Paper>
            <NoResultsFound />
          </Paper>
        </Grid>
      )}
      {chunk &&
        chunk.map((stack: StackRaw) => (
          <Grid item xs={12} md={6} lg={4} xl={2} key={stack.key} onClick={() => handleSelectStack(stack)}>
            <StackItem stack={stack} usesVoc={usesVoc} />
          </Grid>
        ))}
      <Grid item xs={12} >
        <Box justifyContent="center" display="flex" marginY={3}>
          <Button color="primary" disabled={!canLoadMore} variant="contained" onClick={() => loadMore()}>
            Load more
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

const JourneysLoading = () => {
  const theme = useTheme();

  return (
    <div style={{ paddingBottom: `${theme.spacing(4)}px` }}>
      <Grid container spacing={4}>
        {[
          ...Array(12)
            .fill(1)
            .map((v, i) => (
              <Grid key={i} item xs={1} sm={2} md={4}>
                <Loading.Panel width={'100%'} height={464} />
              </Grid>
            ))
        ]}
      </Grid>
    </div>
  );
};
