import { Dropdown } from './Filter/Dropdown';
import { Group } from './Filter/Option/Group';
import { useCriteria } from '../../contexts/Search/Criteria';
import { useSearchQuery } from '../../contexts/Search/Query';
import { Segments } from '../../types/Segments';

export const SegmentFilter = () => {
  const searchCriteria = useCriteria();
  const searchQuery = useSearchQuery();

  const handleSelectedChange = <K extends keyof SearchKind>(key: K, value: number, state: boolean) => {
    let segments = searchQuery.state.segments;

    if (!state && segments.includes(value as Segments)) {
      segments.splice(
        segments.findIndex((item) => item === value),
        1
      );
    } else {
      segments.push(value as Segments);
    }

    searchQuery.dispatch({ type: 'SET_SEGMENT_QUERY_STATE', payload: { value: segments } });
  };

  const handleSelectAll = <K extends keyof SearchKind>(key: K, state: boolean) => {
    if (state) {
      let options = searchCriteria.state.segments;
      let segments = options.map((crit) => crit.id as Segments);

      searchQuery.dispatch({ type: 'SET_SEGMENT_QUERY_STATE', payload: { value: segments } });
    } else {
      searchQuery.dispatch({ type: 'SET_SEGMENT_QUERY_STATE', payload: { value: [] } });
    }
  };

  return (
    <Dropdown
      label={'Segments'}
      count={(searchQuery.state.segments as unknown as number[]).length}
      total={searchCriteria.state.segments.length}
      disabled={searchCriteria.state.loading}
    >
      <Group
        groupKey={'segments'}
        onSelected={handleSelectedChange}
        onSelectAll={handleSelectAll}
        criteria={searchCriteria.state.segments}
        selected={searchQuery.state.segments as unknown as number[]}
      />
    </Dropdown>
  );
};
