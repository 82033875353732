import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import React, { FunctionComponent } from 'react'
import { useSearchQuery } from '../../contexts/Search/Query'
import { ChevronDown } from '../Common/icons'
import { MenuListStyles, PopperStyles, SortableItem, SortableItemTypography } from './SortingDropdown.styled'

const ASC_SORT = 'ASC';
const DESC_SORT = 'DESC';

type SortDirection = typeof ASC_SORT | typeof DESC_SORT;

export type SortOption = {
  label: string;
  field: string;
  direction: SortDirection;
  friction_question?: number;
};

type Criteria = Array<SortOption[]>;

const dropdownOptions: Criteria = [
  [
    { label: 'Date reviewed', field: 'DATE', direction: DESC_SORT }
  ],
  [
    { label: 'Market (A-Z)', field: 'MARKET', direction: ASC_SORT },
    { label: 'Market (Z-A)', field: 'MARKET', direction: DESC_SORT }
  ],
  [
    { label: 'Provider (A-Z)', field: 'PROVIDER', direction: ASC_SORT },
    { label: 'Provider (Z-A)', field: 'PROVIDER', direction: DESC_SORT }
  ],
  [
    { label: 'VoC Score (high-low)', field: 'CX_SCORE', direction: DESC_SORT },
    { label: 'VoC Score (low-high)', field: 'CX_SCORE', direction: ASC_SORT }
  ],
  [
    { label: 'Utility Score (high-low)', field: 'SCORE', direction: DESC_SORT },
    { label: 'Utility Score (low-high)', field: 'SCORE', direction: ASC_SORT }
  ],
  [{ label: 'Friction Score (low-high)', field: 'FRICTION', direction: ASC_SORT }]
];

type SortingDropdownProps = {
  onSortChange: (option: SortOption) => void;
  sortType?: string;
  sortedOnText?: string;
  sortSeparator?: string;
};

export const SortingDropdown: FunctionComponent<SortingDropdownProps> = (props) => {
  const searchQuery = useSearchQuery();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const usesVoc = searchQuery.state.selectedIndustry.usesVoc
  const thisSortType = props.sortedOnText !== undefined ? props.sortType + ": " : props.sortType;

  const handleMenuItemClick = async (event: React.MouseEvent<HTMLLIElement, MouseEvent>, option: SortOption) => {
    if (['FRICTION'].includes(option.field) === false) {
      await searchQuery.dispatch({
        type: 'SET_SORT_QUERY_STATE',
        payload: { value: option }
      });
    }

    props.onSortChange && props.onSortChange(option);

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (

    <Grid container direction="column" >
      <Grid item xs={12}>
        <ButtonGroup
          size={'large'}
          style={{
            borderRadius: '20px'
          }}
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            size={'small'}
            variant={'contained'}
            style={{
              backgroundColor: 'white',
              fontSize: '14px',
              padding: '10px 20px',
              borderRadius: '20px',
              color: '#647386',
              textTransform: 'none'
            }}
            onClick={handleToggle}
          >
            <Box display={'flex'} justifyItems={'center'} alignItems={'center'} whiteSpace={'nowrap'}>
              <span>Sort</span>{' '}
              <ChevronDown viewBox={'0 0 8 8'} style={{ width: '8px', height: '8px', marginLeft: '6px' }} />
              <span style={{ paddingLeft: "10px" }}> | {thisSortType} {props.sortedOnText}</span>
            </Box>
          </Button>
        </ButtonGroup>
        <PopperStyles
          style={{ zIndex: 10, marginTop: '10px' }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          disablePortal
          transition
          placement={'bottom-start'}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <Box>
                    {dropdownOptions.filter(x => usesVoc || x[0].field !== 'CX_SCORE').map((group, index) => {
                      return (
                        <MenuListStyles
                          key={index}
                          id={`dropdown-sort-options-${index}`}
                          style={{
                            borderBottom: dropdownOptions.length !== index + 1 ? '1px solid #ECEDEF' : ''
                          }}
                        >
                          {group.map((option, optionIndex) => {
                            return (
                              <SortableItem key={optionIndex} onClick={(event) => handleMenuItemClick(event, option)}>
                                <Box display={'flex'} justifyItems={'space-between'} alignItems={'center'}>
                                  <SortableItemTypography variant={'body1'}>{option.label}</SortableItemTypography>
                                </Box>
                              </SortableItem>
                            );
                          })}
                        </MenuListStyles>
                      );
                    })}
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </PopperStyles>
      </Grid>
    </Grid>
  );
};
