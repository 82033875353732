import { Page } from "../../components/Layout/Page";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const Loading = () => {
    return (
        <Page>
            <Box marginBottom={2}>
                <Grid container spacing={4}>
                    <Grid item md={4}>
                        <Typography variant={"h1"}>
                            <Skeleton height={48} />
                        </Typography>
                    </Grid>
                    <Grid item md={8}>
                        <Grid container spacing={4}>
                            <Grid item md={2}>
                                <Skeleton style={{ borderRadius: "20px" }} variant="rect" width={"100%"} height={44} />
                            </Grid>
                            <Grid item md={3}>
                                <Skeleton style={{ borderRadius: "20px" }} variant="rect" width={"100%"} height={44} />
                            </Grid>
                            <Grid item md={2}>
                                <Skeleton style={{ borderRadius: "20px" }} variant="rect" width={"100%"} height={44} />
                            </Grid>
                            <Grid item md={5}>
                                <Skeleton style={{ borderRadius: "12px" }} variant="rect" width={"100%"} height={44} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box marginTop={4}>
                <Grid container spacing={4}>
                    <Grid item md={4}>
                        <Skeleton style={{ borderRadius: "12px" }} variant="rect" width={"100%"} height={464} />
                    </Grid>
                    <Grid item md={4}>
                        <Skeleton style={{ borderRadius: "12px" }} variant="rect" width={"100%"} height={464} />
                    </Grid>
                    <Grid item md={4}>
                        <Skeleton style={{ borderRadius: "12px" }} variant="rect" width={"100%"} height={464} />
                    </Grid>

                    <Grid item md={4}>
                        <Skeleton style={{ borderRadius: "12px" }} variant="rect" width={"100%"} height={464} />
                    </Grid>
                    <Grid item md={4}>
                        <Skeleton style={{ borderRadius: "12px" }} variant="rect" width={"100%"} height={464} />
                    </Grid>
                    <Grid item md={4}>
                        <Skeleton style={{ borderRadius: "12px" }} variant="rect" width={"100%"} height={464} />
                    </Grid>
                </Grid>
            </Box>
        </Page>
    )
}
