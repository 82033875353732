import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';

export const DropdownContainerStyles = styled(Box)`
    && {
      border: 1px solid #D9DBE0;
      padding: 9px 12px;
      border-radius: 6px;
      text-transform: capitalize;
      color: #647386;
      position: relative;
      background-color: ${(props: { disabled: boolean }) => (props.disabled ? '#fafafa' : 'white')}
      &:hover {
        cursor: pointer;
      }
    }
`;

export const PopoverStyles = styled(Box)`
  && {
    margin-top: 12px;
    border-radius: 9px;
    filter: drop-shadow(0px 3px 9px rgba(65, 69, 88, 0.25));
    &:before {
      content: '';
      position: absolute;
      display: block;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      top: -10px;
      left: 12px;
      width: 22px;
      height: 10px;
      clip-path: polygon(50% 0, 0% 100%, 100% 100%);
      background-color: white;
      z-index: 9999;
    }
  }
`;

export const CounterTextStyles = styled(Typography)`
  && {
    color: #647386;
    font-size: 0.8em !important;
  }
`;
