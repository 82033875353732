import { useApolloClient } from '@apollo/client';
import algoliasearch from 'algoliasearch';
import { useState } from 'react';
import { getSecurityKeys } from './../services/api/get-security-keys';

/**
 * Algolia Hook for using Algolia within Components.
 */
export const useAlgolia = () => {
  const [securityKey, setSecurityKey] = useState('');
  const gqClient = useApolloClient();

  const getClient = async () => {
    if (!securityKey) {
      const key = await getSecurityKeys(gqClient);
      setSecurityKey(key.algolia);
      return algoliasearch(import.meta.env.REACT_APP_ALGOLIA_APPLICATION_ID as string, key.algolia);
    } else {
      return algoliasearch(import.meta.env.REACT_APP_ALGOLIA_APPLICATION_ID as string, securityKey);
    }
  };

  const getIndex = async (index: string) => {
    const client = await getClient();
    const indexser = client.initIndex(index);
    return indexser;
  };

  const search = async <T>(index: string, term: string, page: number) => {
    try {
      const clientIndex = await getIndex(index);
      return await clientIndex.search<T>(term, { page, hitsPerPage: 1000 });
    } catch (error) {
      console.log('Error while completing a search', error);
    }
  };

  return { search };
};
