import { getEntryCollection } from '../../support/contentful/content/get-entry-collection';
import { Blog } from '../../support/contentful/content-types';

/**
 * Gets the blog posts from contentful.
 *
 * @param {Record<string, string | number | boolean>} query
 *
 * @returns {Promise<ContentfulEntryCollectionResult<Fields.Post>>}
 */
export const getBlogPosts = async (query?: Record<string, string | number | boolean>) => {
  return await getEntryCollection<Blog['fields']>('blogPost', {
    ...query
  });
};
