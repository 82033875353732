import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const StyledBox = styled(Box)`
  && {
    font-size: 14px;
    color: #647386;
    letter-spacing: -0.2px;
    margin-bottom: 32px;
    line-height: 1.5;
  }
`;
