export const useFile = () => {
  /**
   * Converts the file size to the closest readable size.
   *
   * @param {number} size
   */
  const getFileSize = (size: number) => {
    let i = Math.floor(Math.log(size) / Math.log(1024));
    let range: string = (size / Math.pow(1024, i)).toFixed(2);
    return range + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
  };

  /**
   * Gets the file size based of the file type.
   *
   * @param {string} type
   * @param {string} filename
   */
  const getFileType = (type: string, filename: string) => {
    if (['image/png', 'image/jpg', 'image/jpeg'].includes(type)) {
      return 'IMG';
    }

    const ext = filename.split('.');

    return ext.length > 1 ? ext.pop()?.toUpperCase() : 'N/A';
  };

  return {
    getFileSize,
    getFileType
  };
};
