import { Checkbox, FormControlLabel, Radio, withStyles } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import styled from 'styled-components';

const FormControlStyles = styled(FormControlLabel)`
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #24ab3b;
  }
  .MuiFormControlLabel-label {
    white-space: nowrap;
  }
  && {
    color: #68788b;
    white-space: nowrap;
  }
`;

type ItemProps = {
  label: string;
  value: number;
  isSelected: boolean;
  onSelected: (value: number, state: boolean) => void;
  as?: 'tick' | 'checkbox';
};

export const Item = (props: ItemProps) => {
  const { as = 'checkbox' } = props;

  return (
    <FormControlStyles
      data-cy={`${as}-${props.label}`}
      label={props.label}
      control={
        as === 'checkbox' ? (
          <GreenCheckbox
            checked={props.isSelected}
            size="small"
            onChange={(e) => props.onSelected(props.value, e.target.checked)}
          />
        ) : (
          <RadioInput
            checked={props.isSelected}
            size="small"
            onChange={(e) => props.onSelected(props.value, e.target.checked)}
          />
        )
      }
    />
  );
};

const GreenCheckbox = withStyles({
  root: {
    borderRadius: '3px',
    paddingTop: '3px',
    paddingBottom: '4px',
    color: '#8B96A4',
    '&$checked': {
      color: '#24AB3B'
    }
  },
  checked: {}
})((props: CheckboxProps) => {
  return <Checkbox size={'small'} color="default" {...props} />;
});

const RadioInput = withStyles({
  root: {
    paddingTop: '3px',
    paddingBottom: '4px',
    color: '#8B96A4',
    '&$checked': {
      color: '#24AB3B'
    }
  },
  checked: {}
})((props: CheckboxProps) => {
  return <Radio {...props} />;
});
