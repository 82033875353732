import styled from 'styled-components';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { Typography, withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

export const PopperStyles = styled(Popper)`
  && {
    filter: drop-shadow(0px 3px 9px rgba(65, 69, 88, 0.25));
    &:before {
      content: '';
      position: absolute;
      display: block;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      top: -10px;
      left: 12px;
      width: 22px;
      height: 10px;
      clip-path: polygon(50% 0, 0% 100%, 100% 100%);
      background-color: white;
      z-index: 9999;
    }
  }
`;
export const MenuListStyles = styled(MenuList)`
  && {
    .MuiListItem-button:hover {
      p.MuiTypography-body1 {
        color: white !important;
      }
    }
  }
`;

export const SortableItemTypography = styled(Typography)`
  && {
    font-size: 14px !important;
    // color: #647386;
    &:hover {
      color: white !important;
    }
  }
`;

export const SortableItem = withStyles({
  root: {
    '&:hover': {
      backgroundColor: '#E5126E !important',
      color: 'white !important'
    }
  }
})(MenuItem);
