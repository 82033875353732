import { Box, Button, IconButton, Paper, Typography } from "@material-ui/core"
import React from "react"
import { getBrands } from '../../../src/brands'
import { useIdentityContext } from "../../contexts/IdentityContext"
import { signOut } from "../../support/amplify/sign-out"
import { environment } from '../../support/environment'
import { Error } from "./Error"

export const BrandMismatch = () => {
    const {setIsLoggedIn} = useIdentityContext()
    const config = getBrands(environment.branding.name || '');

    const websiteURL = import.meta.env.REACT_APP_WEBSITE_URL;

    const handleLogout = async (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()

        try {
            await signOut();
            setIsLoggedIn(false)

            window.location.href = '/login';
        } catch (e) {
            console.log(e)
        }
    }

    const contactLogout = async (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()

        try {
            await signOut();
            setIsLoggedIn(false)
            if (typeof websiteURL === 'undefined')
            {
                window.location.href = '/login';
            }
            else
            {
                window.location.href = `${websiteURL}/#contactArea`;
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Error>
            <Paper style={{ paddingBottom: "30px", paddingLeft: "30px", paddingRight: "5px"  }}>

                <Box style={{textAlign:"right"}}><IconButton aria-label="close" onClick={handleLogout}>X</IconButton></Box>

                <Box>
                    <Typography variant={"h1"}>
                        Sorry - Unable to Access
                    </Typography>
                </Box>

                <Box paddingTop={"10px"}>
                    <Typography variant={"body1"}>
                        Sorry - it appears you may not have access to this site
                    </Typography>
                    <Typography variant={"body1"} style={{ marginTop: "8px" }}>
                        Please contact us so that we can help
                    </Typography>
                </Box>

                <Box paddingTop={"20px"}>
                    <Button
                        disableElevation
                        color={"default"}
                        variant="outlined"
                        style={{
                            backgroundColor: config.colors.secondary,
                            color: "white",
                            borderRadius: "6px",
                            // textTransform: "capitalize",
                            fontWeight: "bold"
                        }}
                        onClick={contactLogout}
                    >
                        Contact Us
                    </Button>
                </Box>
            </Paper>
        </Error>
    )
}
