import { Box, Container, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { Asset, Entry } from 'contentful';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlogImage } from '../../components/Blog/BlogImage';
import { Header } from '../../components/Blog/Header';
import SkeletonView from '../../components/Common/SkeletonView';
import { Page } from '../../components/Layout/Page';
import { Pagination } from '../../components/Pagination/Pagination';
import { useAlgolia } from '../../hooks/algolia';
import { useQueryParams } from '../../hooks/query';
import { getBlogPosts } from '../../services/contentful/get-blog-posts';
import { Blog, Fields } from '../../support/contentful/content-types';
import getFormattedDate from '../../support/date/getFormattedDate';

const MAX_PAGE_ITEMS = 7;

type AlgoliaResult = {
  sys: {
    id: string;
  };
};

const loadBlogPosts = async (ids: string) => {
  return getBlogPosts({
    limit: 1000,
    skip: 0,
    order: '-sys.createdAt,-fields.originalPostDate',
    'sys.id[in]': ids
  });
};

export const Search = () => {
  const algolia = useAlgolia();
  const queryParams = useQueryParams();
  const [term, setTerm] = useState<string | null>(() => {
    return queryParams.get('term');
  });

  const [currentPage, setCurrentPage] = useState<number>(() => {
    const queryPage = queryParams.get('page');

    if (queryPage) return parseInt(queryPage);

    return 1;
  });

  const [totalResultPages, setTotalResultPages] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(true);
  const [posts, setPosts] = useState<Blog[]>([]);

  function getPageIndices(page: number): { startIndex: number; endIndex: number } {
    if (isNaN(Number(page)) || page < 1) return { startIndex: 0, endIndex: 0 };

    const startIndex = (page - 1) * MAX_PAGE_ITEMS;
    const endIndex = startIndex + MAX_PAGE_ITEMS;
    return { startIndex, endIndex };
  }

  /**
   * These are the posts we want to show on the UI. Usually a subsection of the state variable posts
   */
  const [currentPosts, setCurrentPosts] = useState<Blog[]>([]);

  /**
   * Updates the `currentPosts` state with posts for the current page.
   *
   * This effect recalculates the slice of posts to be displayed whenever
   * the `currentPage` changes or the `posts` array is updated. It ensures
   * that the component only renders posts relevant to the current page,
   * aiding in pagination functionality.
   *
   * The `getPageIndices` function is used to compute the start and end indices
   * for slicing the `posts` array based on the current page number.
   *
   * @effect Recalculates visible posts slice on `currentPage` or `posts` changes.
   */
  useEffect(() => {
    const { startIndex, endIndex } = getPageIndices(currentPage);
    setCurrentPosts(posts.slice(startIndex, endIndex));
  }, [currentPage, posts]);

  /**
   * Performs a search operation using Algolia based on the provided search term and updates the blog posts state.
   * If the search term is empty, the operation is skipped.
   *
   * Steps:
   * 1. Sets loading state to true at the start of the search operation.
   * 2. Uses the Algolia client to search for the term in the specified Algolia blog index.
   * 3. Converts the search results into a comma-separated string of IDs.
   * 4. Calls `loadBlogPosts` with the resulting IDs to fetch the corresponding blog posts.
   * 5. On successful fetch, logs the fetched posts, calculates the total number of result pages, updates the posts state, and sets loading to false.
   *
   * Dependencies:
   * - `term`: The search term used for querying. The effect runs whenever `term` changes.
   */
  useEffect(() => {
    if (!term) return;

    setLoading(true);

    algolia
      .search<AlgoliaResult>(import.meta.env.REACT_APP_ALGOLIA_BLOG_INDEX as string, term as string, 0)
      .then((results) => {
        return results!.hits.map((hit) => hit.sys.id).join(',');
      })
      .then((ids) => {
        loadBlogPosts(ids) /** Just setting page zero as we want to fetch all */
          .then((request) => {
            if (request.type === 'success') {
              console.log('Setting posts', request.response.items);
              setTotalResultPages(Math.ceil(request.response.items.length / MAX_PAGE_ITEMS));
              setPosts(request.response.items);
            }
          })
          .then(() => setLoading(false));
      });
    // the algolia object (and even the search function) is different on
    // each render, so we can't put it in the deps array
    // ??? WHY ARE WE DOING A SEARCH INSIDE USEEFFECT ANYWAY ???
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term]);

  /**
   * Synchronizes the search term in the app's state with the query parameter in the URL.
   * If the term in the URL query parameter is the same as the current state, no action is taken.
   * Otherwise, it updates the term in the state and resets the current page to 1.
   *
   * Dependencies:
   * - `queryParams`: A URLSearchParams object representing the current URL query parameters.
   *   The effect runs whenever `queryParams` changes.
   */
  useEffect(() => {
    if (queryParams.get('term') === term) return;

    setTerm(queryParams.get('term'));
    setCurrentPage(1);
  }, [queryParams, term]);

  if (loading)
    return (
      <>
        <SkeletonView />
      </>
    );

  return (
    <Page>
      <Container>
        {term && <Header title={`Search results for '${term}'`} term={term} />}

        <Divider
          style={{
            backgroundColor: '#D9DBE0'
          }}
        />

        <Pagination
          onPageNavigate={(page) => setCurrentPage(page)}
          page={currentPage}
          meta={{ limit: 20, skip: 20 * currentPage, totalPages: totalResultPages }}
        />

        <Grid container spacing={0}>
          {posts &&
            currentPosts.map((item, index) => {
              return (
                <Grid item xs={index < 3 ? 4 : 12} key={index}>
                  {index < 3 ? <CardResult item={item} /> : <InlineResult item={item} />}
                </Grid>
              );
            })}
        </Grid>

        <Pagination
          onPageNavigate={(page) => setCurrentPage(page)}
          page={currentPage}
          meta={{ limit: 20, skip: 20 * currentPage, totalPages: totalResultPages }}
        />
      </Container>
    </Page>
  );
};

const CardResult = (props: { item: Blog }) => {
  const navigate = useNavigate();

  return (
    <Box marginX={2} marginBottom={2}>
      <Paper style={{ height: '100%', overflow: 'hidden' }}>
        <Box onClick={() => navigate(`/insights/${props.item.fields.path}`)}>
          <BlogImage
            s3={props.item.fields.imageOnS3 as unknown as Entry<Fields.S3Asset>}
            asset={props.item.fields.image as unknown as Asset}
          />
          <Box paddingX={2} paddingTop={2}>
            <Typography variant="h3" style={{ marginBottom: '8px' }}>
              {props.item.fields.title}
            </Typography>
            <Typography variant="body1">
              {props.item.fields.subtitle && props.item.fields.subtitle && props.item.fields.subtitle}
            </Typography>
          </Box>
          <Box padding={2} display="flex">
            <Typography variant="body1">
              <strong>{props.item.fields.source && props.item.fields.source && props.item.fields.source}</strong>
            </Typography>
            <Divider orientation="vertical" flexItem style={{ marginLeft: '8px', marginRight: '8px' }} />
            <Typography variant="body1">{getFormattedDate(props.item.fields.originalPostDate)}</Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

const InlineResult = (props: { item: Blog }) => {
  const navigate = useNavigate();

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      borderTop={'1px solid #D9DBE0'}
      paddingY={2}
      style={{ cursor: 'pointer' }}
      onClick={() => navigate(`/insights/${props.item.fields.path}`)}
    >
      <Box style={{ marginBottom: '8px' }}>
        <Typography variant="h3" style={{ marginBottom: '8px', maxWidth: '800px' }}>
          {props.item.fields.title}
        </Typography>
        <Typography variant="body1">
          {props.item.fields.subtitle && props.item.fields.subtitle && props.item.fields.subtitle}
        </Typography>
      </Box>

      <Box display="flex">
        <Typography variant="body1">
          <strong>{props.item.fields.source && props.item.fields.source && props.item.fields.source}</strong>
        </Typography>
        <Divider orientation="vertical" flexItem style={{ marginLeft: '8px', marginRight: '8px' }} />
        <Typography variant="body1">{getFormattedDate(props.item.fields.originalPostDate)}</Typography>
      </Box>
    </Box>
  );
};
