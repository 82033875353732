import { createContext, PropsWithChildren, useContext, useReducer } from 'react';
import { ChannelTabs, TabChannelView } from '../types/Channel';
import { ChannelScore } from '../declarations/ChannelScore';

type InitialiseCompareChannelModalPayload = {
  channelScores: ChannelScore[];
  view: ChannelTabs;
  expanded?: string[];
  selected?: Record<string, string>;
};

type ToggleCompareChannelModalPayload = {
  value: boolean;
};

type SetCompareChanelScoresModalPayload = {
  channelScores: ChannelScore[] | null;
};

type InitialiseCompareChannelModal = {
  type: 'INITIALISE_COMPARE_CHANNEL_MODAL_STATE';
  payload: InitialiseCompareChannelModalPayload;
};
type ToggleCompareChannelModal = {
  type: 'TOGGLE_COMPARE_CHANNEL_MODAL_STATE';
  payload: ToggleCompareChannelModalPayload;
};
type SetCompareChannelScoresModal = {
  type: 'SET_COMPARE_CHANNEL_SCORES_MODAL_STATE';
  payload: SetCompareChanelScoresModalPayload;
};

type Action = InitialiseCompareChannelModal | ToggleCompareChannelModal | SetCompareChannelScoresModal;

type Dispatch = (action: Action) => void;

type State = {
  channelScores: ChannelScore[] | null;
  open: boolean;
  default: {
    expanded?: string[];
    selected?: Record<string, string>;
    view: ChannelTabs;
  };
};

type CompareChannelContextType = { state: State; dispatch: Dispatch } | undefined;
const CompareChannelContext = createContext<CompareChannelContextType>(undefined);

const initialiseCompareChannelModal = (state: State, action: InitialiseCompareChannelModal): State => {
  const defaultState = {
    expanded: action.payload.expanded,
    selected: action.payload.selected,
    view: action.payload.view
  };

  return {
    ...state,
    channelScores: action.payload.channelScores,
    default: defaultState
  };
};

const toggleCompareChannelModal = (state: State, action: ToggleCompareChannelModal): State => {
  return {
    ...state,
    open: action.payload.value
  };
};

const setCompareChannelScoresModal = (state: State, action: SetCompareChannelScoresModal): State => {
  return {
    ...state,
    channelScores: action.payload.channelScores
  };
};

const reducer = (state: State, action: Action) => {
  if (action.type === 'INITIALISE_COMPARE_CHANNEL_MODAL_STATE') {
    return initialiseCompareChannelModal(state, action);
  } else if (action.type === 'TOGGLE_COMPARE_CHANNEL_MODAL_STATE') {
    return toggleCompareChannelModal(state, action);
  } else if (action.type === 'SET_COMPARE_CHANNEL_SCORES_MODAL_STATE') {
    return setCompareChannelScoresModal(state, action);
  }

  throw new Error(`Unhandled action type in 'CompareChannelContext'`);
};

const initialState = {
  channelScores: null,
  open: false,
  default: {
    view: TabChannelView as ChannelTabs
  }
};

type ChannelProviderProps = {};

export const CompareChannelProvider = (props: PropsWithChildren<ChannelProviderProps>) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <CompareChannelContext.Provider value={{ state, dispatch }}>{props.children}</CompareChannelContext.Provider>;
};

export const useCompareChannel = () => {
  const context = useContext(CompareChannelContext);

  if (context === undefined) {
    throw new Error('useCompareChannel must be used within a CompareChannelProvider');
  }

  return context;
};
