import { FunctionList } from "../../FunctionView/FunctionList";
import { useTheme } from "@material-ui/core";
import { ChannelScore } from '../../../declarations/ChannelScore';

type FunctionsProps = {
    channelScore: ChannelScore
    usesVoc: boolean
}

export const Functions = (props: FunctionsProps) => {
    const theme = useTheme()

    return (
        <div style={{ padding: `${theme.spacing(1)}px ${theme.spacing(4)}px` }}>
            <FunctionList channelScore={props.channelScore} usesVoc={props.usesVoc} />
        </div>
    )
}