import { Typography } from '@material-ui/core';
import './FrictionScoreBreakdown.css';
type FSBreakdownProps = {
  score: FrictionScore;
};

const breakdownItems = ['data_input', 'swipes', 'wipes', 'clicks', 'screens', 'security_score'];
const itemNaming = {
  data_input: 'No. of fields',
  security_score: 'Security',
  swipes: 'Swipes',
  wipes: 'Wipes',
  screens: 'Screens',
  clicks: 'Clicks'
};

const FrictionScoreBreakdown = (props: FSBreakdownProps) => {
  const allowedItems = Object.entries(props.score).filter((v) => breakdownItems.includes(v[0]));

  //   Object.entries(f).find((f) => f[0] === 'data_input')?.[1];
  return (
    <div className="container">
      <div className="hero">
        <Typography variant="h4">Friction score</Typography>
        <div className="value">{props.score.friction_score}</div>
      </div>
      {allowedItems.map((v, idx) => {
        return (
          <div key={idx} className="row">
            <div>{Object.entries(itemNaming).find((f) => f[0] === v[0])?.[1]}</div>
            <div className="value">{v[1]}</div>
          </div>
        );
      })}
    </div>
  );
};

export default FrictionScoreBreakdown;
