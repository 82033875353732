import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonView = () => {
  const SKELETONBOX_SIZE = 300;

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
      }}
    >
      {/* Three squares on top in a row */}
      {/* TODO: Needs to be more true to life. Will do for now but make better later, huh */}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '400px'
        }}
      >
        <Skeleton variant="rect" width={SKELETONBOX_SIZE} height={SKELETONBOX_SIZE} style={{ marginRight: 10 }} />
        <Skeleton variant="rect" width={SKELETONBOX_SIZE} height={SKELETONBOX_SIZE} style={{ marginRight: 10 }} />
        <Skeleton variant="rect" width={SKELETONBOX_SIZE} height={SKELETONBOX_SIZE} style={{ marginRight: 10 }} />
      </Box>

      {/* Two smaller rows below with text content skeleton */}
      <Box style={{ marginTop: 20 }}>
        <Skeleton variant="text" width={300} />
        <Skeleton variant="text" width={300} />
      </Box>
      <Box style={{ marginTop: 20 }}>
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={200} />
      </Box>
    </Box>
  );
};

export default SkeletonView;
