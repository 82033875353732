import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from 'react'

type AdvancedJourneyPropsContextType = {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
}

const AdvancedJourneyContext = createContext<AdvancedJourneyPropsContextType>({
    open: false,
    setOpen: (state) => { }
})

type AdvancedJourneyProps = {}

export const AdvancedJourneyProvider = (props: PropsWithChildren<AdvancedJourneyProps>) => {
    const [open, setOpen] = useState<boolean>(false)

    return (
        <AdvancedJourneyContext.Provider value={{
            open, setOpen
        }}>
            {props.children}
        </AdvancedJourneyContext.Provider>
    )
}

export const useAdvancedJourneyContext = () => {
    return useContext(AdvancedJourneyContext)
}
