import { Box } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';
import { Entry, Asset } from 'contentful';

export interface BlogImageProps {
  s3?: Entry<{ filename: string; s3Url: string }>;
  asset?: Asset;
  height?: string;
  rounded?: boolean;
  roundedSmall?: boolean;
}

export const BlogImage: FunctionComponent<BlogImageProps> = ({
  rounded,
  roundedSmall,
  height,
  s3,
  asset,
}) => {
  const image = useMemo(() => {
    return s3 !== undefined ? s3.fields.s3Url : asset !== undefined ? asset.fields.file.url : '';
  }, [s3, asset]);

  if (!height) {
    height = '200px';
  }

  return (
    <Box
      position={'relative'}
      height={height}
      width="100%"
      role={'img'}
      style={{
        borderRadius: `${rounded !== undefined ? '20px' : '0' || roundedSmall !== undefined ? '5px' : '0'}`,
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
    </Box>
  );
};
