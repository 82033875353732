import React from 'react';

import { AccountContainer } from './containers/AccountContainer';
import { AuthContainer } from './containers/AuthContainer';
import { BlogContainer } from './containers/BlogContainer';
import { DashboardContainer } from './containers/DashboardContainer';
import { ResourceContainer } from './containers/ResourceContainer';
import { SearchContainer } from './containers/SearchContainer';
import { useIdentityContext } from './contexts/IdentityContext';

export interface RoutesProps { }

/**
 * The app routes component, it registers the containers for the app.
 */
const Routes: React.FC<RoutesProps> = () => {
  const { loading } = useIdentityContext();

  if (loading) return null;

  return (
    <>
      <AuthContainer />
      <DashboardContainer />
      <SearchContainer />
      <AccountContainer />
      <ResourceContainer />
      <BlogContainer />
    </>
  );
};

export default Routes;
