import { FunctionComponent, useEffect, useState } from 'react';
import { useCriteria } from '../../contexts/Search/Criteria';
import { useSearchQuery } from '../../contexts/Search/Query';
import { Industry } from '../../types/Industries';
import { Dropdown } from './Filter/Dropdown';
import { Group } from './Filter/Option/Group';

type IndustryFilterProps = {
  onIndustryChange?: (industry: Industry) => void;
};

export const IndustryFilter: FunctionComponent<IndustryFilterProps> = (props) => {
  const [industries, setIndustries] = useState('Industry');
  const [authorisedIndustries, setAuthorisedIndustries] = useState<Industry[]>([]);
  const searchCriteria = useCriteria();
  const searchQuery = useSearchQuery();

  const handleSelectedChange = async <K extends keyof SearchKind>(key: K, value: number, state: boolean) => {
    const selectedIndustry = authorisedIndustries.find(x => x.industryId === value);
    if (selectedIndustry) {
      searchQuery.dispatch({ type: 'SET_SELECTED_INDUSTRY', payload: { value: selectedIndustry } });
      if (selectedIndustry.cycles) {
        props.onIndustryChange && props.onIndustryChange(selectedIndustry);
        searchQuery.dispatch({
          type: 'SET_SELECTED_CYCLE',
          payload: { value: selectedIndustry.cycles[0] }
        });
      }
    }
  };

  useEffect(() => {
    const authorisedIndustries = searchCriteria.state.industries;
    // console.log('UseEffect Industry Filter', authorisedIndustries);
    authorisedIndustries && setAuthorisedIndustries(authorisedIndustries);
    return () => setAuthorisedIndustries([]);
  }, [searchCriteria, setAuthorisedIndustries]);

  useEffect(() => {
    const criterion = searchQuery.state.selectedIndustry.industryName;

    if (criterion) {
      setIndustries(`${criterion}`);
    }
    return () => {
      setIndustries('');
    };
  }, [searchCriteria, searchQuery, setIndustries]);

  return (
    <Dropdown label={`${industries}`} hideCounter count={1} total={1} disabled={false}>
      <Group
        single
        hideSelectAll
        groupKey={'sectors'}
        onSelected={handleSelectedChange}
        criteria={authorisedIndustries.map(
          industry => ({
            id: industry.industryId,
            name: industry.industryName
          })
        )} //Dig out the keys from the Industries object
        selected={[searchQuery.state.selectedIndustry.industryId]}
        data-cy={`sector-filter`}
      />
    </Dropdown>
  );
};
