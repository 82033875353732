import styled from 'styled-components';
import { Close as MatClose } from '@material-ui/icons';
import { Box, Button, Typography } from '@material-ui/core';
import colors from '../../support/theme/colors';

export const Close = styled(MatClose)`
  && {
    margin-left: auto;
    color: white;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const ModalTitle = styled(Typography)`
  && {
    font-size: 14px !important;
    line-height: 18px !important;
    color: white !important;
    font-weight: 600 !important;
    margin-right: auto;
  }
`;

export const ButtonContainer = styled(Box)`
  && {
    background-color: rgba(255, 255, 255, 0.3);
    justify-content: space-between;
    padding: 3px;
    border-radius: 12px;
    display: flex;
    &.dark {
      background-color: #d9dbe0;
    }
  }
`;

export const ViewButton = styled(Button)`
  && {
    &.selected {
      background-color: #ffffff !important;
      color: ${colors.main} !important;
    }

    &.dark {
      background-color: #d9dbe0;
      color: ${colors.main} !important;
    }

    &.dark.selected {
      background-color: ${colors.main} !important;
      color: white !important;
    }

    text-transform: none;
    background-color: transparent !important;
    color: #ffffff !important;
    box-shadow: none !important;
    border-radius: 9px !important;
  }
`;
