export const TabChannelView = "channel"
export const TabJourneyView = "journey"
export const TabFunctionView = "function"
export const TabListView = "list"

export type ChannelTabs = typeof TabChannelView | typeof TabJourneyView | typeof TabFunctionView | typeof TabListView

export type MenuItem = {
    key: ChannelTabs,
    label: string,
    icon: any
}