import { ApolloClient, gql } from '@apollo/client'
import { preProcessGetChannelScoresParams } from './get-channel-scores';

/**
 * The response type for the api request.
 */
type GetPrevalenceResponse = {
  getPrevalence: string[][];
}

/**
 * The variables used for the request.
 */
type GetPrevalenceVariables = {
  markets: number[];
  providers: number[];
  segments: number[];
  channels: number[];
  sectors: number[];
  questions?: { type: string; questionIds: number[] };
  audience: Record<string, number[]>;
  orderBy: { field: string; direction: string; friction_question?: number };
  as_at_date?: string;
};

/**
 * The query to use when running the request.
 */
const query = gql`
  query prevalence(
    $sectors: [Int]
    $markets: [Int]
    $channels: [Int]
    $as_at_date: AWSDate
    $providers: [Int]
    $segments: [Int]
    $audience: AudienceSearch
    $orderBy: OrderBy
    $questions: QuestionCriteria
  ) {
    getPrevalence(
      where: {
        sector: $sectors
        market: $markets
        channel: $channels
        provider: $providers
        segment: $segments
        questions: $questions
      }
      audience: $audience
      orderBy: $orderBy
      as_at_date: $as_at_date
    )    
  }
`;

/**
 * Gets the prevalence from the api.
 *
 * @param {ApolloClient<object>} client
 * @param {GetPrevalenceVariables} variables
 *
 * @throws Error
 */
export const getPrevalence = async (client: ApolloClient<object>, variables: GetPrevalenceVariables) => {
  // note we have to pre-process the params in the same way as getChannelScores
  try {
    const prevalenceResult = await client.query<GetPrevalenceResponse, GetPrevalenceVariables>({
      query,
      variables: preProcessGetChannelScoresParams(variables)
    });
    return prevalenceResult.data.getPrevalence
  } catch (caught) {
    console.error(caught)
    throw Error();
  }
};
