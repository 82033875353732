import { ApolloClient, gql } from '@apollo/client';
import { Sectors } from '../../types/Sectors';
import { JourneyQuestion, Question } from '../../declarations/Question';

/**
 * The response type for the api request.
 */
type GetQuestionsResponse = {
  getQuestions: {
    questions: Question[];
    journeys: JourneyQuestion[];
  };
};

/**
 * The variables used for the request passed in from the caller.
 */
type GetQuestionsVariables = {
  sector: Sectors;
  audience: any;
};

/**
 * 
 * The variables passed to the server - as_at_date must always
 * be the current date so we don't trust the caller to pass it in
 */
type ServerGetQuestionsVariables = GetQuestionsVariables &
{
  as_at_date: string;
};

/**
 * The query to use when running the request.
 */
const query = gql`
  query questions($as_at_date: AWSDate, $journey: [Int], $segment: [Int], $sector: [Int], $audience: AudienceSearch) {
    getQuestions(
      where: { as_at_date: $as_at_date, journey: $journey, segment: $segment, sector: $sector }
      audience: $audience
    ) {
      questions {
        weights {
          segment
          weight
        }
        id
        journey
        journeyId
        predecessor
        questionnaire_version
        sector
        sectorId
        secure_channel
        secure_channelId
        segment
        segmentId
        text
        has_friction_scores
        valid_from
        valid_to
      }
      journeys {
        journey
        journeyId
        weights {
          segment
          weight
        }
      }
    }
  }
`;

/**
 * Gets the question from the api.
 *
 * @param {ApolloClient<object>} client
 * @param {GetQuestionsVariables} variables
 *
 * @throws Error
 */
export const getQuestions = async (client: ApolloClient<object>, variables: GetQuestionsVariables) => {
  try {
    const response = await client.query<GetQuestionsResponse, ServerGetQuestionsVariables>({
      query,
      variables: { ...variables, as_at_date: new Date().toISOString().split('T')[0] }
    });

    return response.data.getQuestions;
  } catch (caught) {
    throw Error();
  }
};
