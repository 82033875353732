import { uniqBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useCriteria } from '../contexts/Search/Criteria';
import { FeatureJourney } from '../types/Functions';
import { useQuestions } from './questions';
import { ChannelScore } from '../declarations/ChannelScore';
import { JourneyCriterion } from '../declarations/Criterion';

/**
 * Functions hook for use within components.
 */
export const useFunctions = () => {
  const searchCriteria = useCriteria();
  const { resolveWeights, getJourneyWeights } = useQuestions();

  /**
   * Get an array of unique questions by id.
   *
   * @returns {Question[]}
   */
  const uniqueQuestions = useMemo(
    () => uniqBy(searchCriteria.state.questions.questions, 'id'),
    [searchCriteria.state.questions]
  );

  /**
   * Get the journey.
   */
  const getJourney = useCallback((journey: JourneyCriterion): FeatureJourney => {
    const questions = uniqueQuestions
      .filter((q) => q.journeyId === journey.id)
      .map((q) => {
        const weights = resolveWeights(q.weights);

        return {
          ...q,
          weights
        };
      });

    const weights = getJourneyWeights(journey.id);

    return {
      key: `journey#${journey.id}`,
      id: journey.id,
      name: journey.name,
      secure: true,
      questions,
      weights
    };
  }, [getJourneyWeights, resolveWeights, uniqueQuestions])

  /**
   * Get a combined list of both secure and non-secure journeys.
   *
   * @returns {FeatureJourney[]}
   */
  const journeys: FeatureJourney[] = useMemo(() => {
    const secureJourneys = searchCriteria.state.secureJourneys.map((j) => getJourney(j));

    return secureJourneys;
  }, [searchCriteria.state.secureJourneys, getJourney]);

  /**
   * Get an array of unique names from the journeys by their key.
   *
   * @returns {string[]}
   */
  const names = useMemo(() => journeys.map((j) => j.key), [journeys]);

  /**
   * Get the channel journeys from the feature journeys.
   */
  const channelJourneys = useMemo(() => {
    return journeys.map((j) => {
      const weights = getJourneyWeights(j.id);

      return {
        key: j.key,
        name: j.name,
        id: j.id,
        secure: j.secure,
        questions: j.questions.map((q) => {
          return q;
        }),
        weights
      };
    });
  }, [getJourneyWeights, journeys]);

  /**
   * Transforms the journeys based on their secure state.
   *
   * @param {ChannelScore} channelScore
   * @param {boolean} secure
   */
  const transformChannelJourneys = (channelScore: ChannelScore, secure: boolean) => {
    return channelScore[secure ? 'secure' : 'nonsecure'].journeys.map((wjs) => {
      const answers = wjs.answers.map((a) => {
        return {
          value: a.value,
          questionId: a.question.id
        };
      });

      return {
        secure: secure,
        journeyId: wjs.journey,
        cx_score: wjs.cx_score,
        score: wjs.score,
        answers,
      };
    });
  };

  /**
   * Transforms the channel scores.
   *
   * @param {ChannelScore[]} channelScores
   */
  const transformChannels = (channelScores: ChannelScore[]) => {
    return channelScores.map((s, i) => transformChannel(s, i));
  };

  /**
   * Transforms a singular channel score.
   *
   * @param {ChannelScore} channelScore
   * @param {number} sort
   */
  const transformChannel = (channelScore: ChannelScore, sort: number) => {
    const a1 = transformChannelJourneys(channelScore, true);
    const a2 = transformChannelJourneys(channelScore, false);

    const answers = a1.concat(a2);

    return {
      ...channelScore,
      sort,
      journeys: channelJourneys,
      answers
    };
  };

  return {
    transformChannel,
    transformChannels,
    transformChannelJourneys,
    journeys,
    names,
    channelJourneys
  };
};
