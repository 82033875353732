import { FunctionComponent } from 'react';
import { usePrioritisationOverlay } from '../../contexts/PrioritisationOverlayContext';
import { Information } from '../Common/icons';
import { Segments } from '../../types/Segments';
import { Box } from '@material-ui/core';

type PrioritisationOverlayTriggerProps = {
  text: string;
  question: number;
  segment: Segments;
  weight: number;
  secure: boolean;
};

export const PrioritisationOverlayTrigger: FunctionComponent<PrioritisationOverlayTriggerProps> = (props) => {
  const { dispatch } = usePrioritisationOverlay();

  const handleClick = () => {
    dispatch({ type: 'SET_VOC_OVERLAY_MODAL_STATE', payload: { open: true } });
    dispatch({
      type: 'SET_VOC_OVERLAY_QUESTION_STATE',
      payload: {
        text: props.text,
        question: props.question,
        segment: props.segment,
        weight: props.weight,
        secure: props.secure
      }
    });
  };

  return (
    <Box
      component={'span'}
      style={{
        cursor: 'pointer',
        width: '12px',
        height: '12px',
        display: 'inline-flex',
        marginRight: '4px',
        verticalAlign: 'middle'
      }}
      onClick={handleClick}
    >
      <Information />
    </Box>
  );
};
