import { createContext, PropsWithChildren, useContext, useReducer } from 'react';
import { ChannelTabs, TabJourneyView } from '../types/Channel';
import { ChannelScore } from '../declarations/ChannelScore';
import { StackRaw } from '../declarations/StackRaw';

type ComparisonPayload = {
  item: ChannelScore;
  stack?: StackRaw;
  view: ChannelTabs;
};

type ToggleComparisonItem = { type: 'TOGGLE_COMPARISON_ITEM_IN_STATE'; payload: ComparisonPayload };
type UpdateComparisonItem = { type: 'UPDATE_COMPARISON_ITEM_IN_STATE'; payload: ComparisonPayload };
type ClearComparison = { type: 'CLEAR_COMPARISON_ITEMS_IN_STATE'; payload: { view: ChannelTabs } };
type ClearComparisonInAllViews = { type: 'CLEAR_COMPARISON_IN_ALL_VIEWS' };

type Action = ToggleComparisonItem | UpdateComparisonItem | ClearComparison | ClearComparisonInAllViews;

type Dispatch = (action: Action) => void;

type ComparisonValue = {
  value: ChannelScore;
  stack?: StackRaw;
  hidden: boolean;
};

type State = Record<ChannelTabs, ComparisonValue[]> & {};

type ComparisonContextType = { state: State; dispatch: Dispatch } | undefined;
const ComparisonContext = createContext<ComparisonContextType>(undefined);

const toggleComparisonItem = (state: State, action: ToggleComparisonItem): State => {
  let current = Array.from(state[action.payload.view]);

  const index = state[action.payload.view].findIndex((c) => {
    if (action.payload.view === TabJourneyView) {
      return c.stack && action.payload.stack && c.stack.key === action.payload.stack.key;
    }

    return c.value.key === action.payload.item.key;
  });

  if (index >= 0) {
    current.splice(index, 1);
  } else {
    current.push({
      value: action.payload.item,
      stack: action.payload.stack,
      hidden: false
    });
  }

  return {
    ...state,
    [action.payload.view]: current
  };
};

const updateComparisonItem = (state: State, action: UpdateComparisonItem): State => {
  return {
    ...state
  };
};

const clearComparison = (state: State, action: ClearComparison): State => {
  return {
    ...state,
    [action.payload.view]: []
  };
};

const clearComparisonInAllViews = (state: State, action: ClearComparisonInAllViews): State => {
  return {
    ...state,
    channel: [],
    function: [],
    journey: [],
    list: []
  };
};

const reducer = (state: State, action: Action) => {
  if (action.type === 'TOGGLE_COMPARISON_ITEM_IN_STATE') {
    return toggleComparisonItem(state, action);
  } else if (action.type === 'UPDATE_COMPARISON_ITEM_IN_STATE') {
    return updateComparisonItem(state, action);
  } else if (action.type === 'CLEAR_COMPARISON_ITEMS_IN_STATE') {
    return clearComparison(state, action);
  } else if (action.type === 'CLEAR_COMPARISON_IN_ALL_VIEWS') {
    return clearComparisonInAllViews(state, action);
  }

  throw new Error(`Unhandled action type in 'ComparisonContext'`);
};

const initialState = {
  channel: [],
  function: [],
  journey: [],
  list: []
};

type ComparisonProviderProps = {};

export const ComparisonProvider = (props: PropsWithChildren<ComparisonProviderProps>) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ComparisonContext.Provider value={{ state, dispatch }}>
      <>{props.children}</>
    </ComparisonContext.Provider>
  );
};

export const useComparison = () => {
  const context = useContext(ComparisonContext);

  if (context === undefined) {
    throw new Error('useComparison must be used within a ComparisonProvider');
  }

  return context;
};
