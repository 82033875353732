import { useNavigate } from 'react-router-dom';
import { getResourceDownloadLink, getResources as getUserResources } from '../support/graphql/queries';
import { useCallback } from 'react';

/**
 * A hook for using resources in components.
 */
export const useResources = () => {
  const navigate = useNavigate();

  /**
   * Get the resources for a given user.
   *
   * @param {any} client
   * @param {{ limit: number; offset: number }} variables
   */
  const getResources = useCallback(
    async (client: any, variables?: { limit: number; offset: number }): Promise<any> => {
      return getUserResources(client, variables);
    }
    , [])

  /**
   * Get the download url for a given resource.
   *
   * @param {any} client
   * @param {number} resourceId
   */
  const getResourceFile = (client: any, resourceId: number) => {
    return getResourceDownloadLink(client, { resourceId });
  };

  /**
   * Force a download by sending the user to the resource url.
   *
   * @param {any} client
   * @param {number} resourceId
   */
  const downloadResource = async (client: any, resourceId: number) => {
    const url = await getResourceFile(client, resourceId);
    if (!url) {
      navigate('/resources/unauthorised');
      return;
    }

    window.location.href = await getResourceFile(client, resourceId);
  };

  return {
    getResources,
    getResourceFile,
    downloadResource
  };
};
