import { ApolloClient, gql } from '@apollo/client';
import { Segments } from '../../types/Segments';
import { Demographic } from '../../declarations/Demographics';

/**
 * The response type for the api request.
 */
type GetQuestionDemographicsResponse = {
  getQuestionDemographics: Record<string, Demographic[]>;
};
/**
 * The variables used for the request.
 */
type GetQuestionDemographicsVariables = {
  audience: Record<string, number[]>;
  as_at_date: string;
  question: number;
  segment: Segments;
};

/**
 * The query to use when running the request.
 */
const query = gql`
  query getQuestionDemographics($audience: AudienceSearch, $as_at_date: AWSDate, $question: Int!, $segment: Int!) {
    getQuestionDemographics(
      audience: $audience
      where: { as_at_date: $as_at_date, question: $question, segment: $segment }
    ) {
      question
      total_count
      important_count
      age {
        id
        important_count
        percent
        total_count
      }
      market {
        id
        important_count
        percent
        total_count
      }
      gender {
        id
        important_count
        percent
        total_count
      }
      income {
        important_count
        id
        percent
        total_count
      }
    }
  }
`;

/**
 * Gets the question demographics from the api.
 *
 * @param {ApolloClient<object>} client
 * @param {GetQuestionDemographicsVariables} variables
 *
 * @throws Error
 */
export const getQuestionDemographics = async (
  client: ApolloClient<object>,
  variables: GetQuestionDemographicsVariables
) => {
  try {
    const response = await client.query<GetQuestionDemographicsResponse, GetQuestionDemographicsVariables>({
      query,
      variables
    });

    return response.data.getQuestionDemographics;
  } catch (caught) {
    throw Error();
  }
};
