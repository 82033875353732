import styled from "styled-components";
import {Box} from "@material-ui/core";

export const CheckBoxIndicator = styled(Box)`
    && {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border-width: 1px;
      border-style: solid;
      margin-right: 4px;
    }
`;
