import { ApolloClient, gql } from '@apollo/client';
import { Segments } from '../../types/Segments';

/**
 * The response type for the api request.
 */
type GetTotalDemographicsResponse = {
  getTotalDemographics: Array<{ segment: Segments; total_count: number }>;
};

/**
 * The variables used for the request.
 */
type GetTotalDemographicsVariables = {
  audience: Record<string, number[]>;
  as_at_date: string;
  segment: Segments;
};

/**
 * The query to use when running the request.
 */
const query = gql`
  query getTotalDemographics($audience: AudienceSearch, $as_at_date: AWSDate, $segment: Int) {
    getTotalDemographics(segment: $segment, audience: $audience, as_at_date: $as_at_date) {
      segment
      total_count
    }
  }
`;

/**
 * Gets the total demographics from the api.
 *
 * @param {ApolloClient<object>} client
 * @param {GetTotalDemographicsVariables} variables
 *
 * @throws Error
 */
export const getTotalDemographics = async (client: ApolloClient<object>, variables: GetTotalDemographicsVariables) => {
  try {
    const response = await client.query<GetTotalDemographicsResponse>({
      query,
      variables
    });

    return response.data.getTotalDemographics;
  } catch (caught) {
    throw Error();
  }
};
