import { Auth } from '@aws-amplify/auth'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { AmplifyInvalidParameter, AmplifySuccess, AmplifyUnknownError } from '../../types/Amplify'
import { CognitoError } from '../../types/Cognito'

type ChangePasswordResult = AmplifySuccess | AmplifyInvalidParameter | AmplifyUnknownError;

/**
 * Handling changing the password via the amplify sdk.
 *
 * @param {CognitoUser} user
 * @param {string} current
 * @param {string} newPassword
 *
 * @returns {Promise<ChangePasswordResult>}
 */
export const changePassword = async (
  user: CognitoUser,
  current: string,
  newPassword: string
): Promise<ChangePasswordResult> => {
  try {
    await Auth.changePassword(user, current, newPassword);

    return { type: 'success' };
  } catch (caught) {
    if ((caught as CognitoError).code === undefined) {
      return {
        type: 'unknown-error',
        exception: caught
      };
    }

    switch ((caught as CognitoError).code) {
      case 'InvalidParameterException':
        return {
          type: 'invalid-parameter',
          error: 'New Passwords do not match criteria!'
        };
    }

    return { type: 'unknown-error', exception: caught };
  }
};
