import { Box, useTheme } from '@material-ui/core';
import { AccountCircleOutlined, HelpOutline, PowerSettingsNew } from '@material-ui/icons';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useIdentityContext } from '../../../contexts/IdentityContext';
import { signOut } from '../../../support/amplify/sign-out';

const Item = styled(NavLink)`
  && {
    // color: white !important;
    text-transform: capitalize;
    text-decoration: none;
    font-family: acumin-pro-wide, sans-serif !important;
    transition: border 0.12s ease-in-out;
    position: relative;
    display: block;
    box-sizing: border-box;
    &:after {
      content: '';
      position: absolute;
      display: block;
      box-sizing: border-box;
      left: 0;
      width: 100%;
      bottom: 0;
      height: 6px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      transition: background-color 0.15s ease;
    }

    &:hover {
      &:after {
        background-color: white;
      }
    }
    &.active {
      &:after {
        background-color: rgba(229, 18, 110, 1);
      }
    }
  }
`;

interface IMenuItem {
  isDropdown?: boolean;
  link: string;
  label?: any;
}

const MenuItem = ({ menuItem }: { menuItem: IMenuItem }) => {
  const theme = useTheme();
  return (
    <Item style={{ padding: '20px 10px', color: theme.palette.navigation.dark }} to={menuItem.link}>
      {menuItem.label}
    </Item>
  );
};

const LogoutMenuItem = () => {
  const theme = useTheme();
  const { setIsLoggedIn } = useIdentityContext();

  const handleLogout = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    try {
      await signOut();
      setIsLoggedIn(false);

      window.location.href = '/login';
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Item style={{ padding: '20px 10px', color: theme.palette.navigation.dark }} onClick={handleLogout} to={'logout'}>
      <PowerSettingsNew />
    </Item>
  );
};

const menu: IMenuItem[] = [
  {
    isDropdown: true,
    link: '/search',
    label: 'Radar'
  },
  {
    link: '/resources',
    label: 'Reports'
  },
  {
    link: '/insights',
    label: 'Insights'
  },
  /*    {
        link: "/swift",
        label: "Swift Assist",
    },
    {
        link: "/blog",
        label: "Insight Sessions",
    },*/
  {
    link: '/help' /*@TODO*/,
    label: <HelpOutline />
  },
  {
    link: '/my-account',
    label: <AccountCircleOutlined />
  }
];

export interface MenuProps { }

export const Menu: React.FC<MenuProps> = () => {
  return (
    <Box display="flex" justifyContent="space-between">
      {menu.map((menuItem) => (
        <MenuItem key={menuItem.link} menuItem={menuItem} />
      ))}
      <LogoutMenuItem />
    </Box>
  );
};

export default Menu;
