import { Box, Button, FormControlLabel, Paper, Radio, withStyles } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import { uniqBy } from 'lodash';
import { FunctionComponent, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useCriteria } from '../../contexts/Search/Criteria';
import { useSearchQuery } from '../../contexts/Search/Query';
import { Modal } from '../Modal/Modal';

type SortByModalProps = {
  onClose: () => void;
  onTriggerSearch: () => void;
};

export const SortByModal: FunctionComponent<SortByModalProps> = ({ onClose, onTriggerSearch }) => {
  const searchCriteria = useCriteria();
  const searchQuery = useSearchQuery();

  const questions = useMemo(() => {
    return uniqBy(
      searchCriteria.state.questions.questions.filter((q) => q.has_friction_scores),
      'id'
    );
  }, [searchCriteria.state.questions.questions]);

  const [selectedQuestionId, setSelectedQuestionId] = useState(() => {
    return searchQuery.state.sort.friction_question;
  });

  const [selectedQuestionText, setSelectedQuestionText] = useState(() => {
    return searchQuery.state.sort.friction_question_text;
  });

  const onApplyHandler = async () => {
    const option = {
      label: 'Friction Score',
      field: 'FRICTION',
      direction: 'ASC',
      friction_question: selectedQuestionId,
      friction_question_text: selectedQuestionText
    };

    await searchQuery.dispatch({
      type: 'SET_SORT_QUERY_STATE',
      payload: { value: option }
    });

    console.log('option', searchQuery.state);

    onTriggerSearch();

    onClose();
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      closeable={true}
      fullHeight={false}
      rounded
      title={'Sort By Friction Score'}
      paddingX={0}
      paddingY={0}
    >
      <Box marginX={4} marginY={2}>
        <Paper style={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Box marginX={4} marginY={2}>
            <Box display={'flex'} flexDirection={'column'}>
              {questions.map((q, index) => (
                <FormControlStyles
                  key={index}
                  label={q.text}
                  control={
                    <RadioInput
                      checked={selectedQuestionId === q.id}
                      size="small"
                      value={q.id}
                      onChange={(e) => {
                        setSelectedQuestionId(Number(e.target.value));
                        setSelectedQuestionText(q.text);
                      }}
                    />
                  }
                />
              ))}
            </Box>
            <Box marginY={2} display={'flex'} justifyContent={'center'} justifyItems={'center'} alignItems={'center'}>
              <Button
                color={'secondary'}
                variant={'contained'}
                onClick={onApplyHandler}
                style={{ boxShadow: 'none' }}
                disabled={false}
              >
                Apply
                {/*{isApplying ? 'Applying ... ' : 'Apply'}*/}
              </Button>
              <Button
                color={'secondary'}
                variant={'text'}
                onClick={onClose}
                style={{ marginLeft: '20px', boxShadow: 'none' }}
                disabled={false}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

const FormControlStyles = styled(FormControlLabel)`
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #24ab3b;
  }
  .MuiFormControlLabel-label {
    white-space: nowrap;
  }
  && {
    color: #68788b;
    white-space: nowrap;
  }
`;

const RadioInput = withStyles({
  root: {
    paddingTop: '3px',
    paddingBottom: '4px',
    color: '#8B96A4',
    '&$checked': {
      color: '#24AB3B'
    }
  },
  checked: {}
})((props: CheckboxProps) => {
  return <Radio {...props} />;
});
