import { Box, Button, Container, Paper, TextField, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorBox } from '../../components/Auth/ErrorBox';
import { ResendVerificationButton } from '../../components/Auth/ResendVerificationButton';
import { Page } from '../../components/Layout/Page';
import { forgotPassword } from '../../support/amplify/forgot-password';
import { resendVerificationCode } from '../../support/amplify/resend-verification-code';
import { AmplifyResponses } from '../../types/Amplify';
import { UNEXPECTED_ERROR_MESSAGE } from '../../helpers/messages';

export const ForgotPassword = () => {
  const navigate = useNavigate();

  const [processing, setProcessing] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<AmplifyResponses | undefined>();
  const [error, setError] = useState<string | undefined>(undefined);
  const [input, setInput] = useState({
    username: '',
    code: ''
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const form = {
      ...input,
      [event.target.name]: event.target.value
    };

    setInput(form);
  };

  const handleResendVerificationCode = async () => {
    setProcessing(true);

    const resendVerificationCodeResult = await resendVerificationCode(input.username);

    switch (resendVerificationCodeResult.type) {
      case 'success':
        navigate('/confirm', { state: { username: input.username } });
        break;
      case 'user-not-found':
        setErrorType(resendVerificationCodeResult.type);
        setError(resendVerificationCodeResult.error);
        break;
      case 'unknown-error':
        setErrorType(resendVerificationCodeResult.type);
        setError(UNEXPECTED_ERROR_MESSAGE);
        break;
    }

    setProcessing(false);
  };

  const handleSubmit = async () => {
    setProcessing(true);

    const forgotPasswordResult = await forgotPassword(input.username);

    switch (forgotPasswordResult.type) {
      case 'success':
        navigate('/forgot-password/confirm', { state: { username: input.username } });
        break;
      case 'user-not-confirmed':
      case 'network-error':
        setErrorType(forgotPasswordResult.type);
        setError(forgotPasswordResult.error);
        break;
      case 'unknown-error':
        setError(UNEXPECTED_ERROR_MESSAGE);
        break;
      case 'limit-exceeded':
        setError(forgotPasswordResult.error);
        break;
      default:
        setError(UNEXPECTED_ERROR_MESSAGE);
    }

    setProcessing(false);
  };

  return (
    <Page>
      <Container>
        <Box marginTop={4}>
          <Button
            variant="contained"
            color="default"
            style={{
              backgroundColor: 'white',
              fontSize: '14px',
              borderRadius: '20px',
              color: '#647386'
            }}
            startIcon={<ArrowBack />}
            onClick={() => navigate('/login')}
          >
            Back
          </Button>
        </Box>
        <Container maxWidth={'sm'}>
          <Box display={'flex'} flexDirection={'column'} maxWidth={'md'}>
            <Box marginBottom={4}>
              <Paper>
                <Box padding={4}>
                  <Typography variant={'h1'}>Forgot Password</Typography>
                  {error && !['user-not-confirmed'].includes(errorType ?? '') && <ErrorBox message={error} />}

                  {error && errorType === 'user-not-confirmed' && (
                    <ErrorBox>
                      {error}

                      <div>
                        <ResendVerificationButton onClick={handleResendVerificationCode} />
                      </div>
                    </ErrorBox>
                  )}
                  <Box marginY={2}>
                    <Typography
                      style={{
                        fontSize: '14px',
                        color: '#647386',
                        fontWeight: 600
                      }}
                      variant={'subtitle2'}
                    >
                      Email Address
                    </Typography>
                    <TextField
                      style={{
                        marginTop: '4px'
                      }}
                      onChange={handleChange}
                      fullWidth={true}
                      size={'small'}
                      variant={'outlined'}
                      required={true}
                      type={'email'}
                      name={'username'}
                      id="username"
                    />
                  </Box>
                  <Box marginY={2}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                      <Button color={'secondary'} variant={'contained'} onClick={handleSubmit} disabled={processing}>
                        Reset Password
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Container>
      </Container>
    </Page>
  );
};

export default ForgotPassword;
