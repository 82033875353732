import { EntryCollection } from 'contentful';
import { clientFactory } from '../client';

type ContentfulEntryCollectionSuccess<T> = {
  type: 'success';
  response: EntryCollection<T>;
  pagination: {
    limit: number;
    total: number;
    skip: number;
  };
};

type ContentfulEntryCollectionNoRecords = {
  type: 'no-records';
};

type ContentfulEntryCollectionFailure = {
  type: 'failure';
  errors: [];
};

export type ContentfulEntryCollectionResult<T> =
  | ContentfulEntryCollectionSuccess<T>
  | ContentfulEntryCollectionNoRecords
  | ContentfulEntryCollectionFailure;

/**
 * Handles fetching the entry collection from contentful's sdk for the given query.
 * @template T
 *
 * @param {string} contentType
 * @param {Record<string, string | number | boolean>} query
 *
 * @returns {Promise<ContentfulEntryCollectionResult<T>>}
 */
export const getEntryCollection = async <T>(
  contentType: string,
  query?: Record<string, string | number | boolean>
): Promise<ContentfulEntryCollectionResult<T>> => {
  try {
    const client = await clientFactory();
    console.log('Clientfactory ', client);
    const response = await client.getEntries<T>({
      content_type: contentType,
      ...query,
      include: 5
    });

    if (response.items.length === 0) {
      return { type: 'no-records' };
    }

    return {
      type: 'success',
      response,
      pagination: {
        limit: response.limit,
        total: response.total,
        skip: response.skip
      }
    };
  } catch (caught) {
    console.log('Failed getting Entry Collection', caught);
    return { type: 'failure', errors: [] };
  }
};
