import { useApolloClient } from '@apollo/client';
import { Box, CircularProgress, Paper, Table, TableBody, TableRow, Typography } from '@material-ui/core';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { ChannelBadge } from '../../../components/Common/ChannelBadge';
import { FrictionScore } from '../../../components/Common/FrictionScore';
import { SecureChannelBadge } from '../../../components/Common/SecureChannelBadge';
import { SegmentBadge } from '../../../components/Common/SegmentBadge';
import { WeightBadge } from '../../../components/Common/WeightBadge';
import Score from '../../../components/Score';
import { useCriteria } from '../../../contexts/Search/Criteria';
import { useSearchResults } from '../../../contexts/Search/Results';
import { useJourneys } from '../../../hooks/journeys';
import { useQuestions } from '../../../hooks/questions';
import { getFrictionScores } from '../../../support/graphql/queries';
import {
  CollapseIcon,
  DataRow,
  ExpandIcon,
  Loader,
  ScoreContainer,
  TableCellStyles,
  TableHeaderCell,
  TableHeaderStyles,
  useRowStyles
} from './List.styled';
import { ChannelJourney, ChannelScore } from '../../../declarations/ChannelScore';
import { JourneyCriterion } from '../../../declarations/Criterion';

type TableJourneyGroupRowProps = {
  single: boolean;
  journeyScore: ChannelJourney;
  frictionScore?: FrictionScore[];
  usesVoc: boolean;
};

const TableJourneyGroupRow: FunctionComponent<TableJourneyGroupRowProps> = (props) => {
  const { score, cx_score, answers, secure } = props.journeyScore;
  const { getWeight } = useQuestions();
  const [open, setOpen] = useState<boolean>(false);

  /*
    Sort answers by secure channel
    Order should be Authenticated, Either, Public
    */
  const sorted_answers = answers.sort(function (_a, b) {
    if (b.question.secure_channel === 2) return 1;
    if (b.question.secure_channel === 1) return -1;
    else return 0;
  });

  return (
    <>
      <DataRow key={props.journeyScore.key} onClick={() => setOpen(!open)}>
        {!props.single && <TableCellStyles />}
        <TableCellStyles colSpan={props.single ? 1 : 3}>
          <Box display="flex" alignItems="center">
            <Box marginRight={1} display="flex" alignItems="center">
              {open ? <CollapseIcon /> : <ExpandIcon />}
            </Box>
            <Box display="flex" alignItems={'center'} justifyContent={'center'}>
              <Typography variant={'body1'} style={{ color: '#001433' }}>
                {props.journeyScore.name ? props.journeyScore.name : 'Unknown Journey'}
              </Typography>
            </Box>
          </Box>
        </TableCellStyles>
        {props.usesVoc &&
          <TableCellStyles>
            <ScoreContainer>
              <Score score={cx_score} />
            </ScoreContainer>
          </TableCellStyles>
        }
        <TableCellStyles>
          <ScoreContainer>
            <Score score={score} />
          </ScoreContainer>
        </TableCellStyles>
        <TableCellStyles>&nbsp;</TableCellStyles>
      </DataRow>
      {open &&
        sorted_answers.map((answer, index) => {
          const weights = getWeight(
            answer.question.id,
            answer.question.journey as unknown as number,
            answer.question.segment,
            secure ? 2 : 1
          );
          console.log(answer);

          const score = props.frictionScore?.find((s) => s.question === answer.question.id);

          return (
            <TableRow key={index}>
              {!props.single && <TableCellStyles />}
              <TableCellStyles colSpan={props.single ? 1 : 3}>
                <Box display="flex" alignItems="center">
                  <Box marginLeft={2} marginRight={1} display="flex" alignItems="center">
                    <FrictionScore.Badge score={score} value={answer.value} />
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between" flexGrow={1}>
                    <Typography style={{ flexGrow: 1 }} variant={'body1'}>
                      {answer.question.text}
                    </Typography>
                    <SecureChannelBadge secure={answer.question.secure_channel} />
                  </Box>
                </Box>
              </TableCellStyles>
              <TableCellStyles>&nbsp;</TableCellStyles>
              <TableCellStyles>&nbsp;</TableCellStyles>
              {props.usesVoc &&
                <TableCellStyles width={150}>
                  {weights[0] && (
                    <WeightBadge
                      key={`${answer.question.id}#${weights[0].segment}`}
                      text={answer.question.text}
                      question={answer.question.id}
                      segment={weights[0].segment}
                      weight={weights[0].weight}
                      secure={true}
                    />
                  )}
                </TableCellStyles>
              }
            </TableRow>
          );
        })}
    </>
  );
};

type TableJourneyGroupProps = {
  single: boolean;
  record: ChannelScore;
  criteria: { secureJourneys: JourneyCriterion[]; nonSecureJourneys: JourneyCriterion[] };
  frictionData: FrictionData[];
  usesVoc: boolean;
};

const TableJourneyGroup: FunctionComponent<TableJourneyGroupProps> = (props) => {
  const { getChannelJourneys } = useJourneys();
  // const [journeys, setJourneys] = useState<ChannelJourney[]>([])

  const channelFrictionData = useMemo(() => {
    const data = props.frictionData.find((f) => f.auditId === props.record.auditId);

    if (data) {
      return data.friction_scores;
    }

    return undefined;
  }, [props.frictionData, props.record]);

  const journeys = useMemo(
    () => getChannelJourneys(props.record, props.criteria.secureJourneys, props.criteria.nonSecureJourneys),

    [getChannelJourneys, props.record, props.criteria.secureJourneys, props.criteria.nonSecureJourneys]
  );

  return (
    <>
      {journeys.map((journeyScore, index) => {
        return (
          <TableJourneyGroupRow
            single={props.single}
            key={index}
            journeyScore={journeyScore}
            frictionScore={channelFrictionData}
            usesVoc={props.usesVoc}
          />
        );
      })}
    </>
  );
};

type TableProviderGroupRowProps = {
  record: ChannelScore;
  searchCriteria: { secureJourneys: JourneyCriterion[]; nonSecureJourneys: JourneyCriterion[] };
  frictionData: FrictionData[];
  usesVoc: boolean;
};

const TableProviderGroupRow: FunctionComponent<TableProviderGroupRowProps> = (props) => {
  const classes = useRowStyles();
  const [open, setOpen] = useState(false);

  const handleOpenState = () => {
    setOpen(!open);
  };

  return (
    <>
      <DataRow
        className={classes.root}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        // onMouseEnter={handlePopoverOpen}
        // onMouseLeave={handlePopoverClose}
        onClick={handleOpenState}
      >
        <TableCellStyles>
          <Box display="flex">
            <Box display="flex" alignItems="center" marginRight={1}>
              {open ? <CollapseIcon /> : <ExpandIcon />}
            </Box>
            <Box fontWeight="700" color="#001433">
              {props.record.providerName}
            </Box>
          </Box>
        </TableCellStyles>
        <TableCellStyles>
          <Typography variant={'h4'}>All</Typography>
        </TableCellStyles>
        <TableCellStyles>
          <ChannelBadge
            version={props.record.channel_version}
            channel={props.record.channel}
            channelName={props.record.channelName}
            providerName={props.record.providerName}
            auditDate={props.record.audit_date}
          />
        </TableCellStyles>
        <TableCellStyles>
          <SegmentBadge segment={props.record.segment} segmentName={props.record.segmentName} />
        </TableCellStyles>
        {props.usesVoc &&
          <TableCellStyles>
            <ScoreContainer>
              <Score score={props.record.cx_score} />
            </ScoreContainer>
          </TableCellStyles>
        }
        <TableCellStyles>
          <ScoreContainer>
            <Score score={props.record.score} />
          </ScoreContainer>
        </TableCellStyles>
        {props.usesVoc &&
          <TableCellStyles>&nbsp;</TableCellStyles>
        }
      </DataRow>

      {open && props.record && (
        <TableJourneyGroup
          single={false}
          record={props.record}
          criteria={props.searchCriteria}
          frictionData={props.frictionData}
          usesVoc={props.usesVoc}
        />
      )}
    </>
  );
};

type ListProps = {
  channelScores: ChannelScore[];
  single?: boolean;
  usesVoc: boolean;
};

export const List: FunctionComponent<ListProps> = (props) => {
  const searchCriteria = useCriteria();
  const searchResults = useSearchResults();
  const client = useApolloClient();

  const { single = false } = props;
  const [frictionData, setFrictionData] = useState<FrictionData[]>([]);
  const auditIds = useMemo(() => props.channelScores.map((c) => c.auditId), [props.channelScores]);

  const loadFrictionScores = useCallback(
    async () => {
      const data: FrictionData[] = await getFrictionScores(client, { auditIds });

      setFrictionData(data);
    }, [auditIds, client])

  useEffect(() => {
    loadFrictionScores();
  }, [props.channelScores, auditIds, loadFrictionScores]);

  if (searchResults.state.loading) {
    return <>Loading ...</>;
  }

  return (
    <Box>
      <Paper>
        <Box position="relative">
          {!!props.channelScores.length && (
            <Box>
              <Table>
                <TableHeaderStyles>
                  <TableRow>
                    {!single && <TableHeaderCell>Provider</TableHeaderCell>}
                    <TableHeaderCell style={{ width: '300px' }}>Categories</TableHeaderCell>
                    {!single && <TableHeaderCell>Channel</TableHeaderCell>}
                    {!single && <TableHeaderCell>Segment</TableHeaderCell>}
                    {props.usesVoc &&
                      <TableHeaderCell>VoC Score</TableHeaderCell>
                    }
                    <TableHeaderCell>Utility Score</TableHeaderCell>
                    {props.usesVoc &&
                      <TableHeaderCell>Weightings</TableHeaderCell>
                    }
                  </TableRow>
                </TableHeaderStyles>
                <TableBody>
                  {props.channelScores.map((record, index) => {
                    if (single) {
                      return (
                        <TableJourneyGroup
                          key={record.key}
                          single={true}
                          record={record}
                          criteria={{
                            secureJourneys: searchCriteria.state.secureJourneys,
                            nonSecureJourneys: searchCriteria.state.nonSecureJourneys
                          }}
                          frictionData={frictionData}
                          usesVoc={props.usesVoc}
                        />
                      );
                    }

                    return (
                      <TableProviderGroupRow
                        key={record.key}
                        record={record}
                        searchCriteria={{
                          secureJourneys: searchCriteria.state.secureJourneys,
                          nonSecureJourneys: searchCriteria.state.nonSecureJourneys
                        }}
                        frictionData={frictionData}
                        usesVoc={props.usesVoc}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          )}
          {searchResults.state.loading && (
            <Loader>
              <CircularProgress color="secondary" />
            </Loader>
          )}
        </Box>
      </Paper>
    </Box>
  );
};
