import { Grid, useTheme } from "@material-ui/core"
import { FunctionComponent } from "react"
import { ChannelScoreItem } from "../../../components/ChannelScoreItem/ChannelScoreItem"
import { Loading } from "../../../components/Common/Loading"
import NoResultsFound from "../../../components/NoResultsFound"
import { useChannel } from "../../../contexts/ChannelContext"
import { useSearchResults } from "../../../contexts/Search/Results"
import { TabChannelView } from "../../../types/Channel"
import { ChannelScore } from "../../../declarations/ChannelScore"

type ChannelsProps = {
    channelScores: ChannelScore[]
}

export const Channels: FunctionComponent<ChannelsProps> = (props) => {
    const channel = useChannel()
    const theme = useTheme()
    const searchResults = useSearchResults()

    const handleChannelOpen = async (channelScore: ChannelScore) => {
        channel.dispatch({
            type: "INITIALISE_CHANNEL_MODAL_STATE",
            payload: { channelScore, view: TabChannelView }
        })

        channel.dispatch({
            type: "TOGGLE_CHANNEL_MODAL_STATE",
            payload: { value: true }
        })
    }

    if (searchResults.state.loading) return <ChannelScoreLoading />

    return (
        <div style={{ paddingBottom: `${theme.spacing(4)}px` }}>

            {(props.channelScores.length === 0) && <NoResultsFound />}

            <Grid spacing={4} container >
                {props.channelScores.map((record) => (
                    <ChannelScoreItem
                        key={record.key}
                        handleClick={handleChannelOpen}
                        channelScore={record}
                    />
                ))}
            </Grid>
        </div>
    )
}

const ChannelScoreLoading = () => {
    const theme = useTheme()

    return (
        <div style={{ paddingBottom: `${theme.spacing(4)}px` }}>
            <Grid container spacing={4}>
                {
                    [...Array(12).fill(1).map((v, i) => (
                        <Grid key={i} item xs={1} sm={2} md={4}>
                            <Loading.Panel width={"100%"} height={464} />
                        </Grid>
                    ))]
                }
            </Grid>
        </div>
    )
}
