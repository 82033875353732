
const secDomainToSting = (domain: number): string => {
    switch (domain) {
        case 1:
            return 'Public';
        case 2:
            return 'Authenticated';
        case 3:
            return 'Either';
        default:
            return 'Unknown';
    }
}

export default secDomainToSting 