import { makeStyles, Paper } from '@material-ui/core'
import styled from 'styled-components'

export const CellElement = styled.div`
  && {
    height: 100%;
    display: flex;
    align-items: center;
    min-height: 70px;
  }
`;

export const Container = styled.div`
  && {
    display: flex;
    flex-flow: row-nowrap;
    overflow: scroll;
    min-height: 100vh;
    max-height: 100vh;
    position: static;
    & > div {
      height: 100%;
      position: relative;
      backface-visibility: hidden;
    }
  }
`;

export const ExpandableArea = styled.div`
  && {
    border-top: 1px solid #d9dbe0;
    min-width: 500px;
    div.oddly:nth-of-type(even) {
      background-color: #f6f8fa;
    }
  }
`;

export const Columns = styled.div`
  && {
    display: flex;
    flex-flow: row;
    background-color: #fff;
    border-right: 1px solid #d9dbe0;
  }
`;

export const Row = styled.div`
  && {
    position: static;
    display: flex;
    flex-flow: column;
    min-width: 132px;
    & > div {
      border-left: 1px solid #d9dbe0;
    }
    div.oddly {
      & > div:nth-of-type(odd) {
        background-color: #f6f8fa;
      }
    }
  }
`;

export const Group = styled.div`
  && {
    width: 100%;
    position: sticky !important;
    backface-visibility: hidden;
    z-index: 4;
    left: 0;
    top: 0;
  }
`;

export const Sticker = styled.div`
  && {
    top: 0;
    left: 0;
    min-height: 200px;
    color: white;
    display: flex;
    align-items: center;
    position: sticky !important;
    backface-visibility: hidden;
    z-index: 3;
  }
`;

export const Cell = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Question = styled.div`
  && {
    align-items: center;
    display: block;
    min-width: 200px;
    margin-left: 15px;
  }
`;

export const OverPaper = styled(Paper)`
  margin-top: 20px;
  && {
    & > div {
      overflow: scroll;
    }
  }
`;

export const useStyles = makeStyles(() => ({
  radio: {
    paddingTop: '2px',
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  }
}));
