import React, {FunctionComponent} from "react";
import {Button} from "@material-ui/core";

type ResetPasswordButtonProps = {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const ResetPasswordButton: FunctionComponent<ResetPasswordButtonProps> = (props) => {
    return (
        <Button
            size="small"
            variant="text"
            color="primary"
            style={{
                boxShadow: "none",
                textDecoration: "underline",
            }}
            onClick={props.onClick}
        >
            Reset Password
        </Button>
    )
}
