import { createContext, PropsWithChildren, useContext, useReducer } from 'react'

type ToggleExpandedState = { type: 'TOGGLE_EXPANDED_STATE', payload: { name: string } }
type ExpandAllExpandedState = { type: 'EXPAND_ALL_EXPANDED_STATE', payload: {} }
type CollapseAllExpandedState = { type: 'COLLAPSE_ALL_EXPANDED_STATE', payload: {} }
type CheckItem = { type: 'CHECK_ITEM', payload: { name?: string } }

type Action = ToggleExpandedState | ExpandAllExpandedState | CollapseAllExpandedState | CheckItem

type Dispatch = (action: Action) => void

/*
 * if checkable, state goes from none to checked to expanded to none
 * otherwise none to expanded to none
 */

type State = {
    expanded: string[],
    checked: string[]
}

type ExpandableContextType = { state: State; dispatch: Dispatch } | undefined
const ExpandableContext = createContext<ExpandableContextType>(undefined)

type ExpandableProviderProps = {
    state: State
    names: string[]
    checkable: string[]
}

export const ExpandableProvider = (props: PropsWithChildren<ExpandableProviderProps>) => {

    const toggleExpandedState = (state: State, action: ToggleExpandedState): State => {
        const selected = action.payload.name
        const expanded = Array.from(state.expanded)
        const checked = Array.from(state.checked)
        // console.log("before", { selected, expanded, checked })

        if (expanded.includes(selected)) {
            // console.log(1)
            expanded.splice(expanded.findIndex((n) => n === selected), 1)
        } else if (state.checked.includes(selected)) {
            // console.log(2)
            checked.splice(checked.findIndex((n) => n === selected), 1)
            expanded.push(selected)
        } else {
            if (props.checkable.includes(selected)) {
                // console.log(3)
                checked.push(selected)
                expanded.splice(expanded.findIndex((n) => n === selected), 1)
            } else {
                // console.log(4)
                expanded.push(selected)
            }
        }
        // console.log("after", { selected, expanded, checked })

        return {
            expanded,
            checked
        }
    }

    const expandAllExpandedState = (state: State, action: ExpandAllExpandedState): State => {
        return {
            expanded: props.names,
            checked: [],
        }
    }

    const collapseAllExpandedState = (state: State, action: CollapseAllExpandedState): State => {
        return {
            expanded: [],
            checked: [],
        }
    }

    const checkItem = (state: State, action: CheckItem): State => {
        const item = action.payload.name
        return item
            ? { expanded: [], checked: [item] }
            : { expanded: state.expanded, checked: [] }
    }

    const reducer = (state: State, action: Action) => {
        if (action.type === 'TOGGLE_EXPANDED_STATE') {
            return toggleExpandedState(state, action)
        } else if (action.type === 'EXPAND_ALL_EXPANDED_STATE') {
            return expandAllExpandedState(state, action)
        } else if (action.type === 'COLLAPSE_ALL_EXPANDED_STATE') {
            return collapseAllExpandedState(state, action)
        } else if (action.type === 'CHECK_ITEM') {
            return checkItem(state, action)
        }

        throw new Error(`Unhandled action type in 'ExpandableContext'`)
    }


    // console.log("ExpandableProvider", props)
    const [state, dispatch] = useReducer(reducer, props.state)

    return (
        <ExpandableContext.Provider value={{ state, dispatch }}>
            <>
                {props.children}
            </>
        </ExpandableContext.Provider>
    )
}

export const useExpandable = () => {
    const context = useContext(ExpandableContext)

    if (context === undefined) {
        throw new Error('useExpandable must be used within a ExpandableProvider')
    }

    return context
}