import { Box } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'
import { getBrands } from '../../../../src/brands'
import { environment } from '../../../support/environment'

export interface HeaderLogoProps {
  type: 'app' | 'default';
}

export const HeaderLogo: React.FC<PropsWithChildren<HeaderLogoProps>> = ({ type }) => {
  const config = getBrands(environment.branding.name || '');
  return (
    <>
      <div id="radartop"></div>
      <Box style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        <img alt={`${environment.branding.name} Logo`} src={type === 'app' ? config.logos.app : config.logos.default} />
      </Box>
    </>
  );
};

export default HeaderLogo;
