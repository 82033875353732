import { ApolloClient, gql } from '@apollo/client';

/**
 * The response type for the api request.
 */
type GetSecurityKeys = {
  getApiKeys: { contentful: string; algolia: string };
};

/**
 * The query to use when running the request.
 */
const query = gql`
  query AllApiKeys {
    getApiKeys {
      algolia
      contentful
    }
  }
`;

/**
 * Gets the sectors from the api.
 *
 * @param {ApolloClient<object>} client
 *
 * @throws Error
 */
export const getSecurityKeys = async (client: ApolloClient<object>) => {
  try {
    const response = await client.query<GetSecurityKeys>({
      query
    });
    return response.data.getApiKeys;
  } catch (caught) {
    console.log('Error getting sec keys', caught);
    throw Error();
  }
};
