import { Box, Button, Typography } from '@material-ui/core';
import { HttpClient as HttpClientIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { useEffect, useMemo } from 'react';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { Authenticated } from './components/Layout/Authenticated';
import { BrandMismatch } from './components/Layout/BrandMismatch';
import { Guest } from './components/Layout/Guest';
import { PendingApproval } from './components/Layout/PendingApproval';
import { Unauthorised } from './components/Layout/Unauthorised';
import { useIdentityContext } from './contexts/IdentityContext';
import Revalidate, { REVALIDATION_INTERVAL, utcDaysDifference } from './pages/Auth/Revalidate';

/**
 * The app entry point.
 *
 * Here we handle the auth state of the user, check for approval and reject if required.
 *
 * The auth state dictates which Layout component is used.
 */
const App = () => {
  /**
   * Sentry configuration and initialisation.
   */
  const sentrySamplingRate = import.meta.env.REACT_APP_SENTRY_SAMPLING_RATE
    ? parseFloat(import.meta.env.REACT_APP_SENTRY_SAMPLING_RATE)
    : 0.1;

  const { loading, session, approvalStatus, group, brand, token, revalidateEmail } = useIdentityContext();

  const isAuthorized = !!(session && token && group);

  const isGuest = useMemo(() => {
    return (session && token) === null;
  }, [session, token]);

  const isPendingApproval = useMemo(() => {
    return (approvalStatus && approvalStatus === 'pending') === true;
  }, [approvalStatus]);

  const isDeniedApproval = useMemo(() => {
    return (approvalStatus && approvalStatus === 'denied') === true;
  }, [approvalStatus]);

  const isBrandMatched = useMemo(() => {
    return (brand !== null && brand === import.meta.env.REACT_APP_BRANDING) === true;
  }, [brand]);

  const allowDebugging = useMemo(() => {
    return import.meta.env.REACT_APP_DEBUGGING === 'true';
  }, []);

  const activeEnv = useMemo(() => {
    return import.meta.env.REACT_APP_ENVIRONMENT ? import.meta.env.REACT_APP_ENVIRONMENT : 'Unknown environment';
  }, []);

  const requiresRevalidation = useMemo(() => {
    // console.log("checking to see if email address needs to be revalidated")
    if (!isAuthorized) {
      // console.log("  no user present")
      return false
    }
    if (revalidateEmail !== "true") {
      // console.log("  customer not flagged for revalidation")
      return false
    }
    const daysAgo = utcDaysDifference(
      new Date(session?.attributes['custom:LastRevalidationDate'] || '2000-01-01'),
      new Date()
    )
    // console.log(`  revalidated ${daysAgo} days ago (limit = ${REVALIDATION_INTERVAL})`)
    return (daysAgo >= REVALIDATION_INTERVAL)
  }, [isAuthorized, revalidateEmail, session?.attributes])

  //DISABLED UNTIL LEGAL GREENLIGHT
  useEffect(() => {
    if (allowDebugging) {
      // ==== Sentry ====
      Sentry.init({
        dsn: 'https://5131bb6e791242659827f2e0f8997c4b@o4505245019078656.ingest.sentry.io/4505245025173504',
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes
            )
          }),
          new HttpClientIntegration({
            // This array can contain tuples of `[begin, end]` (both inclusive),
            // single status codes, or a combination of both.
            // default: [[500, 599]]
            failedRequestStatusCodes: [[500, 599]]
          })
        ],
        // What environment is this?
        environment: activeEnv,
        // Performance Monitoring
        tracesSampleRate: sentrySamplingRate,
        // Session Replay
        replaysOnErrorSampleRate: sentrySamplingRate
      });
    }
    // hotjar.initialize(3310576, 6)
  }, [activeEnv, allowDebugging, sentrySamplingRate]);

  if (loading) return null;

  if (isAuthorized && !isBrandMatched) {
    return (
      <Sentry.ErrorBoundary fallback={ErrorInfoPage}>
        <BrowserRouter>
          <BrandMismatch />
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    );
  }

  if (isAuthorized && isPendingApproval) {
    return (
      <Sentry.ErrorBoundary fallback={ErrorInfoPage}>
        <BrowserRouter>
          <PendingApproval />
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    );
  }

  if (isAuthorized && isDeniedApproval) {
    return (
      <Sentry.ErrorBoundary fallback={ErrorInfoPage}>
        <BrowserRouter>
          <Unauthorised />
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    );
  }

  if (isAuthorized && requiresRevalidation) {
    return (
      <Sentry.ErrorBoundary fallback={ErrorInfoPage}>
        <BrowserRouter>
          <Revalidate />
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    );
  }

  if (!isAuthorized && !isGuest) {
    return (
      <Sentry.ErrorBoundary fallback={ErrorInfoPage}>
        <BrowserRouter>
          <Unauthorised />
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    );
  }

  const Layout = isGuest ? Guest : Authenticated;

  return (
    <>
      <Sentry.ErrorBoundary fallback={ErrorInfoPage}>
        <BrowserRouter>
          <Layout />
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </>
  );
};

const ErrorInfoPage = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height={'100vh'}
      style={{ alignContent: 'center', alignItems: 'center', backgroundColor: '#fff', padding: '2em' }}
    >
      <Typography variant="h1">Oh, dear!</Typography>
      <Typography variant="h6" align="center">
        It appears that something unexpected has gone wrong with our website.
      </Typography>
      <Typography variant="h6" align="center">
        Our team has been notified and will get this resolved as soon as possible! <br />
        <br />
        Reload the page and you it should be good to go
      </Typography>
      <Button
        onClick={() => window.location.reload()}
        variant="contained"
        size="large"
        color="secondary"
        style={{ marginTop: '1em' }}
      >
        Reload
      </Button>
      <img src="assets/images/servererror.jpg" height="70%" style={{}} alt="Help! Something broke" />
    </Box>
  );
};

export default App;
