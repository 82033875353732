import {
  BusinessBanking2021Sector,
  BusinessBanking2023Sector,
  LifeInsurance2021Sector,
  LifeInsurance2023Sector,
  PersonalBanking2021Sector,
  PersonalBanking2023Sector,
  MerchantServices2023Sector,
  Sectors
} from './Sectors';

export type IndustryName = 'Personal Banking' | 'Commercial Banking' | 'Life Insurance' | 'Merchant Services';

/**
 * Some calls does not need an end date, so we use this constant to indicate that.
 */
export const EMPTY_END_DATE = '';

export type Cycle = {
  cycleId: number;
  cycleName: string;
  cycleEndDate: string | null;
  linkedSector: Sectors;
};

/*
 * Note as of April 2024, the industry Ids here correspond to the values in the database, even though that table is not 
 * exposed through the API. However, the drop-down list is sorted on id whereas the DB contains a separate displaySeq column
 */
export type Industry = {
  industryId: number;
  industryName: IndustryName;
  usesVoc: boolean;
  cycles: Cycle[];
};

export const Industries: Industry[] = [
  {
    industryId: 1,
    industryName: 'Personal Banking',
    usesVoc: true,
    cycles: [
      {
        cycleId: 1999,
        cycleName: 'Current',
        cycleEndDate: null,
        linkedSector: PersonalBanking2023Sector
      },
      {
        cycleId: 1007,
        cycleName: 'January 2024',
        cycleEndDate: '2024-01-31',
        linkedSector: PersonalBanking2023Sector
      },
      {
        cycleId: 1006,
        cycleName: 'September 2023',
        cycleEndDate: '2023-09-30',
        linkedSector: PersonalBanking2023Sector
      },
      {
        cycleId: 1005,
        cycleName: 'May 2023',
        cycleEndDate: '2023-05-31',
        linkedSector: PersonalBanking2021Sector
      },
      {
        cycleId: 1004,
        cycleName: 'January 2023',
        cycleEndDate: '2023-01-31',
        linkedSector: PersonalBanking2021Sector
      },
      {
        cycleId: 1003,
        cycleName: 'September 2022',
        cycleEndDate: '2022-09-30',
        linkedSector: PersonalBanking2021Sector
      },
      {
        cycleId: 1002,
        cycleName: 'May 2022',
        cycleEndDate: '2022-05-31',
        linkedSector: PersonalBanking2021Sector
      },
      {
        cycleId: 1001,
        cycleName: 'January 2022',
        cycleEndDate: '2022-01-31',
        linkedSector: PersonalBanking2021Sector
      }
    ]
  },
  // COMMERCIAL BANKING
  // ==================
  {
    industryName: 'Commercial Banking',
    usesVoc: true,
    industryId: 2,
    cycles: [
      {
        cycleId: 2999,
        cycleName: 'Current',
        cycleEndDate: null,
        linkedSector: BusinessBanking2023Sector
      },
      {
        cycleId: 2008,
        cycleName: 'March 2024',
        cycleEndDate: '2024-03-31',
        linkedSector: BusinessBanking2023Sector
      },
      {
        cycleId: 2007,
        cycleName: 'November 2023',
        cycleEndDate: '2023-11-30',
        linkedSector: BusinessBanking2023Sector
      },
      {
        cycleId: 2006,
        cycleName: 'July 2023',
        cycleEndDate: '2023-07-31',
        linkedSector: BusinessBanking2023Sector
      },
      {
        cycleId: 2005,
        cycleName: 'March 2023',
        cycleEndDate: '2023-03-31',
        linkedSector: BusinessBanking2021Sector
      },
      {
        cycleId: 2004,
        cycleName: 'November 2022',
        cycleEndDate: '2022-11-30',
        linkedSector: BusinessBanking2021Sector
      },
      {
        cycleId: 2003,
        cycleName: 'July 2022',
        cycleEndDate: '2022-07-31',
        linkedSector: BusinessBanking2021Sector
      },
      {
        cycleId: 2002,
        cycleName: 'March 2022',
        cycleEndDate: '2022-03-31',
        linkedSector: BusinessBanking2021Sector
      },
      {
        cycleId: 2001,
        cycleName: 'November 2021',
        cycleEndDate: '2021-11-30',
        linkedSector: BusinessBanking2021Sector
      }
    ]
  },
  // Life Insurance
  // ==============
  {
    industryName: 'Life Insurance',
    usesVoc: true,
    industryId: 3,
    cycles: [
      {
        cycleId: 3999,
        cycleName: 'Current',
        cycleEndDate: null,
        linkedSector: LifeInsurance2023Sector
      },
      {
        cycleId: 3007,
        cycleName: 'December 2023',
        cycleEndDate: '2023-12-31',
        linkedSector: LifeInsurance2023Sector
      },
      {
        cycleId: 3006,
        cycleName: 'August 2023',
        cycleEndDate: '2023-08-31',
        linkedSector: LifeInsurance2023Sector
      },
      {
        cycleId: 3005,
        cycleName: 'April 2023',
        cycleEndDate: '2023-04-30',
        linkedSector: LifeInsurance2023Sector
      },
      {
        cycleId: 3004,
        cycleName: 'December 2022',
        cycleEndDate: '2022-12-31',
        linkedSector: LifeInsurance2021Sector
      },
      {
        cycleId: 3003,
        cycleName: 'August 2022',
        cycleEndDate: '2022-08-22',
        linkedSector: LifeInsurance2021Sector
      },
      {
        cycleId: 3002,
        cycleName: 'April 2022',
        cycleEndDate: '2022-04-30',
        linkedSector: LifeInsurance2021Sector
      },
      {
        cycleId: 3001,
        cycleName: 'December 2021',
        cycleEndDate: '2021-12-31',
        linkedSector: LifeInsurance2021Sector
      }
    ]
  },
  // Merchant Services
  // ==============
  {
    industryName: 'Merchant Services',
    usesVoc: false,
    industryId: 5,
    cycles: [
      {
        cycleId: 4999,
        cycleName: 'Current',
        cycleEndDate: null,
        linkedSector: MerchantServices2023Sector
      },
      {
        cycleId: 4002,
        cycleName: 'March 2024',
        cycleEndDate: '2024-03-31',
        linkedSector: MerchantServices2023Sector
      },
      {
        cycleId: 4001,
        cycleName: 'November 2023',
        cycleEndDate: '2023-11-30',
        linkedSector: MerchantServices2023Sector
      },
    ]
  }
];
