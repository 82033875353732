import React from "react";

export interface ScoreProps {
  score: number;
}

const Score: React.FC<ScoreProps> = ({ score }) => {
  return (
    <div style={{ display: "block",border: "none", width: "44px", maxWidth: "100%", position: "relative", boxSizing: "border-box", margin: "0 auto"}}>
      <div style={{
          margin:"auto",
          fontSize:"14px",
          color:"#001433",
          fontWeight: 800,
          width:"100%",
          display:"flex",
          alignItems:"center",
          justifyContent:"center",
          overflow: "hidden"
      }}>
          <div style={{ position: "relative", textAlign: "center", boxSizing: "border-box", lineHeight: "44px", zIndex: 2, fontWeight: 700, letterSpacing: -.2}}>{score}</div>
      </div>
      <svg viewBox="0 0 42 42" width={"42"} height={"42"} style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          width: "118.95%",
          height: "118.95%",
          zIndex: 1,
          transform: "translate3d(-50%,-50%,0)",
          transformOrigin: "center"
      }}>
        <circle cx="21" cy="21" r="15.91549430918954" fill="#fff"/>
        <circle
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke="#ECEDEF"
          strokeWidth="3"
        />
        <circle
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke="#E5126E"
          strokeWidth="3"
          strokeDasharray={`${score} ${100 - score}`}
          strokeDashoffset="25"
        />
      </svg>
    </div>
  );
};

export default Score;
