import Routes from '../../Routes';
import Layout from './Layout';

export const Guest = () => {
  return (
    <>
      <Layout>
        <Routes />
      </Layout>
    </>
  );
};
