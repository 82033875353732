import { Container } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import Header from "../../components/Header/Header"
import { Footer } from "./Footer"

type LayoutProps = {
    children: React.ReactElement;
}

const LayoutContainer = styled(Container)`
    && {
      background: #F6F8FA;
      height: 100%;
      position: relative;
    }
`;

const Layout = (props: LayoutProps) => {
    return (
        <LayoutContainer maxWidth={false} disableGutters={true}>
            <Header/>
            {props.children}
            <Footer/>
        </LayoutContainer>
    );
};

export default Layout;
