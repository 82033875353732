import { useAdvancedJourneyContext } from "../../contexts/AdvancedJourneyContext";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { DropdownContainerStyles } from "../Search/Filter/Dropdown.styled";
import { FilterText } from "../Typography";
import { ChevronDown } from "../Common/icons";
import { useCriteria } from "../../contexts/Search/Criteria";
import { useSearchQuery } from "../../contexts/Search/Query";
import styled from 'styled-components'
import { uniqBy } from "lodash";
import { useMemo } from "react";

export const AdvancedJourneyTrigger = () => {
    const { state: { loading, questions: criteriaQuestions } } = useCriteria();
    const advancedJourneyContext = useAdvancedJourneyContext()
    const { state: { questions: queryQuestions } } = useSearchQuery();
    const label = "Functions"

    const count = useMemo(() =>
        // if they have no questions selected in the query, use all questions from the criteria
        queryQuestions.questionIds.length || uniqBy(criteriaQuestions.questions, 'id').length,
        [criteriaQuestions.questions, queryQuestions.questionIds.length]
    )

    const handleClick = () => {
        advancedJourneyContext.setOpen(true)
    }

    // note this component copies the styling from <Dropdown> 
    // but it's not actually a dropdown
    return (
        <Box data-cy={`dropdown-${label}`} flexGrow={1} key={label} padding={1}>
            <Box onClick={handleClick}>
                <DropdownContainerStyles
                    display="flex"
                    justifyContent="space-between"
                    justifyItems={'center'}
                    disabled={loading}
                >
                    <Box display={'flex'}>
                        <FilterText>{label}</FilterText>
                    </Box>
                    <Box display={'flex'} justifyContent={'space-between'} justifyItems={'center'}>
                        {!loading
                            ? <Box
                                bgcolor="#F6F8FA"
                                minWidth="22.5px"
                                maxWidth="60px"
                                height="22.5px"
                                borderRadius="6px"
                                display="flex"
                                paddingX={'6px'}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <CounterTextStyles>
                                    {queryQuestions.type === 'AND' ? 'ALL' : 'ANY'} {count}
                                </CounterTextStyles>
                            </Box>

                            : <CircularProgress color={'secondary'} size={'1rem'} />
                        }
                        <Box width={'8px'} height={'8px'}>
                            <ChevronDown
                                viewBox={'0,0,8,8'}
                                style={{
                                    width: '8px',
                                    height: '8px',
                                    marginLeft: '5px'
                                }}
                            />
                        </Box>
                    </Box>
                </DropdownContainerStyles>
            </Box>
        </Box>
    )
}


const CounterTextStyles = styled(Typography)`
  && {
    color: #647386;
    font-size: 0.8em !important;
    display: flex;
    word-break: nowrap;
  }
`;
