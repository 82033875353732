import { useApolloClient } from '@apollo/client';
import { Box, Container, Divider, Grid, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FunctionComponent, MouseEvent, useEffect, useState } from 'react';
import { Loading } from '../../components/Common/Loading';
import { ResourceIcon } from '../../components/Common/ResourceIcon';
import { Page } from '../../components/Layout/Page';
import { useFile } from '../../hooks/file';
import { useResources } from '../../hooks/resources';
import getFormattedDate from '../../support/date/getFormattedDate';
import { useStyles } from './Resources.styled';

export const Resources = () => {
  const client = useApolloClient();

  const { getResources } = useResources();

  const [status, setStatus] = useState<string>('idle');
  const [resources, setResources] = useState<any[]>([]);

  useEffect(() => {
    setStatus('pending');

    const fetchResources = async () => {
      const response = await getResources(client);
      setResources(response);
      setStatus('resolved');
    };

    fetchResources();
  }, [client, getResources]);

  return (
    <Page>
      <Container>
        <Box marginTop={4} marginBottom={2}>
          <Grid container spacing={4} justify="space-between" alignItems="center">
            <Grid item xs={12}>
              <Typography variant={'h1'}>Reports</Typography>
            </Grid>
          </Grid>
        </Box>

        <Divider orientation="horizontal" style={{ marginLeft: '4px', marginRight: '4px' }} />

        {status === 'pending' && (
          <Box marginTop={2}>
            <Loading.Panel width={'100%'} height={464} />
          </Box>
        )}

        {status === 'resolved' && <ResourceTable resources={resources} />}
      </Container>
    </Page>
  );
};

type ResourceTableProps = {
  resources: any[];
};

const ResourceTable: FunctionComponent<ResourceTableProps> = ({ resources }) => {
  const classes = useStyles();
  const client = useApolloClient();

  const { downloadResource } = useResources();
  const { getFileSize, getFileType } = useFile();

  const onDownloadHandler = async (event: MouseEvent, resourceId: number) => {
    event.preventDefault();

    downloadResource(client, resourceId);
  };

  return (
    <Box marginTop={2} marginBottom={2}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.header}>Title</TableCell>
              <TableCell className={classes.header}>Uploaded</TableCell>
              <TableCell className={classes.header}>Expires On</TableCell>
              <TableCell className={classes.header}>File Size</TableCell>
              <TableCell className={classes.header}>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resources.map((resource) => {
              return (
                <TableRow
                  key={resource.id}
                  onClick={(e) => onDownloadHandler(e, resource.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell component="th" scope="row">
                    <Box display={'flex'}>
                      <ResourceIcon type={resource.content_type} />
                      <Box marginX={2} display={'flex'} flexDirection={'column'}>
                        <Typography variant={'h4'}>{resource.name}</Typography>
                        {resource.description && <Typography variant={'body1'}>{resource.description}</Typography>}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'body1'}>{getFormattedDate(resource.create_date)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'body1'}>
                      {resource.expiry_date ? getFormattedDate(resource.expiry_date) : 'Never'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'body1'}>{getFileSize(resource.file_size)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'body1'}>{getFileType(resource.content_type, resource.name)}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
