import styled from "styled-components";
import {Box, makeStyles, Paper} from "@material-ui/core";

export const GridItemTail = styled(Box)`
  && {
    margin-top: -3px;
    width: 100%;
    display: block;
    position: relative;
    z-index:1;
      &:after {
      width: 80%;
      height: 22px;
      margin-top: -7px;
      z-index: -6;
    }
    &:before { 
      width: 90%;
      height: 30px;
      margin-top: -21px;
      z-index: -5;
    }
    &:after,
    &:before {
      content: "";
      background-color: white;
      display: block;
      border-radius: inherit;
      box-shadow: 0 1px 3px 0 rgba(65,69,88,0.2);
      border: inherit;
      border-radius: 6px;
      position: absolute;
      left: 50%;
      top: 100%;
      transform-origin: center;
      transform: translateX(-50%);
    }
  }
`;

export const GridItemBox = styled(Box)`
  && {
    position: relative;
    background-color: white;
    border-radius: inherit;
  }
`;

export const GridItemPaper = styled(Paper)`
  && {
    position: relative;
    background-color: white;
    z-index: 2;
  }
`;

export const HoverBox = styled(Box)`
  && {
    &:hover {
      cursor: pointer;
    }
  }
`;

export const ScoreBox = styled(Box)`
  && {
    width: 60px;
    height: 60px;
  }
`;

export const useStyles = makeStyles(() => ({
    radio: {
        paddingTop: "2px",
        '&:hover': {
            backgroundColor: 'transparent !important'
        }
    }
}));
