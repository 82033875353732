import { useApolloClient } from '@apollo/client';
import { Box, Radio, Typography } from '@material-ui/core';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { EqualHeight, EqualHeightElement } from 'react-equal-height';
import { useChannel } from '../../contexts/ChannelContext';
import { useCompareChannel } from '../../contexts/CompareChannelContext';
import { useComparison } from '../../contexts/ComparisonContext';
import { ExpandableProvider, useExpandable } from '../../contexts/ExpandableContext';
import { useFunctions } from '../../hooks/functions';
import { getFrictionScores } from '../../support/graphql/queries';
import { TabFunctionView, TabJourneyView } from '../../types/Channel';
import { ChannelBadge } from '../Common/ChannelBadge';
import { FrictionScore } from '../Common/FrictionScore';
import { Loading } from '../Common/Loading';
import { SegmentBadge } from '../Common/SegmentBadge';
import { WeightBadge } from '../Common/WeightBadge';
import { CheckedIcon, UncheckedIcon } from '../Common/icons';
import { Expandable } from '../Expandable/Expandable';
import Score from '../Score';
import { SecureChannelBadge } from '../../components/Common/SecureChannelBadge';
import { ExportTrigger } from '../Export/ExportTrigger';
import {
  Cell,
  CellElement,
  Columns,
  Container,
  ExpandableArea,
  Group,
  Question,
  Row,
  Sticker,
  useStyles
} from './FunctionView.styled';
import { ChannelScore, TransformedChannelScore, TransformedJourneyAnswers } from '../../declarations/ChannelScore';
import { FeatureJourney } from '../../types/Functions';
import { Question as QuestionType } from '../../declarations/Question';

type WithExpandableProviderProps = {
  expanded: string[];
  names: string[];
  checkable: string[]
  checked: string[]
};

const WithExpandableProvider: FunctionComponent<WithExpandableProviderProps> = (props) => {
  return (
    <ExpandableProvider
      state={{
        expanded: props.expanded,
        checked: props.checked
      }}
      names={props.names}
      checkable={props.checkable}
    >
      {props.children}
    </ExpandableProvider>
  );
};

type FunctionViewComparisonProps = {
  channelScores: ChannelScore[];
  comparable?: boolean;
  sortedOnText?: string;
  sortedQuestion?: Number;
  sortedJourneyKey?: string;
  usesVoc: boolean
};

const FunctionViewComparison: FunctionComponent<FunctionViewComparisonProps> = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const comparison = useComparison();
  const channel = useChannel();
  const compareChannel = useCompareChannel();
  const { journeys, transformChannels } = useFunctions();
  const expandable = useExpandable();

  const frictionSORef = React.useRef<HTMLInputElement>(null);

  const { sortedOnText, channelScores, comparable = false, sortedQuestion, sortedJourneyKey } = props;

  const [frictionScores, setFrictionScores] = useState<FrictionData[]>([]);
  const [frictionScoresLoaded, setFrictionScoresLoaded] = useState<boolean>(false);
  const [prevSortedJourneyKey, setPrevSortedJourneyKey] = useState(sortedJourneyKey)

  useEffect(() => {
    /*
     * This code needs to be inside an effect otherwise you get the console warning:
     *    Warning: Cannot update a component (`ExpandableProvider`) while rendering
     *    a different component (`FunctionViewComparison`).
     *
     * Strangely, without the useEffect, the warning only appears very intermittently
     * and even then the result is still as intended ¯\_(ツ)_/¯
     */
    if (sortedJourneyKey !== prevSortedJourneyKey) {
      expandable.dispatch({ type: "CHECK_ITEM", payload: { name: sortedJourneyKey } })
      setPrevSortedJourneyKey(sortedJourneyKey)
    }
  }, [expandable, sortedJourneyKey, prevSortedJourneyKey])

  // console.log("sortedQuestion", sortedQuestion)

  /**
   * 
   * this code is commented out because as of March 2024 I beleive the scrolling
   * does more harm than good. it can be deleted if we're happy with that
   * Only scroll if we sort by Friction
   */
  // if (!scrolled && comparable && searchResults.state.orderby.field === 'FRICTION') {
  //   if (frictionSORef.current) {
  //     frictionSORef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //     setScrolled(true);
  //   }
  // }

  // useEffect(() => {
  //   setScrolled(false);
  // }, [sortedOnText, comparable]);

  const providers: TransformedChannelScore[] = useMemo(
    () => transformChannels(channelScores),
    [channelScores, transformChannels]
  );

  const auditIds = useMemo(() => {
    return channelScores.map((c) => c.auditId);
  }, [channelScores]);

  const toggleAddToCompare = (e: any, key: string) => {
    e.stopPropagation();

    const channelScore = props.channelScores.find((p) => p.key === key);

    if (!channelScore) return;

    comparison.dispatch({
      type: 'TOGGLE_COMPARISON_ITEM_IN_STATE',
      payload: { item: channelScore, view: TabFunctionView }
    });
  }

  const handleViewJourneyClicked = (channelScore: ChannelScore, expanded: string[]) => {
    if (comparable) {
      channel.dispatch({
        type: 'INITIALISE_CHANNEL_MODAL_STATE',
        payload: { channelScore, view: TabJourneyView, expanded }
      });

      channel.dispatch({
        type: 'TOGGLE_CHANNEL_MODAL_STATE',
        payload: { value: true }
      });
    } else {
      compareChannel.dispatch({
        type: 'INITIALISE_COMPARE_CHANNEL_MODAL_STATE',
        payload: {
          channelScores: props.channelScores,
          expanded: [channelScore.key],
          selected: { [channelScore.key]: expanded[0] },
          view: TabJourneyView
        }
      });

      compareChannel.dispatch({
        type: 'TOGGLE_COMPARE_CHANNEL_MODAL_STATE',
        payload: { value: true }
      });
    }
  }

  const loadScores = useCallback(async () => {
    const response = await getFrictionScores(client, { auditIds });

    setFrictionScores(response);
    setFrictionScoresLoaded(true);
  }, [auditIds, client]);

  useEffect(() => {
    loadScores();
    return () => {
      setFrictionScores([]);
      setFrictionScoresLoaded(false);
    };
  }, [auditIds, loadScores]);

  const showQuestion = (j: any, q: any) => {
    if (expandable.state.expanded.includes(j.key)) {
      return true
    }
    if (expandable.state.checked.includes(j.key)) {
      return q.id === sortedQuestion
    }
    return false;
  }

  const prominentColour = 'white';
  const baseColour = '#fafafa';

  // all of the nested components below are not defined using useCallback() so they get
  // re-created on every render. However this does not seem to cause a performance
  // problem in testing (this view is very slow anyway)

  const FunctionViewHeader = () =>
    <Sticker
      style={{
        alignItems: 'flex-end',
        backgroundColor: 'white',
        borderBottom: '1px solid #D9DBE0',
        zIndex: 3,
        boxShadow: '5px 0px 15px 0px rgba(0,0,0,0.06)'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', padding: '15px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Expandable.GroupTrigger>
            <Typography style={{ marginLeft: '6px' }} variant={'h4'}>
              Expand all
            </Typography>
          </Expandable.GroupTrigger>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
          <Expandable.GroupTrigger collapse>
            <Typography style={{ marginLeft: '6px' }} variant={'h4'}>
              Collapse all
            </Typography>
          </Expandable.GroupTrigger>
        </div>
      </div>
    </Sticker>

  type FunctionViewQuestionStubProps = {
    question: QuestionType
    journey: FeatureJourney
    highlight: boolean
  }

  const FunctionViewQuestionStub: FunctionComponent<FunctionViewQuestionStubProps> = ({ question, journey, highlight }) =>
    <Question
      style={{
        borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px',
        marginLeft: '50px'
      }}
      className={'oddly'}
    >
      <EqualHeightElement name={`${journey.key}#question#${question.id}`}>
        <div style={{ padding: '16px', display: 'block' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              width: '100%'
            }}
          >
            <div style={{ flex: '1 1 auto', width: 'auto' }}>
              <Typography component={'span'} variant={'body1'}>
                {highlight ? (
                  <>
                    <div style={{ position: 'relative', top: '-220px', left: 0 }} ref={frictionSORef}>
                      what is here?
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          flex: '5',
                          paddingLeft: '5px',
                          fontWeight: 'normal',
                          color: '#24AB3B',
                        }}
                      >
                        {question.text}
                      </div>
                      <div
                        style={{
                          flex: '3',
                          paddingRight: '5px',
                          paddingLeft: '5px',
                          fontWeight: 'normal',
                          color: '#24AB3B',
                        }}
                      >
                        Sorted by Friction Score
                      </div>
                    </div>
                  </>
                ) : (
                  <div style={{ display: 'flex' }}>
                    <p>{question.text}</p>
                  </div>
                )}
              </Typography>

              <div style={{ display: 'flex', paddingTop: '15px' }}>
                {question.weights.map((weight) => (
                  <WeightBadge
                    key={`${question.id}#${weight.segment}#${question.secure_channelId}`}
                    weight={weight.weight}
                    question={question.id}
                    text={question.text}
                    segment={weight.segment}
                  />
                ))}
                <Typography component={'span'}>
                  {<SecureChannelBadge secure={question.secure_channelId} />}
                </Typography>
              </div>
            </div>

            <div style={{ display: 'flex', flex: '0 0 0px', width: 'auto' }}>
              {/*<HelpToolTip/>*/}
              {/*<SortMenu/>*/}
            </div>
          </div>
        </div>
      </EqualHeightElement>
    </Question>

  type FunctionViewJourneyStubProps = {
    journey: FeatureJourney
  }

  const FunctionViewJourneyStub: FunctionComponent<FunctionViewJourneyStubProps> = ({ journey }) =>
    <ExpandableArea
      style={{
        backgroundColor:
          expandable.state.expanded.length !== 0
            ? expandable.state.expanded.includes(journey.key)
              ? prominentColour
              : baseColour
            : prominentColour,
        borderRight: '1px solid #D9DBE0'
      }}
    >
      <EqualHeightElement name={journey.key}>
        <div>
          <div style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Expandable.Trigger name={journey.key}>
                <Typography style={{ marginLeft: '10px' }} variant={'h2'}>
                  {journey.name}
                </Typography>
              </Expandable.Trigger>
            </div>
            <div style={{ paddingTop: '15px', marginLeft: '30px' }}>
              <div style={{ display: 'flex' }}>
                {journey.weights.map((weight) => (
                  <WeightBadge
                    withIcon
                    key={`${journey.id}#${weight.segment}#${journey.secure}`}
                    weight={weight.weight}
                    segment={weight.segment}
                    secure={journey.secure}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </EqualHeightElement>

      <Expandable.Group name={journey.key}>
        {journey.questions
          .filter(question => showQuestion(journey, question))
          .map((question, qIdx) => (
            <FunctionViewQuestionStub
              key={qIdx}
              question={question}
              journey={journey}
              highlight={sortedOnText === question.text && comparable} />
          ))}

        {expandable.state.expanded.includes(journey.key) && <>
          {props.usesVoc &&
            <Question
              style={{
                borderTopLeftRadius: '6px',
                borderBottomLeftRadius: '6px',
                marginLeft: '50px'
              }}
              className={'oddly'}
            >
              <EqualHeightElement name={`${journey.key}#cx_score`}>
                <div style={{ padding: '16px', display: 'block' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      width: '100%'
                    }}
                  >
                    <div style={{ flex: '1 1 auto', width: 'auto' }}>
                      <Typography variant={'h4'}>VoC Score</Typography>
                    </div>
                  </div>
                </div>
              </EqualHeightElement>
            </Question>
          }
          <Question
            style={{
              borderTopLeftRadius: '6px',
              borderBottomLeftRadius: '6px',
              marginLeft: '50px'
            }}
            className={'oddly'}
          >
            <EqualHeightElement name={`${journey.key}#score`}>
              <div style={{ padding: '16px', display: 'block' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    width: '100%'
                  }}
                >
                  <div style={{ flex: '1 1 auto', width: 'auto' }}>
                    <Typography variant={'h4'}>Utility Score</Typography>
                  </div>
                </div>
              </div>
            </EqualHeightElement>
          </Question>
        </>}
      </Expandable.Group>
    </ExpandableArea>

  type FunctionViewColumnHeaderProps = {
    provider: TransformedChannelScore
  }

  const FunctionViewColumnHeader: FunctionComponent<FunctionViewColumnHeaderProps> = ({ provider }) => {
    const selected = comparison.state.function.filter((c) => {
      return c.value.key === provider.key;
    });

    const isChecked = selected.length > 0;
    const disabled = !isChecked && comparison.state.function.length === 10;

    return <Sticker style={{ borderBottom: '1px solid #D9DBE0', backgroundColor: 'white' }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {comparable && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Radio
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                textAlign: 'center'
              }}
              checked={isChecked}
              disabled={disabled}
              onClick={(e) => toggleAddToCompare(e, provider.key)}
              classes={{ root: classes.radio }}
              disableRipple={true}
              icon={<UncheckedIcon />}
              inputProps={{ 'aria-label': provider.providerName }}
              checkedIcon={<CheckedIcon />}
            />
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img style={{ maxHeight: '24px', width: 'auto' }} src={provider.providerLogo} alt={''} />
        </div>
        <div
          style={{
            marginTop: '8px',
            marginBottom: '8px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <span>
            <Typography variant={'body2'}>{provider.marketName}</Typography>
          </span>
        </div>
        <div
          style={{
            marginTop: '8px',
            marginBottom: '8px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              marginBottom: '4px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <ChannelBadge
              info={false}
              channel={provider.channel}
              channelName={provider.channelName}
              providerName={provider.providerName}
              auditDate={provider.audit_date}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SegmentBadge segment={provider.segment} segmentName={provider.segmentName} />
          </div>
        </div>
      </div>
    </Sticker>
  }

  type FunctionViewQuestionCellProps = {
    provider: TransformedChannelScore
    journey: FeatureJourney
    frictionData?: FrictionData
    question: QuestionType
    currentJourney?: TransformedJourneyAnswers
  }

  const FunctionViewQuestionCell: FunctionComponent<FunctionViewQuestionCellProps> = ({ provider, journey, frictionData, question, currentJourney }) => {
    let frictionScore = undefined;

    if (frictionData) {
      frictionScore = frictionData.friction_scores.find((fs) => fs.question === question.id);
    }

    let value = undefined;

    if (currentJourney) {
      value = currentJourney.answers.find((a) => a.questionId === question.id);
    }

    return (
      <Cell>
        <EqualHeightElement name={`${journey.key}#question#${question.id}`}>
          <CellElement>
            <FrictionScore.Dropdown
              guid={`${provider.key}#${journey.key}#${question.id}`}
              channelScore={provider as unknown as ChannelScore}
              journey={{
                id: journey.id,
                secure: journey.secure
              }}
              question={question.id}
              score={frictionScore}
              value={value?.value}
              onViewJourneysClick={handleViewJourneyClicked}
              provider={provider}
            />
          </CellElement>
        </EqualHeightElement>
      </Cell>
    );
  }

  type FunctionViewJourneyCellProps = {
    provider: TransformedChannelScore
    journey: FeatureJourney
  }

  const FunctionViewJourneyCell: FunctionComponent<FunctionViewJourneyCellProps> = ({ provider, journey }) => {
    const frictionData = frictionScores.find((fd) => fd.auditId === provider.auditId);

    const currentJourney = provider.answers.find((pj) => {
      return pj.journeyId === journey.id && pj.secure === journey.secure;
    });

    let functionCount = 0;

    if (currentJourney) {
      functionCount = currentJourney.answers.filter((a) => {
        return a.value === 1;
      }).length;
    }

    let frictionScoresCount = 0;

    if (frictionData) {
      const questions = journey.questions.map((q) => q.id);
      frictionScoresCount = frictionData.friction_scores.filter((fs) =>
        questions.includes(fs.question)
      ).length;
    }

    return (
      <>
        <Cell
          style={{
            borderTop: '1px solid #D9DBE0',
            backgroundColor:
              expandable.state.expanded.length !== 0
                ? expandable.state.expanded.includes(journey.key)
                  ? prominentColour
                  : baseColour
                : prominentColour
          }}
        >
          <EqualHeightElement name={journey.key}>
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {currentJourney && (
                <Typography
                  variant={'body2'}
                  style={{
                    color: '#647386',
                    fontSize: '12px',
                    lineHeight: '15px',
                    letterSpacing: '-0.17px',
                    fontWeight: 'bold'
                  }}
                >
                  {functionCount === 1 ? `${functionCount} Function` : `${functionCount} Functions`}
                </Typography>
              )}

              {frictionScoresCount > 0 && (
                <Typography
                  variant={'body2'}
                  style={{
                    color: '#647386',
                    fontSize: '12px',
                    lineHeight: '15px',
                    letterSpacing: '-0.17px',
                    fontWeight: 'bold'
                  }}
                >
                  {frictionScoresCount === 1
                    ? `${frictionScoresCount} Friction Score`
                    : `${frictionScoresCount} Friction Scores`}
                </Typography>
              )}
            </div>
          </EqualHeightElement>
        </Cell>

        <Expandable.Group name={journey.key}>
          <div className={'oddly'}>
            {
              journey.questions
                .filter(question => showQuestion(journey, question))
                .map((question, qIdx) =>
                  <FunctionViewQuestionCell
                    key={qIdx}
                    provider={provider}
                    journey={journey}
                    frictionData={frictionData}
                    question={question}
                    currentJourney={currentJourney} />
                )
            }
            {expandable.state.expanded.includes(journey.key) && <>
              {props.usesVoc &&
                <Cell>
                  <EqualHeightElement name={`${journey.key}#cx_score`}>
                    <CellElement>{currentJourney && <Score score={currentJourney.cx_score} />}</CellElement>
                  </EqualHeightElement>
                </Cell>
              }
              <Cell>
                <EqualHeightElement name={`${journey.key}#score`}>
                  <CellElement>{currentJourney && <Score score={currentJourney.score} />}</CellElement>
                </EqualHeightElement>
              </Cell>
            </>}
          </div>
        </Expandable.Group>
      </>
    );
  }

  if (frictionScores.length <= 0 && !frictionScoresLoaded) {
    return <Loading.Panel width={'100%'} height={600} />;
  }

  // console.log("expandable", expandable.state)
  return (
    <EqualHeight>
      {!comparable && (
        <Box marginY={1} display={'flex'} justifyContent={'end'}>
          <Box marginTop={'10px'}>
            <ExportTrigger tab={TabFunctionView} selected={expandable.state.expanded} />
          </Box>
        </Box>
      )}
      <Container>
        <Group style={{ width: 'auto' }}>
          <FunctionViewHeader />
          {journeys.map((journey, journeyIdx) => (
            <FunctionViewJourneyStub journey={journey} key={journeyIdx} />
          ))}
        </Group>
        <Columns>
          {providers.map((provider, providerIdx) =>
            <Row key={providerIdx}>
              <FunctionViewColumnHeader provider={provider} />
              {
                // the lint warning on the next line seems to be a bug in react/prop-types
                // eslint-disable-next-line react/prop-types
                provider.journeys.map((journey, jIdx) =>
                  <FunctionViewJourneyCell key={jIdx} provider={provider} journey={journey} />)
              }
            </Row>
          )}
        </Columns>
      </Container>
    </EqualHeight>
  );
};

// TODO This view renders 8 times on state change which seems more than necessary
export const FunctionView: FunctionComponent<FunctionViewComparisonProps> = (props) => {
  const { names, journeys } = useFunctions();

  let sortedJourneyKey;
  let sortedQuestion
  if (props.sortedOnText) {
    const findSortedOnJourney = (sortedOnText: string) =>
      journeys.filter((journey) => {
        let journeyHasFrictionScoreQuestion = false;
        journey.questions.forEach((question) => {
          if (question.text === sortedOnText) {
            sortedQuestion = question.id
            journeyHasFrictionScoreQuestion = true;
          }
        });

        return journeyHasFrictionScoreQuestion;
      });

    const sortedJourney = findSortedOnJourney(props.sortedOnText as string);
    // Ideally sortedJourney would always be found if the state of the app is consistent.
    // However if you're currently in function view with sort-by-friction and then
    // you change sector, this component renders initially with props.sortedOnText
    // set to the previous friction question before it resets to the default sort-by-voc.
    // hence if sortedJourney is not found we just ignore it
    if (props.comparable && sortedJourney.length) {
      sortedJourneyKey = sortedJourney[0].key;
    }
  }

  const checkable: string[] = sortedJourneyKey ? [sortedJourneyKey] : []
  const checked: string[] = sortedJourneyKey ? [sortedJourneyKey] : []

  return (
    <WithExpandableProvider expanded={[]} names={names} checkable={checkable} checked={checked}>
      <FunctionViewComparison {...props} sortedQuestion={sortedQuestion} sortedJourneyKey={sortedJourneyKey} />
    </WithExpandableProvider>
  );
};
