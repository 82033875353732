import { Box, FormGroup } from '@material-ui/core';
import { PropsWithChildren, useState } from 'react';
import { useDropdown } from '../../../../contexts/DropdownContext';
import { Item } from './Item';
import Subtitle from './Subtitle';
import { Toggle } from './Toggle';
import { Criterion, JourneyCriterion } from '../../../../declarations/Criterion';

type GroupProps = {
  groupKey: keyof SearchKind;
  criteria: Criterion[] | JourneyCriterion[];
  selected: number[] | Array<{ id: number; secure: boolean }>;
  onSelected: <K extends keyof SearchKind>(key: K, value: number, state: boolean) => void;
  type?: string;
  single?: boolean;
  hideSelectAll?: boolean;
  subtitleText?: string;
  onSelectAll?: <K extends keyof SearchKind>(key: K, state: boolean) => void;
  showTextFilter?: boolean
};

export const Group = (props: PropsWithChildren<GroupProps>) => {
  const dropdown = useDropdown();
  const { single = false, hideSelectAll = false } = props;
  const [filter, setFilter] = useState("")

  const handleSelectInGroup = (state: boolean) => {
    props.onSelectAll && props.onSelectAll(props.groupKey, state);
  };

  const handleSelected = (value: number, state: boolean) => {
    if (single) {
      dropdown.dispatch({
        type: 'TOGGLE_DROPDOWN_OPEN_STATE',
        payload: { value: false }
      });
    }

    props.onSelected(props.groupKey, value, state);
  };

  const isSelected = (value: Criterion | JourneyCriterion): boolean => {
    if (props.groupKey === 'questions') {
      if (props.type === undefined) return false;

      let secure = props.type === 'secure';
      let criterion = value as JourneyCriterion;
      let selected = props.selected as Array<{ id: number; secure: boolean }>;

      return (
        selected.findIndex((selectable) => {
          return selectable.id === criterion.id && selectable.secure === secure;
        }) >= 0
      );
    }

    let selected = props.selected as number[];
    return selected.includes(value.id);
  };

  const isAllSelected = () => {
    return Object.entries(props.criteria).length === props.selected.length;
  };

  return (
    <Box>
      {props.subtitleText && <Subtitle text={props.subtitleText} />}
      {!hideSelectAll && <Toggle checked={isAllSelected()} handleChange={(state) => handleSelectInGroup(state)} />}
      {props.children && props.children}
      {props.showTextFilter &&
        <input
          type="text"
          value={filter}
          onChange={e => setFilter(e.target.value)}
          style={{
            border: "1px solid LightGray",
            outlineWidth: 0,
            padding: 3,
            marginBottom: 6,
            marginLeft: 26
          }}
          placeholder='filter'
        ></input>}
      <FormGroup>
        {props.criteria &&
          Object.values(props.criteria)
            .filter(x => !filter || x.name.toLowerCase().includes(filter.toLowerCase()))
            .map((criterion, index) => {
              return (
                <Item
                  as={single ? 'tick' : 'checkbox'}
                  onSelected={handleSelected}
                  key={index}
                  isSelected={isSelected(criterion)}
                  label={criterion.name}
                  value={criterion.id}
                  data-cy={`item-${criterion.id}`}
                />
              );
            })}
      </FormGroup>
    </Box>
  );
};
