import { Box, Button, Divider, Grid, Paper, Radio, Typography, makeStyles } from "@material-ui/core"
import { uniqBy, groupBy, cloneDeep } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import styled from "styled-components"
import { useAdvancedJourneyContext } from "../../contexts/AdvancedJourneyContext"
import { useCriteria } from "../../contexts/Search/Criteria"
import { useSearchQuery } from "../../contexts/Search/Query"
import { useQuestions } from "../../hooks/questions"
import { GreenCheckbox } from "../Common/GreenCheckbox"
import { WeightBadge } from "../Common/WeightBadge"
import { CheckedIcon, ChevronDown, UncheckedIcon } from "../Common/icons"
import { Modal } from "../Modal/Modal"
import { Question, Weight } from "../../declarations/Question"

export const AdvancedJourneyModal = () => {
    const searchCriteria = useCriteria()
    const searchQuery = useSearchQuery()
    const advancedJourneyContext = useAdvancedJourneyContext()
    const { getJourneyWeights, resolveWeights } = useQuestions()
    const classes = useStyles()
    const [selectedQuestions, setSelectedQuestions] = useState(() => cloneDeep(searchQuery.state.questions))
    const [selectedJourney, setSelectedJourney] = useState<{ id: number, name: string } | undefined>(undefined)
    const [questions, setQuestions] = useState<Question[]>([])

    const getQuestions = useCallback(
        (journeyId: number): Question[] =>
            searchCriteria.state.questions.questions.filter(
                question => journeyId === question.journeyId),
        [searchCriteria.state.questions])

    useEffect(() => {
        if (selectedJourney === undefined) return

        const questions = uniqBy(
            getQuestions(selectedJourney.id),
            value => value.id
        )

        setQuestions(questions)
    }, [selectedJourney, getQuestions])

    useEffect(() => {
        // if the user cancels the dialog then opens again we want to restore the local state
        // to the global state
        if (advancedJourneyContext.open) {
            setSelectedQuestions(cloneDeep(searchQuery.state.questions))
        }
    }, [advancedJourneyContext.open, searchQuery.state.questions])

    const questionsById = Object.fromEntries(searchCriteria.state.questions.questions.map(x => [x.id, x]))
    const selectedQuestionsByJourney = groupBy(selectedQuestions.questionIds, x => questionsById[x].journeyId)

    const isQuestionChecked = (value: number) => selectedQuestions.questionIds.includes(value)

    const isJourneySelected = (value: number) => selectedJourney?.id === value

    const handleJourneySelected = (id: number, name: string) => setSelectedJourney({ id, name })

    const handleChangeQuestionType = (type: string) => {
        setSelectedQuestions({ type, questionIds: [...selectedQuestions.questionIds] })
    }

    const handleQuestionChecked = (id: number, checked: boolean) => {
        const questionCriterion = cloneDeep(selectedQuestions)

        if (!checked && questionCriterion['questionIds'].includes(id)) {
            questionCriterion['questionIds'].splice(
                questionCriterion['questionIds'].indexOf(id),
                1
            )
        } else {
            questionCriterion['questionIds'].push(id)
        }
        setSelectedQuestions(questionCriterion)
    }

    const handleDeselectAll = () => {
        setSelectedQuestions({ type: "OR", questionIds: [] })
    }

    const handleApply = () => {
        searchQuery.dispatch({ type: "SET_QUESTION_QUERY_STATE", payload: { value: selectedQuestions } })
        advancedJourneyContext.setOpen(false)
    }

    return (
        <Modal
            open={advancedJourneyContext.open}
            onClose={() => advancedJourneyContext.setOpen(false)}
            closeable={true}
            paddingX={4}
            paddingY={4}
            fullHeight={false}
            rounded={true}
            size={"lg"}
            title={"Function Selector"}
        >
            <div style={{ display: "flex", flexDirection: "row", width: "1200px", height: "650px" }}>
                <Grid container spacing={2}>
                    {/* Category panel to the left */}
                    <Grid id='category-panel' item xs={4}>
                        {/* Result AND/OR Filter */}
                        <Grid id='and-or-filter-container' container direction="column" style={{ marginLeft: '10px' }}>
                            <Grid id='show-result-label' item xs={6}>
                                <Typography
                                    variant={"body1"}
                                    style={{
                                        fontWeight: "bold"
                                    }}
                                >
                                    Show results containing
                                </Typography>
                            </Grid>
                            {/* AND/OR Radio button container */}
                            <Grid id='and-or-radio-buttons' item xs={"auto"} style={{ display: 'flex' }}>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item xs={6}>
                                        <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                        >
                                            <Box
                                                display={"flex"}
                                                alignItems={"center"}
                                                justifyItems={"center"}
                                                marginRight={1}
                                            >
                                                <Radio
                                                    style={{
                                                        padding: 0,
                                                    }}
                                                    checked={selectedQuestions.type === 'AND'}
                                                    onClick={() => handleChangeQuestionType("AND")}
                                                    classes={{ root: classes.radio }}
                                                    disabled={false}
                                                    disableRipple={true}
                                                    icon={<UncheckedIcon />}
                                                    checkedIcon={<CheckedIcon />}
                                                />
                                            </Box>
                                            <Typography variant={"body1"}>
                                                ALL functions
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                        >
                                            <Box
                                                display={"flex"}
                                                alignItems={"center"}
                                                justifyItems={"center"}
                                                marginRight={1}
                                            >
                                                <Radio
                                                    style={{
                                                        padding: 0,
                                                    }}
                                                    checked={selectedQuestions.type === 'OR'}
                                                    onClick={() => handleChangeQuestionType("OR")}
                                                    classes={{ root: classes.radio }}
                                                    disabled={false}
                                                    disableRipple={true}
                                                    icon={<UncheckedIcon />}
                                                    checkedIcon={<CheckedIcon />}
                                                />
                                            </Box>
                                            <Typography variant={"body1"}>
                                                ANY function
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <StyledBox>
                            Default is to not apply this filter if no boxes are ticked
                            (i.e. filter for ANY benchmark)
                        </StyledBox>
                        <Paper style={{ width: "100%", borderRadius: '12px', paddingTop: "8px", paddingBottom: "8px" }}>
                            <div style={{ maxHeight: "660px", width: "100%", overflowY: "auto", boxSizing: 'border-box' }}>

                                {Object.entries(searchCriteria.state.secureJourneys).map((criterion, idx) => {
                                    const [, value] = criterion
                                    const weights = getJourneyWeights(value.id)

                                    return (
                                        <JourneyItem
                                            key={idx}
                                            onSelected={(id: number) => handleJourneySelected(id, value.name)}
                                            isSelected={isJourneySelected(value.id)}
                                            text={`${value.name} (${selectedQuestionsByJourney[value.id]?.length || '0'})`}
                                            value={value.id}
                                            weights={weights}
                                        />
                                    )
                                })}
                            </div>
                        </Paper>
                        <Box marginLeft={'1em'} marginTop={'1em'}>
                            <Button
                                color={'secondary'}
                                variant={'contained'}
                                onClick={handleApply}
                                style={{ boxShadow: 'none' }}
                            >Apply</Button>
                            <Button
                                color={'secondary'}
                                variant={'text'}
                                onClick={handleDeselectAll}
                                style={{ marginLeft: '2em', boxShadow: 'none' }}
                            >Deselect all</Button>
                        </Box>
                    </Grid>

                    {selectedJourney && (
                        <Grid item xs={8}>

                            <Paper style={{ width: "100%", borderRadius: '12px', }}>
                                <Box style={{ boxSizing: 'border-box', height: '100%' }}>

                                    <Box paddingX={4} paddingY={2} display={"flex"} flexDirection={"column"}>
                                        <Typography variant={"h1"} style={{
                                            fontSize: "18px",
                                            lineHeight: "24px",
                                            fontWeight: "bold"
                                        }}>
                                            {selectedJourney.name}
                                        </Typography>
                                    </Box>

                                    <Box marginX={4}>
                                        <Divider style={{
                                            backgroundColor: "#ECEDEF"
                                        }} />
                                    </Box>

                                    <Box marginX={2} style={{ overflowY: "auto", maxHeight: '560px' }}>
                                        {questions.map((question, idx) => {
                                            return (
                                                <QuestionItem
                                                    key={idx}
                                                    isChecked={isQuestionChecked(question.id)}
                                                    onChecked={(value, checked) => handleQuestionChecked(value, checked)}
                                                    text={question.text}
                                                    value={question.id}
                                                    weights={resolveWeights(question.weights)}
                                                />
                                            )
                                        })}
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                    )}

                    {!selectedJourney && (
                        <Grid item xs={8}>
                            <Box
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                style={{
                                    width: "100%",
                                    height: "440px",
                                    maxHeight: "100%",
                                }}
                            >
                                Select a function to view
                            </Box>

                        </Grid>
                    )}
                </Grid>
            </div>
        </Modal>
    )
}

type JourneyItemProps = {
    text: string,
    value: number
    onSelected: (value: number) => void,
    isSelected: boolean
    weights: Weight[]
}

const JourneyItem = (props: JourneyItemProps) => {

    return (

        <ChannelBoxStyles
            onClick={() => props.onSelected(props.value)}
            style={{
                backgroundColor: props.isSelected === true ? '#001433' : '#FFFFFF'
            }}
        >
            <Box
                marginX={2}
                style={{
                    borderBottom: `1px solid ${props.isSelected === true ? 'transparent' : '#ECEDEF'}`
                }}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    height={"27px"}
                >
                    <Typography variant={"body1"} style={{
                        fontSize: "14px",
                        fontWeight: props.isSelected === true ? "bold" : 500,
                        lineHeight: "21px",
                        color: props.isSelected === true ? '#FFFFFF' : '#647386'
                    }}>
                        {props.text}
                    </Typography>
                </Box>

                <Box
                    display={"flex"}
                    alignItems={"center"}
                >

                    {props.weights.map((weight) => (
                        <WeightBadge
                            key={`${props.value}#${weight.segment}`}
                            weight={weight.weight}
                            segment={weight.segment}
                        />
                    ))}

                    <ChevronDown
                        viewBox={"0,0,8,8"}
                        style={{
                            width: "12px",
                            height: "10px",
                            marginLeft: "5px",
                            transform: "rotate(-90deg)",
                            color: props.isSelected === true ? '#FFFFFF' : '#8B96A4'
                        }}
                    />
                </Box>
            </Box>
        </ChannelBoxStyles>
    )
}

type QuestionItemProps = {
    isChecked: boolean,
    text: string,
    value: number
    onChecked: (value: number, checked: boolean) => void,
    weights: Weight[]
}

const QuestionItem = (props: QuestionItemProps) => {

    return (
        <ChannelBoxStyles>
            <Box
                marginX={2}
                style={{
                    borderBottom: '1px solid #ECEDEF'
                }}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <Box
                    display={"flex"}
                    alignItems={"center"}
                >
                    <GreenCheckbox
                        style={{
                            paddingLeft: 0,
                        }}
                        onClick={(event) => {
                            event.stopPropagation()
                        }}
                        onChange={(event) => {
                            props.onChecked(props.value, event.target.checked)
                        }}
                        checked={props.isChecked}
                    />
                    <Typography variant={"body1"} style={{
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "21px",
                        color: '#647386'
                    }}>
                        {props.text}
                    </Typography>
                </Box>

                <Box
                    display={"flex"}
                    alignItems={"center"}
                >

                    {props.weights.map((weight) => (
                        <WeightBadge
                            key={`${props.value}#${weight.segment}`}
                            weight={weight.weight}
                            segment={weight.segment}
                        />
                    ))}
                </Box>
            </Box>
        </ChannelBoxStyles>
    )
}

const useStyles = makeStyles(() => ({
    radio: {
        paddingTop: "2px",
        '&:hover': {
            backgroundColor: 'transparent !important'
        }
    }
}))

const ChannelBoxStyles = styled(Box)`
    && {
        &:hover {
            cursor: pointer;
        }
    }
`

export const StyledBox = styled(Box)`
  && {
    font-size: 14px;
    color: #647386;
    letter-spacing: -0.2px;
    margin-bottom: 16px;
    line-height: 1.5;
  }
`;
