import { useApolloClient } from "@apollo/client"
import {
    Paper,
    Typography, useTheme
} from "@material-ui/core"
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { EqualHeight, EqualHeightElement } from "react-equal-height"
import styled from "styled-components"
import { ExpandableProvider } from "../../contexts/ExpandableContext"
import { FrictionScore } from "../Common/FrictionScore"
import { WeightBadge } from "../Common/WeightBadge"
import { Expandable } from '../Expandable/Expandable'
// import HelpToolTip from "../HelpToolTip/HelpToolTip";
import { SecureChannelBadge } from "../../components/Common/SecureChannelBadge"
import { useChannel } from "../../contexts/ChannelContext"
import { useFunctions } from "../../hooks/functions"
import { getFrictionScores } from "../../support/graphql/queries"
import { TabJourneyView } from "../../types/Channel"
import { Loading } from "../Common/Loading"
import Score from "../Score"
import { ChannelScore } from "../../declarations/ChannelScore"

const Container = styled.div`
    && {
        display: flex;
        flex-flow: row-nowrap;
    }
`

const ExpandableArea = styled.div`
    && {
        min-width: 500px;
        div.oddly:nth-of-type(even) {
            background-color: #F6F8FA;
        }
    }
`

const Columns = styled.div`
    && {
        display: flex;
        flex-flow: row;
    }
`

const Row = styled.div`
    && {
        position: static;
        display: flex;
        flex-flow: column;
        border-left: 1px solid #D9DBE0;
        min-width: 350px;
        div.oddly {
            & > div:nth-of-type(odd) {
                background-color: #F6F8FA;
            }
        }
    }
`

const Group = styled.div`
    && {
        width: 100%;
        position: sticky;
        z-index: 2;
        left: 0;
    }
`

const Cell = styled.div`
    && {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const Question = styled.div`
    && {
        display: block;
        align-items: center;
        min-width: 200px;
        margin-left: 15px;
    }
`

type FunctionListProps = {
    channelScore: ChannelScore
    usesVoc: boolean
}

export const FunctionList = (props: FunctionListProps) => {
    const client = useApolloClient()
    const theme = useTheme()
    const channel = useChannel()

    const { names, journeys, channelJourneys, transformChannel } = useFunctions()

    const [frictionData, setFrictionData] = useState<FrictionData>()
    const [ready, setReady] = useState<boolean>(false)

    const provider = useMemo(() => transformChannel(props.channelScore, 1), [props.channelScore, transformChannel])

    const loadScores = useCallback(async () => {
        const response = await getFrictionScores(client, { auditIds: [props.channelScore.auditId] })

        if (response.length > 0) {
            setFrictionData(response[0])
        }

        setReady(true)
    }, [client, props.channelScore.auditId])

    const handleViewJourneyClicked = (channelScore: ChannelScore, expanded: string[]) => {

        channel.dispatch({
            type: "INITIALISE_CHANNEL_MODAL_STATE",
            payload: { channelScore, view: TabJourneyView, expanded }
        })

        channel.dispatch({
            type: "TOGGLE_CHANNEL_MODAL_STATE",
            payload: { value: true }
        })
    }

    useEffect(() => {
        loadScores()
    }, [props.channelScore, loadScores])

    if (!ready) {
        return (
            <div style={{ marginTop: "50px" }}>
                <Loading.Panel width={"100%"} height={700} />
            </div>
        )
    }

    return (
        <ExpandableProvider
            state={{
                expanded: names,
                checked: []
            }}
            names={[]}
            checkable={[]}>
            <EqualHeight>

                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Expandable.GroupTrigger>
                            <Typography style={{ marginLeft: "6px" }} variant={"h4"}>Expand all</Typography>
                        </Expandable.GroupTrigger>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", marginLeft: "15px" }}>
                        <Expandable.GroupTrigger collapse>
                            <Typography style={{ marginLeft: "6px" }} variant={"h4"}>Collapse all</Typography>
                        </Expandable.GroupTrigger>
                    </div>
                </div>
                <div style={{ backgroundColor: "transparent", borderRadius: "9px", marginTop: "20px" }}>
                    <Paper style={{
                        borderRadius: "9px",
                        padding: "10px 0px"
                    }}>

                        <Container>
                            <Group>
                                {
                                    journeys.map((j, jIdx) => (
                                        <ExpandableArea key={jIdx} style={{ backgroundColor: "white" }}>
                                            <EqualHeightElement name={j.key}>
                                                <div>
                                                    <div style={{ display: "flex", justifyContent: "space-between", padding: `${theme.spacing(2)}px` }}>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Expandable.Trigger name={j.key}>
                                                                <Typography style={{ marginLeft: "10px" }} variant={"h2"}>{j.name}</Typography> {/* Function header */}
                                                            </Expandable.Trigger>
                                                        </div>
                                                        <div style={{ paddingTop: "15px", marginLeft: "30px" }}>
                                                            <div style={{ display: "flex" }}>
                                                                {j.weights.map((weight) => (
                                                                    <WeightBadge
                                                                        withIcon
                                                                        key={`${j.id}#${weight.segment}#${j.secure}`}
                                                                        weight={weight.weight}
                                                                        segment={weight.segment}
                                                                        secure={j.secure}
                                                                    />
                                                                ))}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </EqualHeightElement>
                                            <Expandable.Group key={jIdx} name={j.key} >
                                                {j.questions.map((q, qIdx) => (
                                                    <Question
                                                        key={qIdx}
                                                        style={{
                                                            borderTopLeftRadius: "6px",
                                                            borderBottomLeftRadius: "6px",
                                                        }}
                                                        className={"oddly"}
                                                    >
                                                        <EqualHeightElement name={`${j.key}#question#${q.id}`}>
                                                            <div style={{ display: "block", padding: `${theme.spacing(2)}px` }}>
                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
                                                                    <div style={{ flex: "1 1 auto", width: "auto" }}>
                                                                        <Typography variant={"body1"}>
                                                                            {q.text} {/* Question text */}
                                                                        </Typography>
                                                                    </div>

                                                                    <div style={{ display: "flex", flex: "0 0 0px", width: "auto", justifyContent: "flex-end" }}>
                                                                        <Typography>{<SecureChannelBadge secure={q.secure_channelId} />}</Typography>
                                                                    </div>

                                                                    <div style={{ display: "flex", flex: "0 0 0px", width: "auto" }}>
                                                                        <div style={{ display: "flex", paddingTop: "5px" }}>
                                                                            {q.weights.map((weight) => (
                                                                                <WeightBadge
                                                                                    key={`${q.id}#${weight.segment}#${q.secure_channelId}`}
                                                                                    weight={weight.weight}
                                                                                    question={q.id}
                                                                                    text={q.text}
                                                                                    segment={weight.segment}
                                                                                    secure={q.secure_channelId === 2}
                                                                                />
                                                                            ))}
                                                                        </div>
                                                                        {/*<HelpToolTip/>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </EqualHeightElement>
                                                    </Question>
                                                ))}

                                                {props.usesVoc &&
                                                    <Question
                                                        style={{
                                                            borderTopLeftRadius: "6px",
                                                            borderBottomLeftRadius: "6px",
                                                        }}
                                                        className={"oddly"}
                                                    >
                                                        <EqualHeightElement name={`${j.key}#cx_score`}>
                                                            <div style={{ display: "block", padding: `${theme.spacing(2)}px` }}>
                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
                                                                    <div style={{ flex: "1 1 auto", width: "auto" }}>
                                                                        <Typography variant={"h4"}>
                                                                            VoC Score
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </EqualHeightElement>
                                                    </Question>
                                                }

                                                <Question
                                                    style={{
                                                        borderTopLeftRadius: "6px",
                                                        borderBottomLeftRadius: "6px",
                                                    }}
                                                    className={"oddly"}
                                                >
                                                    <EqualHeightElement name={`${j.key}#score`}>
                                                        <div style={{ display: "block", padding: `${theme.spacing(2)}px` }}>
                                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
                                                                <div style={{ flex: "1 1 auto", width: "auto" }}>
                                                                    <Typography variant={"h4"}>
                                                                        Utility Score
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </EqualHeightElement>
                                                </Question>
                                            </Expandable.Group>
                                        </ExpandableArea>
                                    ))
                                }
                            </Group>

                            <Columns>
                                <Row>
                                    {
                                        channelJourneys.map((journey, jIdx) => {
                                            const currentJourney = provider.answers.find((pj) => {
                                                return pj.journeyId === journey.id && pj.secure === journey.secure
                                            })

                                            let functionCount = 0

                                            if (currentJourney) {
                                                functionCount = currentJourney.answers.filter((a) => {
                                                    return a.value === 1
                                                }).length
                                            }

                                            let frictionScoresCount = 0

                                            if (frictionData) {
                                                const questions = journey.questions.map((q) => q.id)
                                                frictionScoresCount = frictionData.friction_scores.filter((fs) => questions.includes(fs.question)).length
                                            }

                                            return (
                                                <Fragment key={jIdx}>
                                                    <Cell>
                                                        <EqualHeightElement name={journey.key}>
                                                            <div
                                                                style={{
                                                                    height: "100%",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                {
                                                                    currentJourney && (
                                                                        <Typography variant={"body2"} style={{ lineHeight: "13px", letterSpacing: "-0.17px" }}>
                                                                            {functionCount === 1 ? `${functionCount} Function` : `${functionCount} Functions`}
                                                                        </Typography>
                                                                    )
                                                                }
                                                                {
                                                                    frictionScoresCount > 0 && (
                                                                        <Typography variant={"body2"} style={{ lineHeight: "13px", letterSpacing: "-0.17px" }}>
                                                                            {frictionScoresCount === 1 ? `${frictionScoresCount} Friction Score` : `${frictionScoresCount} Friction Scores`}
                                                                        </Typography>
                                                                    )
                                                                }
                                                            </div>
                                                        </EqualHeightElement>
                                                    </Cell>

                                                    <Expandable.Group name={journey.key} >
                                                        <div className={"oddly"}>
                                                            {journey.questions.map((q, qIdx) => {
                                                                let frictionScore = undefined

                                                                if (frictionData) {
                                                                    frictionScore = frictionData.friction_scores.find((fs) => fs.question === q.id)
                                                                }

                                                                let value = undefined

                                                                if (currentJourney) {
                                                                    value = currentJourney.answers.find((a) => a.questionId === q.id)
                                                                }

                                                                return (
                                                                    <Cell key={qIdx}>
                                                                        <EqualHeightElement name={`${journey.key}#question#${q.id}`}>
                                                                            <div style={{
                                                                                height: "100%",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }} >
                                                                                <FrictionScore.Dropdown
                                                                                    guid={`${provider.key}#${journey.key}#${q.id}`}
                                                                                    channelScore={props.channelScore}
                                                                                    journey={{ id: journey.id, secure: journey.secure }}
                                                                                    question={q.id}
                                                                                    score={frictionScore}
                                                                                    value={value?.value}
                                                                                    onViewJourneysClick={handleViewJourneyClicked}
                                                                                    provider={provider}
                                                                                />
                                                                            </div>
                                                                        </EqualHeightElement>
                                                                    </Cell>
                                                                )
                                                            })}

                                                            {props.usesVoc &&
                                                                <Cell>
                                                                    <EqualHeightElement name={`${journey.key}#cx_score`}>
                                                                        <div style={{
                                                                            height: "100%",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            minHeight: "70px",
                                                                        }} >
                                                                            {currentJourney && (
                                                                                <Score score={currentJourney.cx_score} />
                                                                            )}
                                                                        </div>
                                                                    </EqualHeightElement>
                                                                </Cell>
                                                            }

                                                            <Cell>
                                                                <EqualHeightElement name={`${journey.key}#score`}>
                                                                    <div style={{
                                                                        height: "100%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        minHeight: "70px",
                                                                    }} >
                                                                        {currentJourney && (
                                                                            <Score score={currentJourney.score} />
                                                                        )}
                                                                    </div>
                                                                </EqualHeightElement>
                                                            </Cell>
                                                        </div>
                                                    </Expandable.Group>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </Row>
                            </Columns>
                        </Container>

                    </Paper>
                </div>

            </EqualHeight>
        </ExpandableProvider>
    )
}