import styled from "styled-components";
import {Box, Typography} from "@material-ui/core";

export const MenuTitle = styled(Typography)`
  && {
    font-size: 9px !important;
    line-height: 11px !important;
    align: center;
    color: #647386;
    &.selected {
      color: white;
    }
  }
`;

export const BoxIconContainer = styled(Box)`
  && {
    &:hover {
      cursor: pointer;
    }
  }
`;
