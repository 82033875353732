import { useIdentityContext } from "../../contexts/IdentityContext";
import React from "react";
import { Error } from "./Error";
import { Box, Button, Paper, Typography } from "@material-ui/core";
import { signOut } from "../../support/amplify/sign-out";

export const Unauthorised = () => {
    const { setIsLoggedIn } = useIdentityContext()

    const handleLogout = async (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()

        try {
            await signOut();
            setIsLoggedIn(false)

            window.location.href = '/login';
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Error>
            <Paper style={{ padding: "40px 30px" }}>
                <Box>
                    <Typography variant={"h1"}>
                        Unauthorised Access
                    </Typography>
                </Box>

                <Box paddingTop={"10px"}>
                    <Typography variant={"body1"}>
                        Sorry, it would appear you do not have access to iSky Research.
                    </Typography>
                    <Typography variant={"body1"} style={{ marginTop: "8px" }}>
                        Please contact support at swiftassist@iskyresearch.com
                    </Typography>
                </Box>

                <Box paddingTop={"20px"}>
                    <Button
                        disableElevation
                        color={"default"}
                        variant="outlined"
                        style={{
                            backgroundColor: "#E5126E",
                            color: "white",
                            borderRadius: "6px",
                            // textTransform: "capitalize",
                            fontWeight: "bold"
                        }}
                        onClick={handleLogout}
                    >
                        Ok
                    </Button>
                </Box>
            </Paper>
        </Error>
    )
}
