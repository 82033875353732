import { createContext, FunctionComponent, useContext, useReducer } from 'react';
import { ChannelScore } from '../../declarations/ChannelScore';
import { StackRaw } from '../../declarations/StackRaw';

/**
 * A type helper for Actions.
 */
type SetResultsPayload<T> = {
  value: T;
};

type ResultsType = {
  count: number;
  channels: ChannelScore[];
  stacks: StackRaw[];
}

/**
 * Types for Actions that are used within the reducer functions.
 */
type SetResults = { type: 'SET_RESULTS'; payload: SetResultsPayload<ResultsType> };
type SetLoadingScores = { type: 'SET_LOADING_SCORES'; payload: SetResultsPayload<boolean> };

/**
 * A Union for the Actions.
 */
type Action = SetResults | SetLoadingScores;

/**
 * Dispatch type helper.
 */
type Dispatch = (action: Action) => void;

/**
 * The State type.
 */
type State = {
  loading: boolean;
} & ResultsType

type ResultsContextType = { state: State; dispatch: Dispatch } | undefined;
const ResultsContext = createContext<ResultsContextType>(undefined);
ResultsContext.displayName = 'ResultsContext';

/**
 * Used for setting the channel scores.
 */
const setResults = (state: State, action: SetResults): State => {
  return {
    ...state,
    ...action.payload.value
  };
};

/**
 * Used for setting the loading state of the scores.
 */
const setLoadingScores = (state: State, action: SetLoadingScores): State => {
  return {
    ...state,
    loading: action.payload.value
  };
};


/**
 * A reducer for cycling through dispatched events and call their corresponding function.
 *
 * @param {State} state
 * @param {Action} action
 *
 * @throws Error
 */
const reducer = (state: State, action: Action) => {
  if (action.type === 'SET_RESULTS') {
    return setResults(state, action);
  }

  if (action.type === 'SET_LOADING_SCORES') {
    return setLoadingScores(state, action);
  }

  throw new Error(`Unhandled action type in 'ResultsContext'`);
};

/**
 * The Results Provider component, included in the Search Container.
 */
export const ResultsProvider: FunctionComponent = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    count: 0,
    channels: [],
    stacks: [],
  });

  return <ResultsContext.Provider value={{ state, dispatch }}>{props.children}</ResultsContext.Provider>;
};

/**
 * A hook used for accessing the query in components.
 */
export const useSearchResults = () => {
  const context = useContext(ResultsContext);

  if (context === undefined) {
    throw new Error('useSearchResults must be used within a ResultsProvider');
  }

  return context;
};
