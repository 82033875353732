import { createContext, PropsWithChildren, useContext, useState } from 'react'
import { EnvironmentConfiguration } from "../types/Environment";

type State = EnvironmentConfiguration;

type EnvironmentContextType = { state: State } | undefined
const EnvironmentContext = createContext<EnvironmentContextType>(undefined)

type EnvironmentProviderProps = {
    environment: EnvironmentConfiguration
}

/**
 * @deprecated No longer required, the usages have been removed but the context remains in place.
 *
 * A Component for storing the environment variables
 */
export const EnvironmentProvider = (props: PropsWithChildren<EnvironmentProviderProps>) => {
    const [state] = useState<State>(props.environment)

    return (
        <EnvironmentContext.Provider value={{ state }}>
            {props.children}
        </EnvironmentContext.Provider>
    )
}


/**
 * A hook for using the Environment variables in Components.
 */
export const useEnvironment = () => {
    const context = useContext(EnvironmentContext)

    if (context === undefined) {
        throw new Error('useEnvironment must be used within a EnvironmentProvider')
    }

    return context
}
