import { ApolloClient, gql } from '@apollo/client';
import { Sectors } from '../../types/Sectors';
import { Criterion, JourneyCriterion, ProviderCriterion } from '../../declarations/Criterion';

/**
 * The response type for the api request.
 */
export type GetSearchCriteriaData = {
  getSearchCriteria: {
    sectors: Criterion[];
    markets: Criterion[];
    providers: ProviderCriterion[];
    segments: Criterion[];
    journeys: JourneyCriterion[];
    channels: Criterion[];
  };
};
/**
 * The variables used for the request.
 */
type GetSearchCriteriaVariables = {
  sector: Sectors;
  as_at_date?: string;
};

/**
 * The query to use when running the request.
 */
const query = gql`
  query allSearchCriteria($sector: Int!, $as_at_date: AWSDate) {
    getSearchCriteria(sector: $sector, as_at_date: $as_at_date) {
      markets {
        id
        name
      }
      segments {
        id
        name
      }
      journeys {
        id
        name
        journey_seq
      }
      providers {
        id
        name
        image
        market
      }
      channels {
        id
        name
      }
    }
  }
`;

/**
 * Gets the search criteria from the api.
 *
 * @param {ApolloClient<object>} client
 * @param {GetSearchCriteriaVariables} variables
 *
 * @throws Error
 */
export const getSearchCriteria = async (client: ApolloClient<object>, variables: GetSearchCriteriaVariables) => {
  try {
    const result = await client.query<GetSearchCriteriaData, GetSearchCriteriaVariables>({
      query,
      variables
    });

    return result.data.getSearchCriteria;
  } catch (caught) {
    console.error(caught);
    throw Error();
  }
};
