import { Dropdown } from './Filter/Dropdown';
import { Group } from './Filter/Option/Group';
import { uniq } from 'lodash';
import { useCriteria } from '../../contexts/Search/Criteria';
import { useSearchQuery } from '../../contexts/Search/Query';

export const ProviderFilter = () => {
  const searchCriteria = useCriteria();
  const searchQuery = useSearchQuery();

  const handleSelectedChange = <K extends keyof SearchKind>(key: K, value: number, state: boolean) => {
    let criteria = Object.assign({}, {}, searchQuery.state);

    let providers = criteria['providers'];
    let markets = criteria['markets'];

    if (Array.isArray(providers)) {
      if (!state && providers.includes(value)) {
        providers.splice(
          providers.findIndex((item) => item === value),
          1
        );
      } else {
        const selectedProvider = searchCriteria.state.providers.find((p) => p.id === value);

        if (selectedProvider) {
          const marketId = selectedProvider.market;
          if (!markets.includes(marketId)) {
            markets.push(marketId);
          }
        }

        providers.push(value);
      }
    }

    searchQuery.dispatch({ type: 'SET_MARKET_QUERY_STATE', payload: { value: markets } });
    searchQuery.dispatch({ type: 'SET_PROVIDER_QUERY_STATE', payload: { value: providers } });
  };

  const handleSelectAll = <K extends keyof SearchKind>(key: K, state: boolean) => {
    if (state) {
      let providers = searchCriteria.state.providers.map((criterion) => criterion.id);
      let markets = uniq(searchCriteria.state.providers.map((criterion) => criterion.market));

      searchQuery.dispatch({ type: 'SET_MARKET_QUERY_STATE', payload: { value: markets } });
      searchQuery.dispatch({ type: 'SET_PROVIDER_QUERY_STATE', payload: { value: providers } });
    } else {
      searchQuery.dispatch({ type: 'SET_PROVIDER_QUERY_STATE', payload: { value: [] } });
    }
  };
  return (
    <Dropdown
      label={'Providers'}
      count={(searchQuery.state.providers as unknown as number[]).length}
      total={searchCriteria.state.providers.length}
      disabled={searchCriteria.state.loading}
    >
      <Group
        groupKey={'providers'}
        onSelected={handleSelectedChange}
        onSelectAll={handleSelectAll}
        criteria={searchCriteria.state.providers}
        selected={searchQuery.state.providers as unknown as number[]}
        showTextFilter={true}
      />
    </Dropdown>
  );
};
