import React, { FunctionComponent, useRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { CSVLink } from 'react-csv';
import { Link } from '@material-ui/core';
import { useSearchQuery } from '../../contexts/Search/Query';
import { getPrevalence } from '../../services/api/get-prevalence';
import { useIdentityContext } from '../../contexts/IdentityContext';

const approvedDomains = ['iskyresearch.com', 'trueology.com']

export const PrevalenceButton: FunctionComponent = () => {
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const searchQuery = useSearchQuery();

  // the following line came from https://github.com/react-csv/react-csv/issues/237
  const downloadRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const apolloClient = useApolloClient();
  const identityContext = useIdentityContext()

  const [csvData, setCsvData] = useState<{
    data: string[][];
    filename: string;
  }>({
    data: [],
    filename: ''
  });

  const handleExport = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setIsExporting(true);

    const query = searchQuery.state
    const channelscoreQuery = {
      markets: query.markets,
      providers: query.providers,
      segments: query.segments,
      channels: query.channels,
      sectors: [query.selectedCycle.linkedSector],
      questions: query.questions,
      audience: query.audience,
      orderBy: {
        field: query.sort.field,
        direction: query.sort.direction,
        friction_question: query.sort.friction_question
      },
      as_at_date: query.selectedCycle.cycleEndDate ?? undefined
    };

    const prevalenceResult = await getPrevalence(apolloClient, channelscoreQuery);

    setCsvData({ data: prevalenceResult, filename: 'prevelance.csv' })
    downloadRef.current!.link.click();
    setIsExporting(false);
  };

  return (
    <>
      {approvedDomains.includes(identityContext.group!) && <>
        <CSVLink
          style={{ display: 'hidden', textDecoration: 'none', cursor: 'pointer' }}
          filename={csvData.filename}
          data={csvData.data}
          asyncOnClick={true}
          ref={downloadRef}
          uFEFF={false}
        />
        {!isExporting &&
          <Link href='#' style={{ color: "grey", marginRight: "20px", fontStyle: 'italic', fontSize: "14px" }}
            onClick={handleExport}
          >
            Prevalence Export
          </Link>
        }
      </>
      }
    </>
  );
};
