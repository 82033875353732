import { ApolloClient, gql } from '@apollo/client';
import { Sectors } from '../../types/Sectors';
import { Segments } from '../../types/Segments';

/**
 * The response type for the api request.
 */
type GetAudienceCriteriaResponse = {
  getAudienceCriteria: Record<
    string,
    {
      id: number;
      name: string;
      segment: Segments;
      __typename: string;
    }[]
  >;
};

/**
 * The variables used for the request.
 */
type GetAudienceCriteriaVariables = {
  sector: Sectors;
};

/**
 * The query to use when running the request.
 */
const query = gql`
  query getAudienceCriteria($sector: Int) {
    getAudienceCriteria(sector: $sector) {
      market {
        id
        name
      }
      age {
        id
        name
      }
      gender {
        id
        name
      }
      income {
        id
        name
        segment
      }
    }
  }
`;

/**
 * Gets the audience criteria from the api.
 *
 * @param {ApolloClient<object>} client
 * @param {GetAudienceCriteriaVariables} variables
 *
 * @throws Error
 */
export const getAudienceCriteria = async (client: ApolloClient<object>, variables: GetAudienceCriteriaVariables) => {
  try {
    const response = await client.query<GetAudienceCriteriaResponse, GetAudienceCriteriaVariables>({
      query,
      variables
    });

    return response.data.getAudienceCriteria;
  } catch (caught) {
    throw Error();
  }
};
