import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { Box, Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import { Asset, Entry, Tag, TagLink } from 'contentful';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BlogImage } from '../../components/Blog/BlogImage';
import { TagIcon } from '../../components/Common/icons';
import { Page } from '../../components/Layout/Page';
import isVideo from '../../helpers/isVideo';
import { getBlogPosts } from '../../services/contentful/get-blog-posts';
import { getTags } from '../../services/contentful/get-tags';
import { Blog, Fields, InlineGallery } from '../../support/contentful/content-types';
import getFormattedDate from '../../support/date/getFormattedDate';
import { useAssetStyles, useBlogStyles } from './Show.styled';

type RouterParams = {
  id: string;
};

const loadBlogPost = async (uri: string) => {
  return getBlogPosts({
    limit: 1,
    [`fields.path`]: `${uri}`
  });
};

const loadTags = async () => {
  return getTags({ limit: 1000 });
};

export const Show: FunctionComponent = () => {
  const classes = useBlogStyles();
  const { id } = useParams<RouterParams>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [post, setPost] = useState<Blog>();
  const [tags, setTags] = useState<Tag[]>();

  useEffect(() => {
    setLoading(true);

    Promise.all([loadBlogPost(id!), loadTags()])
      .then(([posts, tags]) => {
        if (posts.type === 'success') {
          setPost(posts.response.items[0]);
        }

        if (tags.type === 'success') {
          setTags(tags.response.items);
        }
      })
      .then(() => setLoading(false));
  }, [id]);

  if (loading) return null;

  const options: Options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Typography variant={'body1'}>{text}</Typography>
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        if (node.data.target.sys.contentType.sys.id === 's3Asset') {
          return <SingleAsset entry={node.data.target} />;
        }

        if (node.data.target.sys.contentType.sys.id === 'inlineGallery') {
          return <MultipleAssets entry={node.data.target} />;
        }

        return null;
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <Box marginY={1}>
            <Typography classes={{ root: classes.paragraphs }} variant={'body1'}>
              {children}
            </Typography>
          </Box>
        );
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return (
          <Box marginY={4}>
            <Typography variant={'h2'}>{children}</Typography>
          </Box>
        );
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <Box marginY={4}>
            <Typography variant={'h3'}>{children}</Typography>
          </Box>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        if (node.data.target.sys.contentType.sys.id === 's3Asset') {
          return <SingleAsset entry={node.data.target} />;
        }

        return null;
      },
      'embedded-entry-inline': (node, children) => {
        if (node.data.target.sys.contentType.sys.id === 'inlineGallery') {
          return <MultipleAssets entry={node.data.target} />;
        }

        return null;
      }
    }
  };

  return (
    <Page>
      <Container>
        <Box marginTop={4} marginBottom={4} display={'flex'} justifyItems={'center'} alignItems={'center'}>
          <Grid container spacing={4}>
            <Grid item xs={10}>
              <Box display={'flex'} flexDirection={'column'}>
                <Box height={'100%'} display={'flex'} justifyItems={'center'} alignItems={'center'}>
                  <Button
                    variant="contained"
                    color="default"
                    style={{
                      color: '#647386',
                      backgroundColor: 'white',
                      fontSize: '14px',
                      padding: '8px 20px',
                      borderRadius: '20px'
                    }}
                    startIcon={
                      <svg
                        width="17px"
                        style={{ transform: 'rotate(90deg) translateX(1px)' }}
                        height="14px"
                        viewBox="0 0 17 14"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Blog" stroke="none" strokeWidth="1" fill="none" fillRule={'evenodd'}>
                          <g
                            id="Blog-Post"
                            transform="translate(-215.000000, -107.000000)"
                            fill="#8B96A4"
                            fillRule={'nonzero'}
                          >
                            <g id="header" transform="translate(200.000000, 94.000000)">
                              <g id="Icons/Arrows/Reload" transform="translate(15.058961, 13.541039)">
                                <path d="M8,14.4589615 C8.22333892,14.4589615 8.43774428,14.3606924 8.60748185,14.1820212 L14.1998883,8.58068118 C14.3785595,8.40201005 14.4589615,8.2054718 14.4589615,7.99106644 C14.4589615,7.53545505 14.1194863,7.1959799 13.6728085,7.1959799 C13.4494696,7.1959799 13.2350642,7.27638191 13.0921273,7.41931882 L11.1624791,9.32216639 L8.75041876,11.9843663 L8.81295366,10.1708543 L8.81295366,-0.728084869 C8.81295366,-1.20156337 8.4734785,-1.54103853 8,-1.54103853 C7.51758794,-1.54103853 7.18704634,-1.20156337 7.18704634,-0.728084869 L7.18704634,10.1708543 L7.24958124,11.9932998 L4.82858738,9.32216639 L2.9078727,7.41931882 C2.76493579,7.27638191 2.55053043,7.1959799 2.32719151,7.1959799 C1.88051368,7.1959799 1.54103853,7.53545505 1.54103853,7.99106644 C1.54103853,8.2054718 1.62144054,8.40201005 1.80011167,8.58068118 L7.39251815,14.1820212 C7.56225572,14.3606924 7.77666108,14.4589615 8,14.4589615 Z" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    }
                    onClick={() => navigate('/insights')}
                  >
                    Back
                  </Button>
                </Box>

                <Box marginTop={6}>
                  <Typography
                    variant={'h1'}
                    style={{
                      fontSize: '48px'
                    }}
                  >
                    {post?.fields.title}
                  </Typography>
                </Box>

                <Box marginY={2}>
                  <Box
                    style={{
                      borderWidth: '1px',
                      borderColor: 'transparent',
                      borderRadius: '25px'
                    }}
                  >
                    <BlogImage
                      roundedSmall={true}
                      height={'300px'}
                      s3={post?.fields.imageOnS3}
                      asset={post?.fields.image}
                    />
                  </Box>
                </Box>

                <Box marginTop={2}>
                  <Grid container>
                    <Grid item xs={2}>
                      <Typography variant="h3">
                        <strong>{post?.fields.source}</strong>
                      </Typography>
                      <Typography variant="body1">
                        {post?.fields.originalPostDate && <>{getFormattedDate(post.fields.originalPostDate)}</>}
                      </Typography>
                    </Grid>

                    <Grid item xs={10}>
                      {post?.fields.content && <>{documentToReactComponents(post.fields.content, options)}</>}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box display={'flex'} flexDirection={'column'}>
                <Box>
                  <Box marginTop={12}>
                    <Typography variant={'h3'}>See more articles</Typography>

                    {post?.metadata.tags && tags && <TagList tags={post?.metadata.tags} items={tags} />}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

const MultipleAssets = (props: { entry: InlineGallery }) => {
  const classes = useAssetStyles();

  const urls = useMemo(() => {
    if (props.entry.fields.s3Assets !== undefined) {
      return props.entry.fields.s3Assets.map((asset) => asset.fields.s3Url);
    }

    if (props.entry.fields.assets !== undefined) {
      return props.entry.fields.assets.map((asset) => asset.fields.file.url);
    }

    return [];
  }, [props.entry]);

  return (
    <Paper className={classes.root} elevation={0}>
      <Box display="flex">
        {urls.length
          ? urls.map((url, index) => {
            const assetTypeIsVideo = isVideo(url);

            return (
              <Box marginX={'auto'} display={'flex'} key={index}>
                <Box paddingY={4} paddingX={1}>
                  {assetTypeIsVideo ? (
                    <video src={url} height={'auto'} width={'220px'} controls></video>
                  ) : (
                    <img height={'auto'} width={'220px'} alt="" src={url} />
                  )}
                </Box>
              </Box>
            );
          })
          : false}
      </Box>
    </Paper>
  );
};

const SingleAsset = (props: { entry: Entry<Fields.S3Asset> | Asset }) => {
  const classes = useAssetStyles();

  const url = useMemo(() => {
    if ((props.entry as Asset).fields.file !== undefined) {
      return (props.entry as Asset).fields.file.url;
    }

    if ((props.entry as Entry<Fields.S3Asset>).fields.s3Url !== undefined) {
      return (props.entry as Entry<Fields.S3Asset>).fields.s3Url;
    }

    return null;
  }, [props.entry]);

  if (!url) return null;

  const assetTypeIsVideo = isVideo(url);

  return (
    <Paper className={classes.root} elevation={0}>
      <Box paddingY={4} margin={'auto'} maxWidth={'400px'}>
        {assetTypeIsVideo ? (
          <video
            controls
            src={url}
            style={{
              width: '100%',
              height: '100%'
            }}
          />
        ) : (
          <img src={url} height={'100%'} width={'100%'} alt={''} />
        )}
      </Box>
    </Paper>
  );
};

const TagList = (props: { tags?: TagLink[]; items: Tag[] }) => {
  return (
    <Box marginY={2}>
      {props.tags &&
        props.tags.map((tag, i) => {
          const tagIndex = props.items.findIndex((_tag) => {
            return tag.sys.id === _tag.sys.id;
          });

          if (tagIndex < 0) return null;

          const name = props.items[tagIndex].name;

          return <TagButton key={i} name={name} />;
        })}

      {!props.tags && props.items.map((tag) => <TagButton key={`${tag.sys + '_' + tag.name}`} name={tag.name} />)}
    </Box>
  );
};

const TagButton = (props: { name: string }) => {
  const navigate = useNavigate();

  return (
    <Box marginY={1}>
      <Button
        variant="contained"
        color="default"
        style={{
          color: '#647386',
          backgroundColor: 'white',
          fontSize: '14px',
          padding: '8px 20px',
          borderRadius: '20px',
          whiteSpace: 'nowrap'
        }}
        startIcon={<TagIcon viewBox={'0,0,16,16'} style={{ width: '16px', height: '16px' }} />}
        onClick={() => navigate(`/insights/search?term=${props.name}`)}
      >
        {props.name}
      </Button>
    </Box>
  );
};
