import { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../components/Router/ProtectedRoute';
import { ResultsProvider } from '../contexts/Search/Results';
import Search from '../pages/Search/Search';

export const SearchContainer: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path={'/search'}
        element={
          <ProtectedRoute>
            {/* <WithResultsProvider /> */}
            <ResultsProvider>
              <Search />
            </ResultsProvider>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
