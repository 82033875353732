import { createContext, FunctionComponent, useContext, useReducer } from 'react';
import { Segments } from '../types/Segments';

type SetModalState = { type: 'SET_VOC_OVERLAY_MODAL_STATE'; payload: { open: boolean } };
type SetQuestionState = {
  type: 'SET_VOC_OVERLAY_QUESTION_STATE';
  payload: {
    text: string;
    question: number;
    segment: Segments;
    weight: number;
    secure: boolean;
  };
};

type Action = SetModalState | SetQuestionState;

type Dispatch = (action: Action) => void;

type State = {
  vocOverlayIsOpen: boolean;
  text?: string;
  question?: number;
  segment?: Segments;
  weight?: number;
  secure?: boolean;
};

type PrioritisationOverlayContextType = { state: State; dispatch: Dispatch } | undefined;
const PrioritisationOverlayContext = createContext<PrioritisationOverlayContextType>(undefined);

const setModalState = (state: State, action: SetModalState): State => {
  return {
    ...state,
    vocOverlayIsOpen: action.payload.open
  };
};

const setQuestionState = (state: State, action: SetQuestionState): State => {
  return {
    ...state,
    ...action.payload
  };
};

const reducer = (state: State, action: Action) => {
  if (action.type === 'SET_VOC_OVERLAY_MODAL_STATE') {
    return setModalState(state, action);
  }

  if (action.type === 'SET_VOC_OVERLAY_QUESTION_STATE') {
    return setQuestionState(state, action);
  }

  throw new Error(`Unhandled action type in 'PrioritisationOverlayContext'`);
};

export const PrioritisationOverlayProvider: FunctionComponent = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    vocOverlayIsOpen: false
  });

  return (
    <PrioritisationOverlayContext.Provider value={{ state, dispatch }}>
      {props.children}
    </PrioritisationOverlayContext.Provider>
  );
};

export const usePrioritisationOverlay = () => {
  const context = useContext(PrioritisationOverlayContext);

  if (context === undefined) {
    throw new Error('usePrioritisationOverlay must be used within a PrioritisationOverlayProvider');
  }

  return context;
};
