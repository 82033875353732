import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";

export interface DashboardPanelProps {
  title?: string;
  children: any;
}

const DashboardPanel: React.FC<DashboardPanelProps> = ({ title, children }) => {
  return (
    <Paper style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box paddingX={2} display="flex" flexDirection="column" style={{ height: '100%' }}>
        {title !== undefined && (
          <Box paddingY={2} style={{ borderBottom: "1px solid #ECEDEF", flex: "none" }}>
            <Typography variant="h3">{title}</Typography>
          </Box>
        )}
        <Box paddingX={2} marginBottom={2} style={{ flex: 1, overflow: 'hidden', padding:'10px' }}>
          {children}
        </Box>
      </Box>
    </Paper>
  );
};

export default DashboardPanel;
